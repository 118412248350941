import GenericRadioSelect from "app/container/Checkout/Generic/GenericRadioSelect";
import ShippingRadioContentComponent from "app/container/Checkout/Shipping/ShippingRadioContentComponent";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setShippingAddress,
  updateCartShippingAddress,
} from "redux/actions/checkout";

// Styles
import CreateShippingAddressFormContainer from "./shippingAddressBlock/CreateShippingAddressFormContainer";

const ShippingAddressBlock = () => {
  const dispatch = useDispatch();
  const { availableShippingAddresses, shippingAddress } = useSelector(
    s => s.checkout
  );

  const onAddressSelected = useCallback(
    shippingAddressId => {
      // They have selected an actual address
      dispatch(setShippingAddress(shippingAddressId));
      dispatch(updateCartShippingAddress(shippingAddressId));
    },
    [dispatch]
  );

  return (
    <div className="pt-5">
      <p className="gkit-p-20 gkit-bold">Shipping address</p>
      <GenericRadioSelect
        entities={availableShippingAddresses}
        newEntityLabel={"Add new address"}
        onEntitySelected={onAddressSelected}
        RadioContentComponent={ShippingRadioContentComponent}
        selectedEntityID={
          typeof shippingAddress === "number" ? shippingAddress : null
        }
        topAlignedCheckboxes={true}
      />
      {typeof shippingAddress !== "number" && (
        <CreateShippingAddressFormContainer />
      )}
    </div>
  );
};

export default ShippingAddressBlock;
