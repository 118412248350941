import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import api from "api";
import { checkoutBlocks } from "./checkoutConstants";
import CheckoutPage from "./CheckoutPage";

// Styles
import styles from "./InitialisedCheckout.module.scss";
import DesktopSubscriptionSummary from "./subscriptionSummary/DesktopSubscriptionSummary";
import MobileSubscriptionSummary from "./subscriptionSummary/MobileSubscriptionSummary";
import PayPalLoader from "./PayPalLoader";
import SignInBlock from "./SignInBlock";
import CheckingOutOverlay from "./CheckingOutOverlay";
import CheckoutErrorOverlay from "./CheckoutErrorOverlay";
import { checkoutFailure } from "redux/actions/checkout";
import { loadCountries } from "redux/actions/config";
import { cartToApplePayProps, shouldUseProvinceCode } from "shared/utility";

const InitialisedCheckout = ({
  onQuickCheckoutPaymentMethodReceived,
  onCardCheckout,
  onPaymentMethodCheckout,
  onPaypalCheckout,
  onStandardCheckoutPaymentMethodReceived,
  onQuickPaypalCheckoutReceived,
}) => {
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(0);
  const [hasTracked, setHasTracked] = useState(false);
  const [paypalCheckoutInstance, setPaypalCheckoutInstance] = useState(null);
  const {
    availableCountryLookup,
    cart,
    checkoutError,
    isCheckingOut,
    isLoggedIn,
  } = useSelector((s) => ({
    ...s.checkout,
    ...s.auth,
    ...s.config,
  }));

  // Drop our tiktok "initiatecheckout" pixel
  useEffect(() => {
    if (hasTracked) {
      return;
    }

    setHasTracked(true);

    // TikTok
    if (window.ttq) {
      window.ttq.track("InitiateCheckout");
    }
  }, [cart, hasTracked, setHasTracked]);

  const { country_code: countryCode, requires_shipping: requiresShipping } =
    cart;

  // Generate the structure of the checkout pages #dynamically son 🤤
  const pages = requiresShipping
    ? [
        [
          checkoutBlocks.QUICK_PAYMENT,
          checkoutBlocks.CONTACT_INFORMATION,
          checkoutBlocks.SHIPPING_ADDRESS,
          checkoutBlocks.CONTINUE,
        ],
        [
          checkoutBlocks.SHIPPING_SUMMARY,
          checkoutBlocks.SHIPPING_METHOD,
          checkoutBlocks.PAYMENT_METHOD,
          checkoutBlocks.CHECKOUT,
        ],
      ]
    : [
        [
          checkoutBlocks.QUICK_PAYMENT,
          checkoutBlocks.CONTACT_INFORMATION,
          checkoutBlocks.PAYMENT_METHOD,
          checkoutBlocks.CHECKOUT,
        ],
      ];

  // Load our beautiful list of countries from the API first thang
  useEffect(() => {
    if (requiresShipping) {
      dispatch(loadCountries());
    }
  }, [dispatch, requiresShipping]);

  // Render the previous page!
  const previousPage = useCallback(() => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  }, [pageIndex]);

  // Render the next page!
  const progressPage = useCallback(() => {
    if (pages.length > pageIndex + 1) {
      setPageIndex(pageIndex + 1);
    }
  }, [pageIndex, pages.length]);

  const onQuickPaypalCheckoutRequestGenerated = useCallback(
    (event) => {
      const chosenCountryCode = event.details.shippingAddress.countryCode;
      // Throw an error if address is not GB
      if (chosenCountryCode !== countryCode) {
        dispatch(
          checkoutFailure({
            message: `You've chosen to ship in a different country (${chosenCountryCode}) to the one you've selected for your cart (${countryCode}).`,
          })
        );
      } else {
        // Get applicable shipping rates from the API
        const params = {
          country_code: event.details.shippingAddress.countryCode,
        };

        if (
          shouldUseProvinceCode(
            availableCountryLookup,
            event.details.shippingAddress.countryCode
          )
        ) {
          params.province_code = event.details.shippingAddress.state;
        }
        api
          .get(`/shop/cart/${encodeURIComponent(cart.token)}/shipping-rates`, {
            params,
          })
          .then(({ data }) => {
            onQuickPaypalCheckoutReceived({
              paypalResponse: event,
              shippingOptions: data.map((option) => ({
                id: String(option.id),
                label: option.name,
                amount: Math.round(parseFloat(option.price.amount) * 100),
              })),
            });
          });
      }
    },
    [
      availableCountryLookup,
      cart.token,
      countryCode,
      onQuickPaypalCheckoutReceived,
      dispatch,
    ]
  );

  const onQuickCheckoutPaymentRequestGenerated = useCallback(
    (paymentRequest) => {
      // We've got a shipping address to generate shipping methods with
      paymentRequest.on("shippingaddresschange", (event) => {
        if (
          !(
            event.shippingAddress &&
            event.shippingAddress.country === countryCode
          )
        ) {
          return event.updateWith({ status: "invalid_shipping_address" });
        }

        const rateRequestParams = {
          country_code: event.shippingAddress.country,
        };

        const shippingDetailsParams = {
          country_code: event.shippingAddress.country,
          zip: event.shippingAddress.postalCode,
        };

        // Only use province code if we need it.
        if (
          shouldUseProvinceCode(
            availableCountryLookup,
            event.shippingAddress.country
          )
        ) {
          rateRequestParams.province_code = event.shippingAddress.region;
          shippingDetailsParams.province_code = event.shippingAddress.region;
        }

        // Get applicable shipping rates from the API
        api
          .get(`/shop/cart/${encodeURIComponent(cart.token)}/shipping-rates`, {
            params: rateRequestParams,
          })
          .then(({ data }) => {
            if (data.length === 1) {
              shippingDetailsParams.shipping_rate_id = data[0].id;
              api
                .put(
                  `/shop/cart/${encodeURIComponent(
                    cart.token
                  )}/shipping-details`,
                  shippingDetailsParams
                )
                .then(({ data: newCart }) => {
                  event.updateWith({
                    status: "success",
                    ...cartToApplePayProps(newCart),
                  });
                });
            } else {
              event.updateWith({
                status: "success",
                shippingOptions: data.map((option) => ({
                  id: String(option.id),
                  label: option.name,
                  amount: Math.round(parseFloat(option.price.amount) * 100),
                })),
              });
            }
          });
      });

      // We've received a payment method, pass it up the chain to process
      paymentRequest.on("paymentmethod", (event) => {
        if (onQuickCheckoutPaymentMethodReceived) {
          onQuickCheckoutPaymentMethodReceived(event);
        }
      });
    },
    [
      availableCountryLookup,
      cart.token,
      countryCode,
      onQuickCheckoutPaymentMethodReceived,
    ]
  );

  const onStandardCheckoutPaymentRequestGenerated = useCallback(
    (paymentRequest) => {
      paymentRequest.on("paymentmethod", (event) => {
        if (onStandardCheckoutPaymentMethodReceived) {
          onStandardCheckoutPaymentMethodReceived(event);
        }
      });
    },
    [onStandardCheckoutPaymentMethodReceived]
  );

  return (
    <div className={cx(styles.container, "pb-5")}>
      <PayPalLoader onCheckoutInstance={setPaypalCheckoutInstance} />
      <MobileSubscriptionSummary />
      <div className="container pt-5">
        <div className="row">
          <div className="d-none d-md-block col-md-6 col-lg-5">
            <DesktopSubscriptionSummary
              isSubscription={Boolean(cart.subscription)}
            />
          </div>

          <div className="col col-md-6 col-lg-7 position-relative">
            <h2 className="gkit-h2">
              {pageIndex === 0
                ? "Add your information"
                : "Pick a delivery and payment option"}
            </h2>
            {!isLoggedIn && pageIndex === 0 && <SignInBlock />}
            <CheckoutPage
              pageIndex={pageIndex}
              blocks={pages[pageIndex]}
              onCardCheckout={onCardCheckout}
              onPaymentMethodCheckout={onPaymentMethodCheckout}
              onPaypalCheckout={onPaypalCheckout}
              onQuickCheckoutPaymentRequestGenerated={
                onQuickCheckoutPaymentRequestGenerated
              }
              onQuickPaypalCheckoutRequestGenerated={
                onQuickPaypalCheckoutRequestGenerated
              }
              onNextPage={progressPage}
              onPreviousPage={previousPage}
              onStandardPaymentRequestGenerated={
                onStandardCheckoutPaymentRequestGenerated
              }
              paypalCheckoutInstance={paypalCheckoutInstance}
            />
            {checkoutError && <CheckoutErrorOverlay />}
            {isCheckingOut && <CheckingOutOverlay />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialisedCheckout;
