import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import ShippingAddressForm from "./ShippingAddressForm";
import { useSelector } from "react-redux";

const CreateShippingAddressFormContainer = () => {
  const { cart, shippingAddress, availableCountries, availableCountryLookup } =
    useSelector((s) => ({ ...s.checkout, ...s.config }));
  const { country_code: countryCode } = cart;
  return (
    <Formik
      initialValues={
        shippingAddress && typeof shippingAddress !== "number"
          ? shippingAddress
          : {
              first_name: "",
              last_name: "",
              phone: "",
              company: "",
              address1: "",
              address2: "",
              zip: "",
              city: "",
              country_code: countryCode,
              province_code: "",
            }
      }
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required("A first name must be included."),
        last_name: Yup.string().required("A last name must be included."),
        phone: Yup.string()
          .matches(/^\+?[ \d]{7,15}$/, "A valid phone number is required.")
          .required("A valid phone number must be included."),
        company: Yup.string(),
        address1: Yup.string()
          .min(3)
          .max(35, "Address line 1 must not exceed 35 characters.")
          .required("First line of your address must be included."),
        address2: Yup.string()
          .max(25, "Address line 2 must not exceed 25 characters.")
          .nullable(),
        zip: Yup.string().required("A postcode must be included."),
        city: Yup.string().required("A city must be included."),
        country_code: Yup.string()
          .equals(
            [countryCode],
            `Your cart can only be checked out for ${countryCode}.`
          )
          .required("A country must be included."),
        province_code: Yup.string(),
      })}
    >
      {(formikProps) => (
        <ShippingAddressForm
          availableCountries={availableCountries}
          availableCountryLookup={availableCountryLookup}
          {...formikProps}
        />
      )}
    </Formik>
  );
};

export default CreateShippingAddressFormContainer;
