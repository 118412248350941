import React from "react";
import cx from "classnames";

const InputField = ({ children, meta, ...props }) => (
  <div className={cx("form-group", props.className)}>
    {props.label && (
      <label className="gkit-input-label" htmlFor={props.id || props.name}>
        {props.label}
      </label>
    )}
    {children}
    {meta && meta.touched && meta.error && (
      <div className="invalid-feedback">{meta.error}</div>
    )}
  </div>
);

export default InputField;
