import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import DashedSeparator from "shared/components/DashedSeparator";
import SummaryLineItem from "./SummaryLineItem";

/**
 * This renders shipping and discount line items
 */
const PriceChangingLineItems = () => {
  const { availableShippingMethods, cart, shippingMethod } = useSelector(
    s => s.checkout
  );

  // Agnostic of authentication status, grab the selected shipping rate from
  // the cart if available, otherwise stateful selection.
  const chosenShippingMethod = useMemo(() => {
    if (!(shippingMethod || cart.shipping_rate)) {
      return null;
    }

    let shippingRate = cart.shipping_rate;

    if (!shippingRate) {
      shippingRate =
        (availableShippingMethods || []).find(
          method => method.id === shippingMethod
        ) || null;
    }

    return shippingRate;
  }, [availableShippingMethods, shippingMethod, cart]);

  const hasDiscount = Boolean(
    cart.discount_amount && cart.discount_amount.amount !== "0.00"
  );

  const hasCredit = Boolean(
    cart.credit_applied && cart.credit_applied.amount !== "0.00"
  );

  const hasTax = Boolean(cart.tax_lines && cart.tax_lines.length > 0);

  if (!(hasDiscount || chosenShippingMethod || hasCredit || hasTax)) {
    return null;
  }

  return (
    <>
      <DashedSeparator className="my-2" />
      {hasDiscount && (
        <SummaryLineItem
          className="my-2"
          label="Discount"
          value={`-${cart.discount_amount.formatted}`}
        />
      )}
      {hasCredit && (
        <SummaryLineItem
          className="my-2"
          label="Account Credit"
          value={`-${cart.credit_applied.formatted}`}
        />
      )}
      {chosenShippingMethod && (
        <SummaryLineItem
          className="my-2"
          label="Shipping"
          value={
            chosenShippingMethod.price.amount === "0.00"
              ? "Free"
              : chosenShippingMethod.price.formatted
          }
        />
      )}
      {cart.tax_lines &&
        cart.tax_lines
          .filter(taxLine => Boolean(parseFloat(taxLine.amount.amount)))
          .map((taxLine, idx) => (
            <SummaryLineItem
              className="my-2"
              key={idx}
              label={taxLine.name}
              value={taxLine.amount.formatted}
            />
          ))}
    </>
  );
};

export default PriceChangingLineItems;
