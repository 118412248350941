import React from "react";
import cx from "classnames";

import styles from "./SummaryLineItem.module.scss";

const SummaryLineItem = ({ className, isLarge = false, label, value }) => {
  return (
    <div
      className={cx(
        "d-flex flex-row justify-content-between",
        styles.container,
        className
      )}
    >
      <p className={cx("gkit-p-15 gkit-bold m-0", isLarge && styles.largeText)}>
        {label}
      </p>
      <p className={cx("gkit-p-15 m-0", isLarge && styles.largeText)}>
        {value}
      </p>
    </div>
  );
};

export default SummaryLineItem;
