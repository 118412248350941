import React, { useCallback } from "react";
import cx from "classnames";

import Spinner from "shared/components/Spinner";

import styles from "./CheckingOutOverlay.module.scss";

const CheckingOutOverlay = () => {
  const onNodeReady = useCallback(node => {
    if (node) {
      node.scrollIntoView();
    }
  }, []);

  return (
    <div className={cx(styles.container)} ref={onNodeReady}>
      <p className="gkit-p-17 gkit-bold gkit-light">
        Please wait while we place your order...
      </p>
      <Spinner />
    </div>
  );
};

export default CheckingOutOverlay;
