import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";

import api, { extractAPIErrorString } from "../../../../../api";

import { Input } from "../../../../../shared/Input";
import { Button } from "../../../../../shared/Button";
import ErrorHandling from "../../../../../shared/ErrorHandling";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const Login = ({ setLoginState, setEmailState }) => {
  const [hasError, setHasError] = useState(null);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: LoginSchema,
    onSubmit: values => {
      setHasError(null);
      return api
        .get("/auth/email", {
          params: values,
        })
        .then(
          ({ data }) => {
            setEmailState(values.email);
            if (data.auth_methods.includes("password")) {
              setLoginState("passwordLogin");
            } else {
              // automatically resend a verification code if they do not have a password assigned
              return api
                .post("/auth/login", { email: values.email })
                .then(() => {
                  setLoginState("codeLogin");
                });
            }
          },
          error => {
            const errorStr = extractAPIErrorString(error);
            if (_.get(error, "response.data.error.code") === "not_found") {
              setHasError(
                "A Grind account with this email address could not be found."
              );
            } else {
              setHasError(errorStr);
            }
          }
        );
    },
  });
  return (
    <form onSubmit={handleSubmit} className="mt-4 mt-md-0 p-md-4">
      <h2 className="gkit-h2 text-md-center">Sign in to your Grind account</h2>
      <p className="gkit-p-17 gkit-light text-md-center">
        Please enter your email address to continue.
      </p>
      <Input
        className="mt-3"
        name="email"
        label="Email"
        values={values}
        errors={errors}
        touched={touched}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="jane@email.com"
      />
      <ErrorHandling error={hasError} />
      <Button
        size="md"
        className="mt-3"
        type="submit"
        id="submit-button"
        isLoading={isSubmitting}
      >
        Continue
      </Button>
    </form>
  );
};

export default Login;
