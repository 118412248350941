import { STORE_BASKET, CONFIRM_ORDER, SET_DISCOUNT_CODE } from "../constants";
import { handleActions } from "redux-actions";

// import mockCart from "api/mock/confirm-cart.json";
// import mockConfirm from "api/mock/confirm-confirmed.json";

const initialState = {
  // cart: mockCart,
  // confirmed: mockConfirm
  cart: null,
  confirmed: null,
  discountCode: null
};

export default handleActions(
  {
    [STORE_BASKET]: (state, action) => ({
      ...state,
      cart: action.payload
    }),
    [CONFIRM_ORDER]: (state, action) => ({
      ...state,
      confirmed: action.payload
    }),
    [SET_DISCOUNT_CODE]: (state, { payload }) => ({
      ...state,
      discountCode: payload
    })
  },
  initialState
);
