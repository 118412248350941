import React, { useCallback } from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import styles from "./CheckoutErrorOverlay.module.scss";
import { clearCheckoutError } from "redux/actions/checkout";

const CheckoutErrorOverlay = () => {
  const dispatch = useDispatch();
  const { checkoutError } = useSelector(s => s.checkout);

  const onBackClick = useCallback(
    () => dispatch(clearCheckoutError()),
    [dispatch]
  );

  const onNodeReady = useCallback(node => {
    if (node) {
      node.scrollIntoView();
    }
  }, []);

  let errorStr = null;

  if (!checkoutError) {
    return null;
  }

  const { message, response } = checkoutError;

  // Handle Unhandled API Error
  if (response && response.status === 500) {
    errorStr =
      "An unknown error occurred. Please try again or contact us if this issue persists.";

    // Handle Handled API Error
  } else if (response && response.status >= 400) {
    errorStr = _.get(response, "data.error.message");

    // Handle Stripe Error
  } else if (message) {
    errorStr = message;

    // Handle Unknwon / Network Error
  } else {
    errorStr =
      "Please check your connection and try again. Contact us if the issue persists.";
  }

  return (
    <div
      className={cx(
        styles.container,
        "d-flex flex-column align-items-center justify-content-start text-center px-3"
      )}
      ref={onNodeReady}
    >
      <img alt="Error" src={require("assets/images/iconError.svg").default} />
      <p className="gkit-h3 mb-0 mt-2">Something went wrong</p>
      <p className="gkit-p-15 gkit-light gkit-medium mb-0 mt-1">{errorStr}</p>

      <button
        className="gkit-btn-md gkit-tertiary mt-4"
        onClick={onBackClick}
        type="button"
      >
        Go back
      </button>
    </div>
  );
};

export default CheckoutErrorOverlay;
