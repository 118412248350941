import React from "react";

const InvalidCheckout = () => {
  return (
    <div className="invalidCheckout container py-5">
      <div className="row justify-content-center">
        <div className="col col-md-6 text-center">
          <img
            className="w-100"
            style={{ objectFit: "contain" }}
            alt="Oops!"
            src={require("assets/images/assetSpilledPods.jpg").default}
          />
          <h3 className="gkit-h3 mt-4">
            The checkout page you are looking for does not exist
          </h3>
          <p className="gkit-p-15 gkit-light mb-0">
            The URL you entered may be deleted or not available.
            <br />
            Return to the home page to continue shopping.
          </p>
          <a
            className="gkit-btn-lg gkit-primary mt-4"
            href="https://grind.co.uk/"
          >
            Return to home page
          </a>
        </div>
      </div>
    </div>
  );
};

export default InvalidCheckout;
