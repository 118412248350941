import React, { useCallback, useEffect, useState } from "react";
import { Form } from "formik";

import Textbox from "shared/components/Textbox";
import { useDispatch, useSelector } from "react-redux";
import { setContactDetails } from "redux/actions/checkout";
import { checkEmail } from "redux/actions/auth";
import GenericCheckbox from "app/container/Checkout/Generic/GenericCheckbox";

import api from "api";
import { setAcceptsMarketing } from "redux/actions/checkout";
import { sendEvent } from "shared/DataLayer";

const ContactInformationForm = ({
  isLoggedIn,
  validateForm,
  values,
  handleBlur,
}) => {
  const [validatedEmail, setValidatedEmail] = useState(null);
  const [marketing, setMarketing] = useState(false);

  const dispatch = useDispatch();
  const { acceptsMarketing } = useSelector((s) => s.checkout);

  const onFieldBlur = useCallback(() => {
    validateForm().then((errors) => {
      // Valid email
      setValidatedEmail(errors.email ? null : values.email);

      // Whole form is valid
      if (Object.keys(errors).length === 0) {
        sendEvent("contactComplete", values);
        dispatch(setContactDetails(values));
      }
    });
  }, [dispatch, validateForm, values]);

  // Only set email when it changes
  useEffect(() => {
    if (validatedEmail) {
      sendEvent("emailComplete", validatedEmail);
      dispatch(checkEmail(validatedEmail));
    }
  }, [dispatch, validatedEmail]);

  useEffect(() => {
    if (isLoggedIn) {
      api
        .get("/account/preferences")
        .then((data) => setMarketing(data.data.accepts_marketing));
    }
  }, [isLoggedIn]);

  const onCheckboxChanged = (val) => {
    // Set marketing preferences
    if (isLoggedIn && val) {
      api.put("/account/preferences", {
        accepts_marketing: val,
      });
    }
    sendEvent("marketingChange", val);
    dispatch(setAcceptsMarketing(val));
  };

  return (
    <Form>
      <div className="row g-3">
        <Textbox
          autoComplete="email"
          containerClass="col"
          disabled={isLoggedIn}
          label="Email"
          name="email"
          onBlur={(e) => {
            handleBlur(e);
            onFieldBlur();
          }}
          placeholder="jane@mail.com"
        />
      </div>
      {!marketing && (
        <div className="mt-3 mb-3">
          <GenericCheckbox
            label="Send me news of what Grind is up to including new products, exclusive offers and dogs that we like."
            checked={acceptsMarketing}
            id="marketingConsent"
            onChange={onCheckboxChanged}
            className="d-none d-md-block"
          />
          <GenericCheckbox
            label="Send me news of what Grind is up to including new products, exclusive offers and dogs that we like."
            checked={acceptsMarketing}
            id="marketingConsent"
            onChange={onCheckboxChanged}
            className="d-md-none"
            labelSize={15}
          />
        </div>
      )}
      <div className="row g-3 mt-2">
        <Textbox
          autoComplete="given-name"
          containerClass="col-6 col-md-6"
          disabled={isLoggedIn}
          label="First name"
          name="first_name"
          onBlur={(e) => {
            handleBlur(e);
            onFieldBlur();
          }}
          placeholder="Jane"
        />
        <Textbox
          autoComplete="family-name"
          containerClass="col-6 col-md-6"
          disabled={isLoggedIn}
          label="Last name"
          name="last_name"
          onBlur={(e) => {
            handleBlur(e);
            onFieldBlur();
          }}
          placeholder="Grind"
        />
      </div>
    </Form>
  );
};

export default ContactInformationForm;
