export const standardErrorRequestURLs = {
  "/account/addresses": {
    ignore: false,
  },
  "/account/payment-cards": {
    ignore: false,
  },
  "/account/payment-methods": {
    ignore: false,
  },
  "/account/preferences": {
    ignore: false,
  },
  "/account/referral-code": {
    ignore: false,
  },
  "/shop/cart/discount": {
    ignore: false,
  },
  "/shop/cart/shipping-address": {
    ignore: false,
  },
  "/shop/cart/shipping-rate": {
    ignore: false,
  },
  "/auth/login": {
    ignore: false,
  },
  "/auth/logout": {
    ignore: false,
  },
  "/auth/magic-login": {
    ignore: false,
  },
  "/auth/email": {
    ignore: [404],
  },
  "/auth/register": {
    ignore: false,
  },
  "/shop/countries": {
    ignore: false,
  },
  "/shop/cart/checkout": {
    ignore: false,
  },
  "/shop/cart/subscribe": {
    ignore: false,
  },
  "/shop/cart": {
    ignore: false,
  },
};

export const nonStandardErrorRequestUrls = [
  {
    url: /^\/shop\/cart\/[a-z0-9]{12,40}\/shipping-rates$/,
    name: "/shop/cart/TOKEN/shipping-rates",
    ignore: false,
  },
  {
    url: /^\/shop\/cart\/[a-z0-9]{12,40}\/shipping-details$/,
    name: "/shop/cart/TOKEN/shipping-details",
    ignore: false,
  },
  {
    url: /^\/shop\/cart\/[a-z0-9]{12,40}\/claim$/,
    name: "/shop/cart/TOKEN/claim",
    ignore: false,
  },
  {
    url: /^\/shop\/cart\/[a-z0-9]{12,40}\/discount$/,
    name: "/shop/cart/TOKEN/discount",
    ignore: [400],
  },
  {
    url: /^\/shop\/checkout\/[a-z0-9]{12,40}\/confirm$/,
    name: "/shop/checkout/TOKEN/confirm",
    ignore: false,
  },
  {
    url: /^\/shop\/cart\/[a-z0-9]{12,40}\/checkout$/,
    name: "/shop/cart/TOKEN/checkout",
    ignore: false,
    ignoreCodes: ["payment_method.requires_action"],
  },
  {
    url: /^\/shop\/checkout\/[a-z0-9]{12,40}$/,
    name: "/shop/checkout/TOKEN",
    ignore: false,
  },
  {
    url: /^\/shop\/cart\/[a-z0-9]{12,40}\/products\/\d+$/,
    name: "/shop/cart/TOKEN/products/ID",
    ignore: false,
  },
  {
    url: /^\/shop\/cart\/[a-z0-9]{12,40}\/products\/\d+\/restore$/,
    name: "/shop/cart/TOKEN/products/ID/restore",
    ignore: false,
  },
  {
    url: /^\/account\/payment-cards\/\d+\/confirm$/,
    name: "/account/payment-cards/ID/confirm",
    ignore: [400],
  },
  {
    url: /^\/shop\/cart\/products\/\d+\/restore$/,
    name: "/shop/cart/products/ID/restore",
    ignore: false,
  },
  {
    url: /^\/shop\/cart\/products\/\d+$/,
    name: "/shop/cart/products/ID",
    ignore: false,
  },
];
