import { paymentTypes } from "app/checkout/checkoutConstants";
import { handleActions } from "redux-actions";
import {
  APPLY_DISCOUNT_CODE_SUCCESS,
  APPLY_SHIPPING_ADDRESS_SUCCESS,
  APPLY_SHIPPING_METHOD_SUCCESS,
  CHECKOUT,
  CHECKOUT_FAILURE,
  CHECKOUT_SUCCESS,
  CLAIM_CART_FAILURE,
  CLAIM_CART_SUCCESS,
  CLEAR_CHECKOUT_ERROR,
  CREATE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD_FAILURE,
  CREATE_PAYMENT_METHOD_SUCCESS,
  FETCH_CONFIRMED_CHECKOUT_SUCCESS,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PROFILE_SUCCESS,
  FETCH_SHIPPING_ADDRESSES_SUCCESS,
  FETCH_SHIPPING_METHODS_SUCCESS,
  IMPORT_CART_FAILURE,
  IMPORT_CART_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  REMOVE_CART_PRODUCT_SUCCESS,
  REMOVE_DISCOUNT_CODE_SUCCESS,
  REMOVE_DISCOUNT_CODE,
  RESTORE_CART_PRODUCT_SUCCESS,
  SET_CONTACT_DETAILS,
  SET_HAS_CARD_DETAILS,
  SET_IRCLICKID,
  SET_PAYMENT_METHOD,
  SET_SHIPPING_ADDRESS,
  SET_SHIPPING_METHOD,
  SET_ACCEPTS_MARKETING,
  STORE_DISCOUNT_CODE,
  UPDATE_CART_PRODUCT_SUCCESS,
} from "redux/constants";

const initialState = {
  cart: null,
  cartError: null,

  discountCode: null,
  isClaimed: false,

  contactDetails: null,
  shippingAddress: null,
  shippingMethod: null,
  paymentMethod: null,

  hasCardDetails: false,

  availableShippingAddresses: null,
  availableShippingMethods: null,
  availablePaymentMethods: null,

  checkoutError: null,
  completedCheckout: null,
  isCheckingOut: false,

  irclickid: null,
  acceptsMarketing: true,
};

const updateCart = (state, { payload }) => {
  const updatePayload = {
    cart: payload,
  };

  if (payload.shipping_rates && payload.shipping_rates.length > 0) {
    updatePayload.availableShippingMethods = payload.shipping_rates;
  }

  if (payload.shipping_rate) {
    updatePayload.shippingMethod = payload.shipping_rate.id;
  }

  return {
    ...state,
    ...updatePayload,
  };
};

// This is a lazy way of updating the isClaimed status.
const updateClaimedCart = (state, { payload }) => {
  const updatePayload = {
    cart: payload,
  };

  if (payload.shipping_rates && payload.shipping_rates.length > 0) {
    updatePayload.availableShippingMethods = payload.shipping_rates;
  }

  if (payload.shipping_rate) {
    updatePayload.shippingMethod = payload.shipping_rate.id;
  }

  return {
    ...state,
    isClaimed: true,
    ...updatePayload,
  };
};

const updateConfirmedCheckout = (state, { payload }) => ({
  ...state,
  isCheckingOut: false,
  completedCheckout: payload,
});

const beginCheckout = (state) => ({
  ...state,
  isCheckingOut: true,
  checkoutError: null,
});

const checkoutFailure = (state, { payload }) => ({
  ...state,
  isCheckingOut: false,
  checkoutError: payload,
});

export default handleActions(
  {
    [LOGOUT]: () => initialState,
    [STORE_DISCOUNT_CODE]: (state, { payload }) => ({
      ...state,
      discountCode: payload,
    }),
    [REMOVE_DISCOUNT_CODE]: (state) => ({
      ...state,
      discountCode: null,
    }),
    [IMPORT_CART_SUCCESS]: updateCart,
    [IMPORT_CART_FAILURE]: (state, { payload }) => ({
      ...state,
      cartError: payload,
    }),
    [CLAIM_CART_SUCCESS]: updateClaimedCart,
    [CLAIM_CART_FAILURE]: (state, { payload }) => ({
      ...state,
      cartError: payload,
    }),
    [LOGIN_SUCCESS]: (state) => ({
      ...state,
      shippingMethod: null,
      availablePaymentMethods: null,
    }),
    [SET_CONTACT_DETAILS]: (state, { payload }) => ({
      ...state,
      contactDetails: payload,
    }),
    [FETCH_PROFILE_SUCCESS]: (state, { payload }) => ({
      ...state,
      contactDetails: {
        email: payload.email,
        first_name: payload.first_name,
        last_name: payload.last_name,
      },
    }),
    [SET_SHIPPING_ADDRESS]: (state, { payload }) => ({
      ...state,
      shippingAddress: payload,
      availableShippingMethods: null,
    }),
    [SET_SHIPPING_METHOD]: (state, { payload }) => ({
      ...state,
      shippingMethod: payload,
    }),
    [SET_PAYMENT_METHOD]: (state, { payload }) => ({
      ...state,
      paymentMethod: payload,
      hasCardDetails:
        payload === paymentTypes.CARD ? state.hasCardDetails : false,
    }),
    [SET_HAS_CARD_DETAILS]: (state, { payload }) => ({
      ...state,
      hasCardDetails: payload,
    }),
    [SET_ACCEPTS_MARKETING]: (state, { payload }) => ({
      ...state,
      acceptsMarketing: payload,
    }),
    [FETCH_SHIPPING_ADDRESSES_SUCCESS]: (state, { payload }) => ({
      ...state,
      availableShippingAddresses: payload,
      shippingAddress:
        state.shippingAddress ||
        (payload.length > 0 ? payload[0].id : state.shippingAddress),
    }),
    [FETCH_SHIPPING_METHODS_SUCCESS]: (state, { payload }) => ({
      ...state,
      availableShippingMethods: payload,
      shippingMethod: payload.length > 0 ? payload[0].id : state.shippingMethod,
    }),
    [FETCH_PAYMENT_METHODS_SUCCESS]: (state, { payload }) => ({
      ...state,
      availablePaymentMethods: payload,
    }),
    [CREATE_PAYMENT_METHOD_SUCCESS]: (state, { payload }) => ({
      ...state,
      paymentMethod: payload.id,
      availablePaymentMethods: [
        payload,
        ...(state.availablePaymentMethods || []),
      ],
    }),
    [APPLY_DISCOUNT_CODE_SUCCESS]: updateCart,
    [REMOVE_DISCOUNT_CODE_SUCCESS]: updateCart,
    [APPLY_SHIPPING_ADDRESS_SUCCESS]: updateCart,
    [APPLY_SHIPPING_METHOD_SUCCESS]: updateCart,
    [REMOVE_CART_PRODUCT_SUCCESS]: updateCart,
    [RESTORE_CART_PRODUCT_SUCCESS]: updateCart,
    [UPDATE_CART_PRODUCT_SUCCESS]: updateCart,
    [CHECKOUT]: beginCheckout,
    [CHECKOUT_SUCCESS]: updateConfirmedCheckout,
    [CHECKOUT_FAILURE]: checkoutFailure,
    [CREATE_PAYMENT_METHOD]: beginCheckout,
    [CREATE_PAYMENT_METHOD_FAILURE]: checkoutFailure,
    [FETCH_CONFIRMED_CHECKOUT_SUCCESS]: updateConfirmedCheckout,
    [CLEAR_CHECKOUT_ERROR]: (state) => ({
      ...state,
      checkoutError: null,
    }),
    [SET_IRCLICKID]: (state, { payload }) => ({
      ...state,
      irclickid: payload,
    }),
  },
  initialState
);
