import React from "react";
import cx from "classnames";

const GenericCheckbox = ({
  label,
  checked,
  id,
  onChange,
  className = "",
  labelSize = 17,
}) => {
  return (
    <div className={className}>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <label
          className={cx(
            "form-check-label",
            labelSize ? `gkit-p-${labelSize}` : "gkit-p-17"
          )}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default GenericCheckbox;
