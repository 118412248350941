import React, { useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { createPortal } from "react-dom";

import "./Modal.scss";

const modalRoot = document.getElementById("modal");

const Backdrop = styled.div`
  position: fixed;
  z-index: ${props => props.zIndex};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: ${props => (props.show ? 0.5 : 0)};
  pointer-events: ${props => (props.show ? "all" : "none")};
  transition: opacity 0.2s ease-in-out;
`;

const Modal = ({
  zIndex = 1040,
  name,
  show,
  disableHide,
  children,
  handleModal,
  isLoading,
  disablePositionFix,
}) => {
  const el = useMemo(() => document.createElement("div"), []);
  const modalCount = useMemo(
    () => document.getElementsByClassName("mode").length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show]
  );

  const handleClose = useCallback(() => {
    if (name) {
      handleModal({
        [name]: false,
      });
    } else {
      handleModal(false);
    }
  }, [handleModal, name]);

  const prevHeight = useMemo(() => {
    if (show && document.body.style.position !== "fixed") {
      return window.scrollY;
    }
    return document.body.style.top.replace("-", "").replace("px", "");
  }, [show]);

  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el]);

  useEffect(() => {
    function fixScreen() {
      document.body.style.top = `-${prevHeight}px`;
      document.body.style.position = "fixed";
      document.body.style.left = 0;
      document.body.style.right = 0;
    }

    function releaseScreen() {
      document.body.style.position = "";
      document.body.style.left = null;
      document.body.style.right = null;
      document.body.style.top = null;
      window.scrollTo(0, parseInt(prevHeight || "0"));
    }

    if (!disablePositionFix) {
      if (modalCount <= 1) {
        if (!show) {
          releaseScreen();
        } else {
          fixScreen();
        }
      }
    }

    return () => {
      if (!disablePositionFix && modalCount === 0) {
        document.body.style.position = "";
        document.body.style.left = null;
        document.body.style.right = null;
        document.body.style.top = null;
        window.scrollTo(0, parseInt(prevHeight || "0"));
      }
    };
  }, [show, prevHeight, modalCount, disablePositionFix]);

  const _handleClose = () => {
    if (!isLoading && !disableHide) {
      handleClose();
    }
  };

  return (
    <>
      {createPortal(
        <Backdrop show={show} onClick={_handleClose} zIndex={zIndex} />,
        el
      )}
      {createPortal(
        <CSSTransition
          appear
          in={show}
          timeout={{
            enter: 0,
            exit: 300,
          }}
          classNames="modal-normal"
          mountOnEnter
          unmountOnExit
        >
          <div
            className="mode"
            tabIndex="-1"
            role="dialog"
            style={{
              pointerEvents: "none",
              zIndex: zIndex + modalCount * 10,
            }}
          >
            <div className="mode-dialog" role="document">
              <div className="mode-content">{children}</div>
            </div>
          </div>
        </CSSTransition>,
        el
      )}
    </>
  );
};

export default Modal;
