import React, { useState } from "react";
import { useSelector } from "react-redux";
import ShippingSummaryBlock from "app/checkout/ShippingSummaryBlock";
import RewardsSignupBlock from "./RewardsSignupBlock";
import OrderBreakdownBlock from "./OrderBreakdownBlock";
import constants from "api/constants";

const ConfirmationBlock = () => {
  const { cart } = useSelector((s) => s.checkout);
  const { completedCheckout } = useSelector((s) => s.checkout);
  const [customerEnrolled, setCustomerEnrolled] = useState(false);

  const alreadyEnrolled = completedCheckout.loyalty?.enrolled ?? false;

  return (
    <div>
      <div className="d-flex flex-row align-items-start">
        <div className="d-flex flex-column align-items-start flex-grow-1">
          <h2 className="gkit-h2 m-0">It's ordered!</h2>
          <p className="gkit-p-20 gkit-mobile-15 gkit-light mt-2 mb-0">
            Thanks for your order, we hope you enjoyed shopping with us.
          </p>
        </div>
        <img
          alt="Order Successful"
          src={
            require("assets/images/iconCheckoutConfirmationTick.svg").default
          }
        />
      </div>

      {!(alreadyEnrolled || customerEnrolled) &&
        cart?.country_code === "GB" && (
          <RewardsSignupBlock
            setCustomerEnrolled={setCustomerEnrolled}
            completedCheckout={completedCheckout}
          />
        )}

      <ShippingSummaryBlock />
      <OrderBreakdownBlock
        completedCheckout={completedCheckout}
        className="mt-4"
      />
      <a
        className="gkit-btn-lg gkit-primary w-100 mt-4"
        href={constants.shopifyURL}
      >
        Complete
      </a>
    </div>
  );
};

export default ConfirmationBlock;
