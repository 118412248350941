import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import { addDiscountCode, clearDiscountCode } from "redux/actions/checkout";
import InputField from "shared/components/InputField";

import styles from "./DiscountCodeField.module.scss";

const DiscountCodeField = ({ className }) => {
  const dispatch = useDispatch();
  const {
    cart,
    isClaimed,
    isCheckingOut,
    discountCode: reduxCode,
    isLoggedIn,
  } = useSelector((s) => ({ ...s.checkout, ...s.auth }));
  const [discountCode, setDiscountCode] = useState(reduxCode || "");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const hasDiscount = Boolean(cart.discount);

  useEffect(() => {
    // This should only trigger at the start
    if (!reduxCode) return;
    if ((isLoggedIn && isClaimed) || !isLoggedIn) {
      setError(null);
      setDiscountCode(reduxCode);
      setIsLoading(true);
      dispatch(addDiscountCode(reduxCode))
        .then(
          () => {},
          ({ response }) => {
            setError(response.data.error.message);
            dispatch(clearDiscountCode());
          }
        )
        .finally(() => setIsLoading(false));
    }
  }, [reduxCode, isLoggedIn, dispatch, isClaimed, hasDiscount]);

  useEffect(() => {
    if (!discountCode && cart.discount && cart.discount.code) {
      setDiscountCode(cart.discount.code);
    }
  }, [discountCode, cart.discount, setDiscountCode]);

  const onSubmit = useCallback(() => {
    setIsLoading(true);
    setError(null);
    if (!hasDiscount) {
      dispatch(addDiscountCode(discountCode))
        .then(
          () => {},
          ({ response }) => {
            setError(response.data.error.message);
          }
        )
        .finally(() => setIsLoading(false));
    } else {
      setDiscountCode("");
      dispatch(clearDiscountCode()).finally(() => setIsLoading(false));
    }
  }, [hasDiscount, discountCode, dispatch]);

  return (
    <div>
      <InputField
        className={cx("checkoutDiscountCodeField my-2", className)}
        label="Discount code"
        name="discountCode"
      >
        <div className="d-flex d-row">
          <div className={cx("flex-grow-1", styles.inputContainer)}>
            <input
              className={cx(
                "form-control gkit-input-md text-uppercase",
                Boolean(error) && styles.inputError,
                hasDiscount && styles.inputSuccess
              )}
              disabled={isLoading || hasDiscount || isCheckingOut}
              placeholder="Code"
              type="text"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />
            {hasDiscount && (
              <img
                alt="Successfully Applied Discount"
                src={require("assets/images/iconDiscountTick.svg").default}
              />
            )}
          </div>
          <button
            className={cx(
              "gkit-btn-md gkit-primary ms-2",
              isLoading && "gkit-loading"
            )}
            disabled={isLoading || isCheckingOut}
            onClick={onSubmit}
          >
            {hasDiscount ? "Remove" : "Apply"}
          </button>
        </div>
        {error && (
          <p className="gkit-p-13 gkit-bold text-danger mb-0 mt-1">{error}</p>
        )}
      </InputField>
    </div>
  );
};

export default DiscountCodeField;
