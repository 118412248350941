import React from "react";
import { useSelector } from "react-redux";
import { Button } from "shared/Button";

const ContinueBlock = ({ onClick }) => {
  const { contactDetails, shippingAddress } = useSelector((s) => s.checkout);

  const isDisabled = !Boolean(contactDetails && shippingAddress);

  return (
    <div className="pt-4">
      <div className="row justify-content-end">
        <div className="col-lg-6">
          <Button
            className="w-100"
            disabled={isDisabled}
            onClick={onClick}
            size="lg"
          >
            Continue to payment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContinueBlock;
