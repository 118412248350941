import React, { useMemo } from "react";
import cx from "classnames";
import _ from "lodash";

import IconLineItem from "./orderBreakdownBlock/IconLineItem";
import DashedSeparator from "shared/components/DashedSeparator";

const OrderBreakdownBlock = ({ completedCheckout, className }) => {
  const isSubscription = completedCheckout.type === "subscription";

  const lineItems = useMemo(() => {
    const innerLineineItems = isSubscription
      ? (
        completedCheckout.subscription.last_dispatch ||
        completedCheckout.subscription
      ).products
      : completedCheckout.order.products;

    return innerLineineItems.map(({ quantity, product }) =>
      [quantity > 1 ? `${quantity}x` : null, product.title]
        .filter(Boolean)
        .join(" ")
    );
  }, [completedCheckout, isSubscription]);

  const shippingValue = isSubscription
    ? null
    : _.get(completedCheckout, "order.shipping_method.price.formatted");

  const paidAmount = isSubscription
    ? _.get(
      completedCheckout,
      "subscription.last_dispatch.order.payment.captured.formatted"
    )
    : _.get(completedCheckout, "order.payment.captured.formatted");

  const hasFreeTin = _.get(
    completedCheckout,
    "subscription.last_dispatch.products",
    []
  ).find(
    product =>
      product.children?.[0]?.type === "rule" &&
      product.children?.[0]?.removed === false
  );

  return (
    <div className={cx("px-md-3", className)}>
      <p className="gkit-p-20 gkit-bold my-0">Order breakdown</p>
      <div className="mt-4">
        {/* Line Items in your Subscription */}
        <IconLineItem
          icon={require("assets/images/iconProductTag.svg").default}
        >
          {lineItems.map((lineItem, idx) => (
            <p
              className={cx(
                "gkit-p-17 gkit-bold mb-0",
                idx > 0 ? "mt-2" : "mt-0"
              )}
              key={String(idx)}
            >
              {lineItem}
            </p>
          ))}
        </IconLineItem>

        {/* Optional Free Grind Tin Line */}
        {isSubscription && hasFreeTin && (
          <IconLineItem
            className="mt-4"
            icon={require("assets/images/iconGrindTin.svg").default}
            isFree={true}
          >
            <p className="gkit-p-17 gkit-bold my-0">Grind Tin</p>
          </IconLineItem>
        )}

        {/* Shipping Line */}
        <IconLineItem
          className="mt-4"
          icon={require("assets/images/iconShippingTruck.svg").default}
          isFree={!Boolean(shippingValue)}
          value={shippingValue}
        >
          <p className="gkit-p-17 gkit-bold my-0">Shipping</p>
          <p className="gkit-p-15 gkit-light mt-2 mb-0">
            Climate compensated shipping{" "}
            <span role="img" aria-label="Eco friendly">
              🍃
            </span>
          </p>
        </IconLineItem>

        <DashedSeparator className="d-md-none my-2" />

        <div className="d-flex flex-row justify-content-between mt-4">
          <p className="gkit-h3 my-0">Paid today</p>
          <p className="gkit-p-22 my-0">{paidAmount || "£0.00"}</p>
        </div>
      </div>
    </div>
  );
};

export default OrderBreakdownBlock;
