import React, { useState } from "react";

import Modal from "../../../../shared/Modal";

import Login from "./Login";
import Register from "./Register";
import PasswordLogin from "./PasswordLogin";
import CodeLogin from "./CodeLogin";

import "./LoginModal.scss";

/**
 * login states
 * 1. login
 * 2. register
 * 3. passwordLogin
 * 4. codeLogin
 */

const LoginModal = ({ isVisible, ...props }) => {
  const [loginState, setLoginState] = useState("login"); // login, register, passwordLogin, codeLogin
  const [emailState, setEmailState] = useState("");
  return (
    <Modal handleModal={props.handleModal} show={isVisible}>
      {loginState === "login" ? (
        <Login setLoginState={setLoginState} setEmailState={setEmailState} />
      ) : null}
      {loginState === "register" ? (
        <Register
          setLoginState={setLoginState}
          emailState={emailState}
          handleModal={props.handleModal}
        />
      ) : null}
      {loginState === "passwordLogin" ? (
        <PasswordLogin
          setLoginState={setLoginState}
          handleModal={props.handleModal}
          emailState={emailState}
        />
      ) : null}
      {loginState === "codeLogin" ? (
        <CodeLogin
          setLoginState={setLoginState}
          handleModal={props.handleModal}
          emailState={emailState}
        />
      ) : null}
    </Modal>
  );
};

export default LoginModal;
