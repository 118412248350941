import React, { useState, useRef } from "react";
import cx from "classnames";
import { useFormik, getIn } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import api, { extractAPIErrorString } from "../../../../../api";

import { Button } from "../../../../../shared/Button";
import ErrorHandling from "../../../../../shared/ErrorHandling";

import { codeLogin } from "../../../../../redux/actions/auth";

import styles from "./CodeLogin.module.scss";

const CodeLoginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  code: Yup.array().of(Yup.number().lessThan(10).required()),
  // .strict()
});

const SpecialInput = ({
  inputRef,
  errors,
  values,
  touched,
  name,
  ...props
}) => {
  return (
    <input
      name={name}
      ref={inputRef}
      value={getIn(values, name)}
      className={cx(
        "form-control gkit-input-md mx-1",
        getIn(errors, name) && getIn(touched, name) ? styles.isInvalid : null
      )}
      autoComplete="off"
      inputMode="numeric"
      {...props}
    />
  );
};

const CodeLogin = ({ handleModal, emailState }) => {
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(null);
  const [resent, setResent] = useState("idle"); // idle, sending, done
  const oneRef = useRef();
  const twoRef = useRef();
  const threeRef = useRef();
  const fourRef = useRef();
  const fiveRef = useRef();
  const sixRef = useRef();
  const refMap = {
    1: oneRef,
    2: twoRef,
    3: threeRef,
    4: fourRef,
    5: fiveRef,
    6: sixRef,
  };
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: emailState || "",
      code: ["", "", "", "", "", ""],
    },
    validationSchema: CodeLoginSchema,
    validateOnBlur: false,
    onSubmit: ({ email, code }) => {
      setHasError(null);
      setResent("idle");

      dispatch(codeLogin({ email, code: code.join("") })).then(
        () => {
          // Logged In...
        },
        err => setHasError(extractAPIErrorString(err))
      );
    },
  });

  const handleInputChange = (e, current, next) => {
    if (e.target.value.length > 1) {
      // dont allow more than 1 digit
      if (next) {
        refMap[next].current.focus();
      }
    } else {
      setFieldValue(`code.${current - 1}`, e.target.value);
    }
    if (e.target.value.length === 1) {
      if (next) {
        refMap[next].current.focus();
      }
    }
  };
  const handleKeyChange = (e, current) => {
    if (e.keyCode !== 8) return;
    if (!getIn(values, `code.${current - 1}`)) {
      if (current !== 1) {
        refMap[current - 1].current.focus();
      }
    }
  };

  const requestCode = ({ email }) => {
    setHasError(null);
    setResent("sending");
    return api.post("/auth/login", { email }).then(
      () => {
        setResent("done");
      },
      error => {
        setHasError(extractAPIErrorString(error));
        setResent("idle");
      }
    );
  };

  const handlePaste = e => {
    const pastedvalue = e.clipboardData.getData("Text");
    if (pastedvalue.length === 6) {
      setFieldValue("code.0", pastedvalue[0], false);
      setFieldValue("code.1", pastedvalue[1], false);
      setFieldValue("code.2", pastedvalue[2], false);
      setFieldValue("code.3", pastedvalue[3], false);
      setFieldValue("code.4", pastedvalue[4], false);
      setFieldValue("code.5", pastedvalue[5], false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4 mt-md-0 p-md-4">
      <h2 className="gkit-h2 text-md-center">Enter your verification Code</h2>
      <p className="gkit-p-17 gkit-light text-md-center">{emailState}</p>
      <p className="gkit-p-17 gkit-light text-md-center">
        You should have been sent this in an email from Grind. Please enter your
        6 digit code below.
      </p>
      <div className={`form-group ${styles.codeContainer} mb-4`}>
        <SpecialInput
          name="code.0"
          inputRef={oneRef}
          onChange={e => handleInputChange(e, 1, 2)}
          onKeyDown={e => handleKeyChange(e, 1)}
          values={values}
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          onPaste={handlePaste}
        />
        <SpecialInput
          name="code.1"
          inputRef={twoRef}
          onChange={e => handleInputChange(e, 2, 3)}
          onKeyDown={e => handleKeyChange(e, 2)}
          values={values}
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          onPaste={handlePaste}
        />
        <SpecialInput
          name="code.2"
          inputRef={threeRef}
          onChange={e => handleInputChange(e, 3, 4)}
          onKeyDown={e => handleKeyChange(e, 3)}
          values={values}
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          onPaste={handlePaste}
        />
        <SpecialInput
          name="code.3"
          inputRef={fourRef}
          onChange={e => handleInputChange(e, 4, 5)}
          onKeyDown={e => handleKeyChange(e, 4)}
          values={values}
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          onPaste={handlePaste}
        />
        <SpecialInput
          name="code.4"
          inputRef={fiveRef}
          onChange={e => handleInputChange(e, 5, 6)}
          onKeyDown={e => handleKeyChange(e, 5)}
          values={values}
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          onPaste={handlePaste}
        />
        <SpecialInput
          name="code.5"
          inputRef={sixRef}
          onChange={e => handleInputChange(e, 6)}
          onKeyDown={e => handleKeyChange(e, 6)}
          values={values}
          errors={errors}
          touched={touched}
          onBlur={handleBlur}
          onPaste={handlePaste}
        />
      </div>
      <ErrorHandling error={hasError} />
      <Button
        size="md"
        className="mt-3"
        type="submit"
        id="submit-button"
        isLoading={isSubmitting}
      >
        Continue
      </Button>
      {/* eslint jsx-a11y/anchor-is-valid: "off" */}
      <p className="gkit-p-15 mt-4 text-md-center">
        No code? {resent === "done" ? <strong>Code resent.</strong> : null}
        {resent === "sending" ? <strong>Processing..</strong> : null}
        {resent === "idle" ? (
          <strong
            className="cursor-pointer"
            onClick={() => requestCode({ email: emailState })}
          >
            Resend verification code email
          </strong>
        ) : null}
      </p>
    </form>
  );
};

export default CodeLogin;
