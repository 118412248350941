import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  initialiseEnquireLabs,
  loadConfirmedCheckout,
} from "redux/actions/checkout";
import ConfirmationBlock from "./checkoutConfirmation/ConfirmationBlock";
import TrustpilotBlock from "./checkoutConfirmation/TrustpilotBlock";
import EnquireModal from "./testPage/EnquireModal";

const CheckoutConfirmation = () => {
  const dispatch = useDispatch();
  const { checkoutToken } = useParams();
  const { completedCheckout, contactDetails } = useSelector((s) => ({
    ...s.auth,
    ...s.checkout,
  }));

  // TODO: Referral Data
  // const [referralData, setReferralData] = useState(null);

  const [isEnquireModalVisible, setIsEnquireModalVisible] = useState(false);

  // We email them about their referrals, so maybe we can take this out
  // const [showReferralModal, setShowReferralModal] = useState(false);

  useEffect(() => {
    if (completedCheckout && contactDetails && window.EnquireLabs) {
      setTimeout(() => {
        setIsEnquireModalVisible(true);
      }, 2000);

      setTimeout(() => {
        dispatch(initialiseEnquireLabs(completedCheckout, contactDetails));
      }, 2250);
    }
  }, [completedCheckout, contactDetails, dispatch]);

  useEffect(() => {
    if (completedCheckout && completedCheckout.token === checkoutToken) {
      return;
    }

    dispatch(loadConfirmedCheckout(checkoutToken));
  }, [checkoutToken, completedCheckout, dispatch]);

  // useEffect(() => {
  //   if (isLoggedIn && !referralData) {
  //     api.get("/account/referral-code").then(({ data }) => {
  //       if (data) {
  //         setReferralData(data);

  //         setTimeout(() => {
  //           setShowReferralModal(true);
  //         }, 1000);
  //       }
  //     });
  //   }
  // }, [isLoggedIn, referralData]);

  if (!completedCheckout) {
    return null;
  }

  return (
    <div className="checkoutConfirmation container py-5">
      {/*referralData && (
        <ReferralModal
          referralData={referralData}
          show={showReferralModal}
          handleModal={() => setShowReferralModal(false)}
        />
      )
      */}
      <div className="row">
        <div className="d-none d-md-block col-md-6 col-lg-5">
          <TrustpilotBlock />
        </div>
        <div className="col col-md-6 col-lg-7">
          <ConfirmationBlock />
        </div>
      </div>
      <EnquireModal
        isVisible={isEnquireModalVisible}
        onDismissed={() => setIsEnquireModalVisible(false)}
      />
    </div>
  );
};

export default CheckoutConfirmation;
