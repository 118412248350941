import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import InputField from "shared/components/InputField";

import "shared/components/StripeStyling.scss";

const StripeCardInput = ({ onChange }) => (
  <InputField label="Card details">
    <CardElement
      className="gkit-input-md gkit-stripe-input"
      onChange={onChange}
      options={{
        style: {
          base: {
            fontFamily: "ApercuPro, sans-serif",
            fontSize: "17px"
          }
        }
      }}
    />
  </InputField>
);

export default StripeCardInput;
