import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { getIn } from "formik";
import "./Input.scss";

const Input = ({
  label,
  values,
  touched,
  name,
  errors,
  type = "text",
  autoComplete = "off",
  placeholder,
  className,
  isLoading,
  ...props
}) => (
  <div className={cx("form-group", className)}>
    {label ? <label className="gkit-input-label">{label}</label> : null}
    <div className="control">
      <input
        name={name}
        value={getIn(values, name)}
        className={cx(
          "form-control gkit-input-md",
          getIn(errors, name) && getIn(touched, name) ? "is-invalid" : null
        )}
        type={type}
        autoComplete={autoComplete}
        placeholder={placeholder}
        {...props}
      />
    </div>
    {getIn(errors, name) && getIn(touched, name) ? (
      <div className="invalid-feedback d-block">{getIn(errors, name)}</div>
    ) : null}
  </div>
);

const InputWithImagePrepend = ({
  label,
  values,
  touched,
  name,
  errors,
  type = "text",
  autoComplete = "off",
  placeholder,
  className,
  isLoading,
  image,
  ...props
}) => (
  <div className={cx("form-group", className)}>
    {label ? <label className="gkit-input-label">{label}</label> : null}
    <div className="control" id="prepend-control">
      <img className="prepend-image" src={image} alt="placeholder indicator" />
      <input
        name={name}
        value={getIn(values, name)}
        className={cx(
          "form-control gkit-input-md input-with-image",
          getIn(errors, name) && getIn(touched, name) ? "is-invalid" : null
        )}
        type={type}
        autoComplete={autoComplete}
        placeholder={placeholder}
        {...props}
      />
    </div>
    {getIn(errors, name) && getIn(touched, name) ? (
      <div className="invalid-feedback d-block">{getIn(errors, name)}</div>
    ) : null}
  </div>
);

InputWithImagePrepend.propTypes = {
  image: PropTypes.string.isRequired
};

const RadioGroup = ({ name, errors, label, className, children, ...props }) => (
  <fieldset name={name} className={cx("form-group", className)} {...props}>
    {label ? <label className="gkit-input-label">{label}</label> : null}
    {children}
    {getIn(errors, name) ? (
      <div className="invalid-feedback">{errors[name]}</div>
    ) : null}
  </fieldset>
);

const Radio = ({ id, values, name, prefix, className, children, ...props }) => {
  return (
    <>
      <label
        className={cx(
          "custom-control custom-radio d-flex flex-row align-items-center",
          className
        )}
      >
        <input
          type="radio"
          className="custom-control-input"
          id={prefix ? `${prefix}${id}` : id}
          name={name}
          checked={String(id) === String(values[name])}
          value={id}
          {...props}
        />
        <label
          className="custom-control-label"
          htmlFor={prefix ? `${prefix}${id}` : id}
        >
          {children}
        </label>
      </label>
    </>
  );
};

export { Input, RadioGroup, Radio, InputWithImagePrepend };
