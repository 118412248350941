import React from "react";
import cx from "classnames";

import styles from "./CartLineItem.module.scss";
import LineItemVariant from "./LineItemVariant";
import LineItemBadge from "./LineItemBadge";

import { strWithSuperR } from "../../../shared/utility";

const CartLineItem = ({ className, lineItem }) => {
  const freeTinRemoved = Boolean(
    lineItem.children?.find((child) => child.removed)
  );

  const isMatchaProduct = Boolean(
    lineItem.product.title.toUpperCase().includes("MATCHA")
  );

  const isSubscription = Boolean(lineItem.type === "subscription");

  return (
    <div
      className={cx(
        "d-flex flex-row align-items-start",
        styles.container,
        className
      )}
    >
      {/* Product Image */}
      <div className={styles.imgContainer}>
        <img
          alt={lineItem.product.title}
          src={
            lineItem.product.image
              ? (lineItem.variant?.image?.url ?? lineItem.product.image.url) +
                "&width=300"
              : require("assets/images/imagePlaceholder.png").default
          }
        />
        <div className={styles.quantityContainer}>
          <p className={cx("gkit-p-15 gkit-bold m-0", styles.quantityText)}>
            {lineItem.quantity}
          </p>
        </div>
      </div>

      {/* Product Meta */}
      <div>
        <div className={cx("mb-2", styles.badgeContainer)}>
          {/* Product type badge */}
          {lineItem.type && <LineItemBadge type={lineItem.type} />}

          {/* Matcha tin badge */}
          {isSubscription && isMatchaProduct && !freeTinRemoved ? (
            <LineItemBadge type="matchatin" />
          ) : null}
        </div>

        {/* Product Title */}
        <p className="gkit-p-17 gkit-mobile-15 gkit-bold my-0">
          {strWithSuperR(lineItem.product.title)}
        </p>

        {/* Product Variant */}
        {lineItem.variant && <LineItemVariant variant={lineItem.variant} />}

        {/* Product Price */}
        <p className="mb-0 mt-0 mt-md-2">
          <span
            className={cx(
              "gkit-p-20 gkit-mobile-15 gkit-bold d-inline",
              (lineItem.discount.amount !== "0.00" ||
                lineItem.total.amount === "0.00") &&
                "gkit-green"
            )}
          >
            {lineItem.subtotal.amount === "0.00"
              ? "Free"
              : lineItem.total.formatted}
          </span>
          {lineItem.discount.amount !== "0.00" && (
            <span className="gkit-p-13 gkit-bold d-inline ms-1">
              <del>{lineItem.subtotal.formatted}</del>
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default CartLineItem;
