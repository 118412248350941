import React, { useEffect, useRef } from "react";
import cx from "classnames";

import { Tooltip } from 'bootstrap';

import styles from "./RecurringCost.module.scss";

const RecurringCost = ({ className, label, cart, interval, terms }) => {
  const tooltipRef = useRef(null);

  // For the recurring cost, we only want calculate the subscription product subtotals.
  const subscriptionProducts = cart.products.filter(product => product.type === "subscription");
  const subscriptionSubtotal = subscriptionProducts.reduce((a, b) => a + Number(b.subtotal.amount), 0);
  const formattedSubtotal = new Intl.NumberFormat(`en-${cart.country_code}`, { style: 'currency', currency: cart.currency }).format(
    subscriptionSubtotal,
  );

  useEffect(() => {
    const tooltip = new Tooltip(tooltipRef.current, {
      title: terms,
      placement: 'top',
      trigger: 'hover'
    })

    return () => {
      tooltip.dispose();
    };
  }, [terms]);

  const valueStr = `${formattedSubtotal} every ${interval > 1 ? `${interval} weeks` : 'week'}`;

  return (
    <div>
      <div
        className={cx(
          "d-flex flex-row justify-content-between align-items-start",
          styles.container,
          className
        )}
      >
        <p className={cx("gkit-p-15 gkit-bold m-0 gkit-light d-flex align-items-center gap-1 flex-shrink-0")}>
          {label}

          <img
            type="button"
            className={styles.icon}
            alt=""
            src={require("assets/images/iconQuestionMark.svg").default}
            data-bs-toggle="tooltip"
            ref={tooltipRef}
          />
        </p>

        <p className={cx("gkit-p-15 ms-4 my-0 gkit-light", styles.value)}>
          {valueStr}
        </p>
      </div>
    </div>
  );
};

export default RecurringCost;
