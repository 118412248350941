import React, { useState } from "react";
import cx from "classnames";
import { useField } from "formik";
import { uniqueId } from "lodash";

const InputCheckbox = ({ children, className, ...props }) => {
  const [field, meta] = useField(props);
  const [referenceId] = useState(uniqueId("checkbox-"));
  return (
    <label
      className={cx(
        "custom-control custom-checkbox d-flex flex-row align-items-center mb-3",
        className
      )}
    >
      <input
        type="checkbox"
        className={cx(
          "custom-control-input",
          meta && meta.touched && meta.error && "is-invalid"
        )}
        checked={field.value}
        {...field}
        {...props}
        id={referenceId}
      />
      <label className="custom-control-label gkit-p-17" htmlFor={referenceId}>
        {children}
      </label>
      {meta && meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </label>
  );
};

export default InputCheckbox;
