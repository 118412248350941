import React from "react";
import cx from "classnames";

import styles from "./Spinner.module.scss";

const Spinner = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <img
      alt="Loading..."
      className={styles.spinner}
      src={require("assets/img/iconSpinnerPink.svg").default}
    />
  </div>
);

export default Spinner;
