import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";

import styles from "./ShippingSummaryBlock.module.scss";
import {
  addressLinesFromAddress,
  getSelectedShippingAddress,
} from "shared/utility";

const ShippingSummaryBlock = ({ className, onClick }) => {
  const { shippingAddress, availableShippingAddresses, completedCheckout } =
    useSelector(s => s.checkout);

  // This should grab the Shipping Address from a completed checkout,
  // authenticated selection or anonymous selection in that order.
  const selectedShippingAddress = useMemo(
    () =>
      getSelectedShippingAddress({
        shippingAddress,
        availableShippingAddresses,
        completedCheckout,
      }),
    [shippingAddress, completedCheckout, availableShippingAddresses]
  );

  if (!selectedShippingAddress) {
    return null;
  }

  const addressLines = addressLinesFromAddress(selectedShippingAddress);

  return (
    <div className={cx("mt-4", styles.container, className)}>
      <div className="row">
        <div className="col-auto">
          <img
            alt="Your shipping information"
            src={require("assets/img/iconShippingInfo.svg").default}
          />
        </div>
        <div className="col">
          <p className="gkit-p-17 gkit-bold mb-2">Shipping to</p>
          <p className={cx("gkit-p-15 m-0", styles.g0)}>
            {addressLines.map((line, idx) => (
              <Fragment key={String(idx)}>
                {idx > 0 && <br />}
                {line}
              </Fragment>
            ))}
          </p>
        </div>
        {onClick && (
          <div className="col-auto">
            <button type="button" className="btn gkit-p-17" onClick={onClick}>
              Edit
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShippingSummaryBlock;
