import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import api, { extractAPIErrorString } from "../../../../../api";

import { Input } from "../../../../../shared/Input";
import { Button } from "../../../../../shared/Button";
import ErrorHandling from "../../../../../shared/ErrorHandling";
import { passwordLogin } from "redux/actions/auth";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

const PasswordLogin = ({ handleModal, emailState, setLoginState }) => {
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(null);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: emailState || "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: ({ email, password }) => {
      setHasError(null);

      return dispatch(passwordLogin({ email, password })).then(
        () => {
          // Logged In
        },
        err => setHasError(extractAPIErrorString(err))
      );
    },
  });

  const handleCodeLogin = () => {
    setLoginState("codeLogin");
    return api.post("/auth/login", { email: emailState });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="mt-4 mt-md-0 p-md-4"
      id="password-login"
    >
      <h2 className="gkit-h2 text-md-center">Log in</h2>
      <p className="gkit-p-17 gkit-light text-md-center">
        Welcome back. Enter your password to continue.
      </p>
      <Input
        className="mt-3"
        name="email"
        label="Email"
        values={values}
        errors={errors}
        touched={touched}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="jane@email.com"
        type="email"
      />
      <Input
        name="password"
        label="Password"
        values={values}
        errors={errors}
        touched={touched}
        type="password"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorHandling error={hasError} />
      <Button
        size="md"
        className="mt-3"
        type="submit"
        id="submit-button"
        isLoading={isSubmitting}
      >
        Continue
      </Button>
      <Button
        size="md"
        btnType="secondary"
        className="mt-3"
        type="button"
        id="submit-button"
        onClick={handleCodeLogin}
      >
        Login without password
      </Button>
      {/* <p className="gkit-p-15 mt-3 text-md-center">
        New to Grind?{" "}
        <strong
          className="cursor-pointer"
          onClick={() => setLoginState("login")}
        >
          Create an account
        </strong>
      </p> */}
    </form>
  );
};

export default PasswordLogin;
