import React from "react";
import cx from "classnames";

import PageHeader from "./PageHeader";

import styles from "./PageContainer.module.scss";

const PageContainer = ({ children }) => (
  <div className={cx("outerCheckoutContainer", styles.container)}>
    <PageHeader />
    {children}
  </div>
);

export default PageContainer;
