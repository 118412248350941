import React from "react";
import BootstrapModal from "shared/components/BootstrapModal";
import Spinner from "shared/components/Spinner";

import "./EnquireModal.scss";

const EnquireModal = ({ isVisible, onDismissed }) => {
  return (
    <BootstrapModal isVisible={isVisible} onDismissed={onDismissed}>
      <div className="modal-header">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <div id="enquire_labs_target">
          <Spinner />
        </div>
      </div>
    </BootstrapModal>
  );
};

export default EnquireModal;
