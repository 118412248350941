import React, { useMemo } from "react";

import cx from "classnames";

import styles from "./TinSelectBlock.module.scss";
import { multiTinLookup } from "../checkoutConstants";
import { getStore } from "../../../shared/utility";

const TinBlock = ({
  className,
  isLoading,
  onSelect,
  isSelected,
  tinOption,
}) => {
  const targetTin = useMemo(() => {
    if (!tinOption) {
      return null;
    }

    return Object.values(multiTinLookup).find(
      ({ variantTitles }) =>
        variantTitles.indexOf(tinOption.variant_title) !== -1
    );
  }, [tinOption]);

  const optionName = targetTin?.title || tinOption?.variant_title;
  const isAvailable = tinOption
    ? targetTin?.availableLocations.includes(getStore(window.location.search))
    : true;

  return isAvailable ? (
    <div className={cx(styles.tinBlockContainer, className)}>
      <div
        className={cx(
          styles.imageContainer,
          "d-flex align-items-center justify-content-center"
        )}
      >
        {tinOption?.image && (
          <img
            alt={tinOption.variant_title}
            className={cx("w-100")}
            src={tinOption.image.url}
          />
        )}
      </div>
      <p className="gkit-p-13 fw-normal mt-1 mb-0">{optionName || "No Tin"}</p>
      <button
        className={cx(
          "gkit-btn gkit-btn-sm",
          isSelected ? "gkit-primary" : "gkit-secondary",
          styles.toggle,
          isSelected && styles.active,
          isLoading && "gkit-loading",
          "mt-1"
        )}
        disabled={isSelected}
        onClick={onSelect}
        type="button"
      >
        {isSelected ? "Selected" : "Select"}
      </button>
    </div>
  ) : null;
};

export default TinBlock;
