import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "bootstrap";
import cx from "classnames";

const modalRoot = document.getElementById("bs-modal");

const BootstrapModal = ({ children, isVisible, onDismissed, isCentered }) => {
  const modalRef = useRef();
  const [isShowing, setIsShowing] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);
  const [modal, setModal] = useState(null);

  // Creates reference to bootstrap modal
  useEffect(() => {
    if (!modal && modalRef.current) {
      // If modal is not set, set it
      const newModal = new Modal(modalRef.current);
      setModal(newModal);

      modalRef.current.addEventListener("hidden.bs.modal", () => {
        setIsDismissed(true);
        setIsShowing(false);
        onDismissed();
      });
    }
  }, [modal, onDismissed]);

  useEffect(() => {
    // If there's no modal ref, don't do anything (as we can't obv)
    if (!modal) {
      return;
    }

    // If the grand show-er has acknowledged it's no longer visible
    // after it's user-dismissed, then we'll allow it to be re-shown
    if (!isShowing && !isVisible && isDismissed) {
      setIsDismissed(false);
    }

    // If isShowing and isVisible are the same, we don't need to do anything
    if (isShowing === isVisible || (isVisible && isDismissed)) {
      return;
    }

    isVisible ? modal.show() : modal.hide();
    setIsShowing(isVisible);
  }, [isShowing, isVisible, isDismissed, modal]);

  return createPortal(
    <div
      className="modal fade"
      id="reactBootstrapModal"
      ref={modalRef}
      tabIndex="-1"
      aria-labelledby="modal"
      {...(isVisible
        ? { "aria-modal": "true", role: "dialog" }
        : { "aria-hidden": "true" })}
    >
      <div
        className={cx(
          "modal-dialog",
          isCentered ? "modal-dialog-centered" : undefined
        )}
      >
        <div className="modal-content">{children}</div>
      </div>
    </div>,
    modalRoot
  );
};

export default BootstrapModal;
