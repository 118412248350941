import React, { useCallback } from "react";
import cx from "classnames";

import { paymentTypes } from "../checkoutConstants";

const PaymentTypeOption = ({ className, isSelected, onSelect, type }) => {
  const onChange = useCallback(
    ({ target }) => {
      onSelect(target.value);
    },
    [onSelect]
  );
  let contents;

  switch (type) {
    case paymentTypes.APPLE_PAY:
      contents = (
        <img
          alt="Apple Pay"
          src={require("assets/images/logoApplePay.svg").default}
          style={{ height: "26px" }}
        />
      );
      break;

    case paymentTypes.CARD:
      contents = (
        <div className="d-flex flex-column align-items-start">
          <p className="gkit-p-17 gkit-bold m-0">Credit or Debit Card</p>
          <div className="d-flex flex-row mt-2">
            <img
              alt="MasterCard"
              className="me-2"
              src={require("assets/images/logoMasterCard.svg").default}
              style={{ height: "26px" }}
            />
            <img
              alt="American Express"
              className="me-2"
              src={require("assets/images/logoAmericanExpress.svg").default}
              style={{ height: "26px" }}
            />
            <img
              alt="Visa"
              src={require("assets/images/logoVisa.svg").default}
              style={{ height: "26px" }}
            />
          </div>
        </div>
      );
      break;

    case paymentTypes.PAYPAL:
      contents = (
        <img
          alt="PayPal"
          src={require("assets/images/logoPayPal.svg").default}
          style={{ height: "22px" }}
        />
      );
      break;

    default:
      contents = null;
      break;
  }

  if (!contents) {
    return null;
  }

  return (
    <div className={cx("col-12 col-md-6", className)}>
      <label className="d-flex flex-row align-items-start">
        <input
          className="me-3 mt-1"
          type="radio"
          onChange={onChange}
          value={type}
          checked={isSelected}
        />
        {contents}
      </label>
    </div>
  );
};

export default PaymentTypeOption;
