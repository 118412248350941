import { useStripe } from "@stripe/react-stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import { setHasCardDetails, setPaymentMethod } from "redux/actions/checkout";
import { paymentTypes } from "../checkoutConstants";
import PaymentTypeOption from "./PaymentTypeOption";
import StripeCardInput from "./StripeCardInput";
import { sendEvent } from "shared/DataLayer";
import Bugsnag from "@bugsnag/js";

const NewPaymentMethod = () => {
  const { cart, paymentMethod, paymentProviders } = useSelector((s) => ({
    ...s.checkout,
    ...s.config,
  }));

  const { country_code: countryCode, currency } = cart;

  const dispatch = useDispatch();
  const stripe = useStripe();
  const [supportsApplePay, setSupportsApplePay] = useState(null);

  const onPaymentTypeSelect = useCallback(
    (paymentType) => {
      dispatch(setPaymentMethod(paymentType));
      sendEvent("setPaymentMethod", paymentType);
    },
    [dispatch]
  );

  // Handles letting the store know if we've got a fully populated stripe card
  // field.
  const onCardDetailsChanged = useCallback(
    ({ complete }) => {
      dispatch(setHasCardDetails(complete));
    },
    [dispatch]
  );

  const paymentDue = cart.payment_due.amount;

  // Handles generating a payment request for Apple Pay.
  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!window.ApplePaySession) {
      setSupportsApplePay(false);
      return;
    }

    try {
      const pr = stripe.paymentRequest({
        country: countryCode,
        currency: currency.toLowerCase(),
        requestPayerEmail: false,
        requestPayerPhone: false,
        requestPayerName: false,
        requestShipping: false,
        total: {
          label: "Grind",
          amount: Math.round(parseFloat(paymentDue) * 100),
        },
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setSupportsApplePay(true);
        }
      });
    } catch (e) {
      setSupportsApplePay(false);
      Bugsnag.notify(e);
    }
  }, [countryCode, currency, paymentDue, stripe]);

  const availablePaymentTypes = [
    supportsApplePay ? paymentTypes.APPLE_PAY : null,
    paymentProviders && paymentProviders.paypal ? paymentTypes.PAYPAL : null,
    paymentTypes.CARD,
  ].filter(Boolean);

  return (
    <div>
      <div className="row">
        {availablePaymentTypes.map((paymentType, idx) => (
          <PaymentTypeOption
            className={cx(idx > 0 && "mt-2", idx < 2 && "mt-md-0")}
            isSelected={paymentType === paymentMethod}
            onSelect={onPaymentTypeSelect}
            type={paymentType}
            key={paymentType}
          />
        ))}
      </div>
      {/* When card is selected, show card detail collection field */}
      {paymentMethod === paymentTypes.CARD && (
        <div className="mt-2" style={{ maxWidth: "360px" }}>
          <StripeCardInput onChange={onCardDetailsChanged} />
        </div>
      )}
    </div>
  );
};

export default NewPaymentMethod;
