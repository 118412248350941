import React from "react";
import cx from "classnames";

const IconLineItem = ({ children, className, icon, isFree, value }) => (
  <div className={cx("d-flex flex-row align-items-start", className)}>
    <img alt="Line Item" src={icon} />
    <div className="ms-2 flex-grow-1">{children}</div>
    {Boolean(isFree || value) && (
      <p
        className={cx(
          "gkit-p-17 flex-shrink-0 ms-3 my-0",
          isFree && "gkit-green"
        )}
      >
        {isFree ? "Free" : value}
      </p>
    )}
  </div>
);
export default IconLineItem;
