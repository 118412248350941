import { combineReducers } from "redux";
import auth from "./reducers/auth";
import config from "./reducers/config";
import order from "./reducers/order";
import checkout from "./reducers/checkout";

export default combineReducers({
  auth,
  checkout,
  config,
  order
});
