import _ from "lodash";
import { createActions } from "redux-actions";

import api from "api";
import {
  APPLY_DISCOUNT_CODE,
  APPLY_DISCOUNT_CODE_FAILURE,
  APPLY_DISCOUNT_CODE_SUCCESS,
  APPLY_SHIPPING_ADDRESS,
  APPLY_SHIPPING_ADDRESS_FAILURE,
  APPLY_SHIPPING_ADDRESS_SUCCESS,
  APPLY_SHIPPING_METHOD,
  APPLY_SHIPPING_METHOD_FAILURE,
  APPLY_SHIPPING_METHOD_SUCCESS,
  CLAIM_CART,
  CLAIM_CART_FAILURE,
  CLAIM_CART_SUCCESS,
  CREATE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD_FAILURE,
  CREATE_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHODS,
  FETCH_PAYMENT_METHODS_FAILURE,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_SHIPPING_ADDRESSES,
  FETCH_SHIPPING_ADDRESSES_FAILURE,
  FETCH_SHIPPING_ADDRESSES_SUCCESS,
  FETCH_SHIPPING_METHODS,
  FETCH_SHIPPING_METHODS_FAILURE,
  FETCH_SHIPPING_METHODS_SUCCESS,
  IMPORT_CART,
  IMPORT_CART_FAILURE,
  IMPORT_CART_SUCCESS,
  REMOVE_CART_PRODUCT,
  REMOVE_CART_PRODUCT_FAILURE,
  REMOVE_CART_PRODUCT_SUCCESS,
  UPDATE_CART_PRODUCT,
  UPDATE_CART_PRODUCT_FAILURE,
  UPDATE_CART_PRODUCT_SUCCESS,
  REMOVE_DISCOUNT_CODE,
  REMOVE_DISCOUNT_CODE_FAILURE,
  REMOVE_DISCOUNT_CODE_SUCCESS,
  RESTORE_CART_PRODUCT,
  RESTORE_CART_PRODUCT_FAILURE,
  RESTORE_CART_PRODUCT_SUCCESS,
  SET_CONTACT_DETAILS,
  SET_HAS_CARD_DETAILS,
  SET_PAYMENT_METHOD,
  SET_SHIPPING_ADDRESS,
  SET_SHIPPING_METHOD,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  FETCH_CONFIRMED_CHECKOUT,
  FETCH_CONFIRMED_CHECKOUT_SUCCESS,
  FETCH_CONFIRMED_CHECKOUT_FAILURE,
  CLEAR_CHECKOUT_ERROR,
  SET_IRCLICKID,
  SET_ACCEPTS_MARKETING,
  STORE_DISCOUNT_CODE,
} from "redux/constants";
import {
  generateImpactTrackingObject,
  shouldUseProvinceCode,
  stripEmptyStrings,
  trackFosphaTransaction,
  trackTVSquaredTransaction,
} from "shared/utility";
import Bugsnag from "@bugsnag/js";
import constants from "api/constants";
import { loadStripe } from "@stripe/stripe-js";
import { sendEvent } from "shared/DataLayer";
import { multiTinLookup } from "app/checkout/checkoutConstants";

const {
  importCart,
  importCartSuccess,
  importCartFailure,
  claimCart,
  claimCartSuccess,
  claimCartFailure,
  setContactDetails,
  setShippingAddress,
  setShippingMethod,
  setPaymentMethod,
  setHasCardDetails,
  setAcceptsMarketing,
  fetchShippingAddresses,
  fetchShippingAddressesSuccess,
  fetchShippingAddressesFailure,
  fetchShippingMethods,
  fetchShippingMethodsSuccess,
  fetchShippingMethodsFailure,
  fetchPaymentMethods,
  fetchPaymentMethodsSuccess,
  fetchPaymentMethodsFailure,
  createPaymentMethod,
  createPaymentMethodSuccess,
  createPaymentMethodFailure,
  applyShippingAddress,
  applyShippingAddressSuccess,
  applyShippingAddressFailure,
  applyShippingMethod,
  applyShippingMethodSuccess,
  applyShippingMethodFailure,
  applyDiscountCode,
  applyDiscountCodeSuccess,
  applyDiscountCodeFailure,
  removeDiscountCode,
  removeDiscountCodeSuccess,
  removeDiscountCodeFailure,
  removeCartProduct,
  removeCartProductSuccess,
  removeCartProductFailure,
  restoreCartProduct,
  restoreCartProductSuccess,
  restoreCartProductFailure,
  updateCartProduct,
  updateCartProductSuccess,
  updateCartProductFailure,
  checkout,
  checkoutSuccess,
  checkoutFailure,
  fetchConfirmedCheckout,
  fetchConfirmedCheckoutSuccess,
  fetchConfirmedCheckoutFailure,
  clearCheckoutError,
  setIrclickid,
  storeDiscountCode,
} = createActions(
  IMPORT_CART,
  IMPORT_CART_SUCCESS,
  IMPORT_CART_FAILURE,
  CLAIM_CART,
  CLAIM_CART_SUCCESS,
  CLAIM_CART_FAILURE,
  SET_CONTACT_DETAILS,
  SET_SHIPPING_ADDRESS,
  SET_SHIPPING_METHOD,
  SET_PAYMENT_METHOD,
  SET_HAS_CARD_DETAILS,
  SET_ACCEPTS_MARKETING,
  FETCH_SHIPPING_ADDRESSES,
  FETCH_SHIPPING_ADDRESSES_SUCCESS,
  FETCH_SHIPPING_ADDRESSES_FAILURE,
  FETCH_SHIPPING_METHODS,
  FETCH_SHIPPING_METHODS_SUCCESS,
  FETCH_SHIPPING_METHODS_FAILURE,
  FETCH_PAYMENT_METHODS,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILURE,
  CREATE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILURE,
  APPLY_SHIPPING_ADDRESS,
  APPLY_SHIPPING_ADDRESS_SUCCESS,
  APPLY_SHIPPING_ADDRESS_FAILURE,
  APPLY_SHIPPING_METHOD,
  APPLY_SHIPPING_METHOD_SUCCESS,
  APPLY_SHIPPING_METHOD_FAILURE,
  APPLY_DISCOUNT_CODE,
  APPLY_DISCOUNT_CODE_SUCCESS,
  APPLY_DISCOUNT_CODE_FAILURE,
  REMOVE_DISCOUNT_CODE,
  REMOVE_DISCOUNT_CODE_SUCCESS,
  REMOVE_DISCOUNT_CODE_FAILURE,
  REMOVE_CART_PRODUCT,
  REMOVE_CART_PRODUCT_SUCCESS,
  REMOVE_CART_PRODUCT_FAILURE,
  RESTORE_CART_PRODUCT,
  RESTORE_CART_PRODUCT_SUCCESS,
  RESTORE_CART_PRODUCT_FAILURE,
  UPDATE_CART_PRODUCT,
  UPDATE_CART_PRODUCT_FAILURE,
  UPDATE_CART_PRODUCT_SUCCESS,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  FETCH_CONFIRMED_CHECKOUT,
  FETCH_CONFIRMED_CHECKOUT_SUCCESS,
  FETCH_CONFIRMED_CHECKOUT_FAILURE,
  CLEAR_CHECKOUT_ERROR,
  SET_IRCLICKID,
  STORE_DISCOUNT_CODE
);

const loadCart = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(importCart(token));

    api.post("/shop/cart", { token }).then(
      ({ data }) => {
        dispatch(importCartSuccess(data));
        sendEvent("checkoutInit");
        resolve(data);
      },
      (err) => {
        dispatch(importCartFailure(err));
        reject(err);
      }
    );
  });

const claimShoppingCart = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(claimCart(token));

    api.post(`/shop/cart/${encodeURIComponent(token)}/claim`).then(
      ({ data }) => {
        dispatch(claimCartSuccess(data));
        resolve(data);
      },
      (err) => {
        dispatch(claimCartFailure(err));
        reject(err);
      }
    );
  });

const loadShippingMethods = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { cart, shippingAddress } = getState().checkout;
    const { availableCountryLookup } = getState().config;
    // TODO: Work with authed carts
    if (!(cart && shippingAddress) || typeof shippingAddress === "number") {
      return reject();
    }

    dispatch(fetchShippingMethods());

    const params = { country_code: shippingAddress.country_code };

    if (
      shippingAddress.province_code &&
      shouldUseProvinceCode(
        availableCountryLookup,
        shippingAddress.country_code
      )
    ) {
      params.province_code = shippingAddress.province_code;
    }

    api
      .get(`/shop/cart/${encodeURIComponent(cart.token)}/shipping-rates`, {
        params,
      })
      .then(
        ({ data }) => {
          sendEvent("checkoutPayment");
          dispatch(fetchShippingMethodsSuccess(data));
          resolve(data);
        },
        (err) => {
          reject(err);
          dispatch(fetchShippingMethodsFailure(err));
        }
      );
  });

const loadShippingAddresses = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchShippingAddresses());

    api.get("/account/addresses").then(
      ({ data }) => {
        dispatch(fetchShippingAddressesSuccess(data));
        resolve(data);
      },
      (err) => {
        dispatch(fetchShippingAddressesFailure(err));
        reject(err);
      }
    );
  });

const loadPaymentMethods = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchPaymentMethods());

    api.get("/account/payment-methods").then(
      ({ data }) => {
        dispatch(fetchPaymentMethodsSuccess(data));
        resolve(data);
      },
      (err) => {
        dispatch(fetchPaymentMethodsFailure(err));
        reject(err);
      }
    );
  });

const addPaymentMethod = (type, token, stripe) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(createPaymentMethod({ type }));
    api.post("/account/payment-methods", { type, [type]: { token } }).then(
      ({ data }) => {
        dispatch(createPaymentMethodSuccess(data));
        resolve(data);
      },
      (err) => {
        if (requiresAction(err)) {
          dispatch(handleRequiresAction(err.response, stripe)).then(
            resolve,
            reject
          );
        } else {
          dispatch(createPaymentMethodFailure(err));
          reject(err);
        }
      }
    );
  });

const confirmPaymentMethod = (paymentMethodId) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.post(`/account/payment-methods/${paymentMethodId}/confirm`).then(
      ({ data }) => {
        dispatch(createPaymentMethodSuccess(data));
        resolve(data);
      },
      (err) => {
        dispatch(createPaymentMethodFailure(err));
        reject(err);
      }
    );
  });

const updateCartShippingAddress = (address) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(applyShippingAddress(address));

    const payload =
      typeof address === "number" || address === null
        ? { address_id: address }
        : address;

    api.put("/shop/cart/shipping-address", payload).then(
      ({ data }) => {
        dispatch(applyShippingAddressSuccess(data));
        resolve(data);
      },
      (err) => {
        dispatch(applyShippingAddressFailure(err));
        reject(err);
      }
    );
  });

const updateCartShippingMethod = (shippingMethodID) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(applyShippingMethod(shippingMethodID));

    api
      .put("/shop/cart/shipping-rate", { shipping_rate_id: shippingMethodID })
      .then(
        ({ data }) => {
          dispatch(applyShippingMethodSuccess(data));
          resolve(data);
        },
        (err) => {
          dispatch(applyShippingMethodFailure(err));
          reject(err);
        }
      );
  });

const updateAnonymousCartShippingDetails =
  (shippingAddress, shippingMethodId) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(applyShippingMethod(shippingMethodId));

      const { token } = getState().checkout.cart;

      api
        .put(`/shop/cart/${encodeURIComponent(token)}/shipping-details`, {
          shipping_rate_id: shippingMethodId,
          ...shippingAddress,
        })
        .then(
          ({ data }) => {
            dispatch(applyShippingMethodSuccess(data));
            resolve(data);
          },
          (err) => reject(err)
        );
    });

const addDiscountCode = (code) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(applyDiscountCode(code));

    const { token } = getState().checkout.cart;

    api.put(`/shop/cart/${encodeURIComponent(token)}/discount`, { code }).then(
      ({ data }) => {
        dispatch(applyDiscountCodeSuccess(data));
        sendEvent("addDiscount", code);
        resolve(data);
      },
      (err) => {
        dispatch(applyDiscountCodeFailure(err));
        reject(err);
      }
    );
  });

const clearDiscountCode = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(removeDiscountCode());

    const { token } = getState().checkout.cart;

    api.delete(`/shop/cart/${encodeURIComponent(token)}/discount`).then(
      ({ data }) => {
        dispatch(removeDiscountCodeSuccess(data));
        sendEvent("removeDiscount");
        resolve(data);
      },
      (err) => {
        dispatch(removeDiscountCodeFailure(err));
        reject(err);
      }
    );
  });

const authenticatedCheckout = (paymentMethodId, stripe) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(checkout());
    api
      .post(
        "/shop/cart/checkout",
        { payment_method_id: paymentMethodId },
        { timeout: 30000 }
      )
      .then(
        ({ data }) => {
          dispatch(successfulCheckout(data));
          dispatch(conversionEventsForCompletedCheckout(data));
          sendEvent("checkoutConversion");
          resolve(data);
        },
        (err) => {
          if (requiresAction(err)) {
            dispatch(handleRequiresAction(err.response, stripe));
          } else {
            dispatch(checkoutFailure(err));
            reject(err);
          }
        }
      );
  });

const requiresAction = (err) =>
  ["checkout.requires_action", "payment_method.requires_action"].indexOf(
    _.get(err, "response.data.error.code")
  ) !== -1;

const handleRequiresAction =
  ({ data }, stripe) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const isCheckout = Boolean(_.get(data, "error.checkout"));

      // Setup Intent or Payment Intent Secret
      const intentSecret = _.get(
        data,
        isCheckout
          ? "error.checkout.authorization"
          : "error.payment_method.authorization"
      );

      // Checkout Token or Payment Method ID
      const entityId = _.get(
        data,
        isCheckout ? "error.checkout.token" : "error.payment_method.id"
      );

      const publishableKey = _.get(
        data,
        isCheckout
          ? "error.checkout.publishable_key"
          : "error.payment_method.publishable_key"
      );

      // Create a promise that returns current stripe if the key is the same
      // or a newly loaded stripe if it's different to stored
      const stripePromise =
        !publishableKey || publishableKey === constants.stripeKey
          ? Promise.resolve(stripe)
          : loadStripe(publishableKey);

      stripePromise.then((contextualStripe) => {
        // Use the corrent stripe for the stripe actions within this scope
        const stripeHandleFunction =
          data.error.code === "payment_method.requires_action"
            ? contextualStripe.confirmCardSetup
            : contextualStripe.handleCardAction;

        stripeHandleFunction(intentSecret).then(({ error }) => {
          if (error) {
            return isCheckout
              ? dispatch(checkoutFailure(error))
              : dispatch(createPaymentMethodFailure(error));
          } else {
            if (isCheckout) {
              // Checkout Token or Payment Method ID
              return dispatch(confirmCheckout(entityId, stripe)).then(
                resolve,
                reject
              );
            } else {
              return dispatch(confirmPaymentMethod(entityId)).then(
                resolve,
                reject
              );
            }
          }
        });
      });
    });

const confirmCheckout = (checkoutToken, stripe) => (dispatch) =>
  new Promise((resolve, reject) => {
    api.post(`/shop/checkout/${checkoutToken}/confirm`).then(
      ({ data }) => {
        dispatch(successfulCheckout(data));
        dispatch(conversionEventsForCompletedCheckout(data));
        sendEvent("checkoutConversion");
        resolve(data);
      },
      (err) => {
        if (requiresAction(err)) {
          return dispatch(handleRequiresAction(err.response, stripe)).then(
            resolve,
            reject
          );
        } else {
          dispatch(checkoutFailure(err));
          reject(err);
        }
      }
    );
  });

const anonymousCheckout =
  ({ paymentMethod, stateOverride }, stripe) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const stateObj = getState();

      // Get up-to-date checkout details from the store
      const {
        acceptsMarketing,
        cart: { token: cartToken },
        contactDetails,
        shippingAddress,
        shippingMethod,
      } = stateOverride || stateObj.checkout;

      // This should be the impossible state (but it's obviously possible!)...
      if (!shippingMethod) {
        Bugsnag.notify(new Error("Falsey Shipping Method"), (event) => {
          event.addMetadata("reduxState", stateObj);
        });
      }
      dispatch(checkout());
      api
        .post(
          `/shop/cart/${encodeURIComponent(cartToken)}/checkout`,
          {
            ...contactDetails,
            ...(shippingAddress
              ? {
                  shipping_address: stripEmptyStrings(shippingAddress),
                  shipping_rate_id: parseInt(shippingMethod),
                }
              : {}),
            payment_token: paymentMethod,
            accepts_marketing: acceptsMarketing,
          },
          { timeout: 30000 }
        )
        .then(
          ({ data }) => {
            dispatch(successfulCheckout(data));
            dispatch(conversionEventsForCompletedCheckout(data));
            sendEvent("checkoutConversion");
            resolve(data);
          },
          (err) => {
            if (requiresAction(err)) {
              return dispatch(handleRequiresAction(err.response, stripe));
            } else {
              dispatch(checkoutFailure(err));
              reject(err);
            }
          }
        );
    });

const loadConfirmedCheckout = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchConfirmedCheckout(token));

    api.get(`/shop/checkout/${token}`).then(
      ({ data }) => {
        dispatch(fetchConfirmedCheckoutSuccess(data));
        resolve(data);
      },
      (err) => {
        dispatch(fetchConfirmedCheckoutFailure(err));
        reject(err);
      }
    );
  });

const conversionEventsForCompletedCheckout =
  (checkoutObject) => (dispatch, getState) => {
    const { cart, irclickid } = getState().checkout;

    try {
      if (checkoutObject.subscription) {
        /* Will Run In GTM
        if (window.fbq) {
          window.fbq("track", "Subscribe", {
            subscription_id: String(checkoutObject.subscription.id),
            value: cart.payment_due.amount,
            currency: cart.payment_due.currency,
          });
        } */

        if (window.ttq) {
          console.log("TTQ");

          window.ttq.track("Subscribe", {
            content_id: String(checkoutObject.subscription.id),
            content_type: "product",
            value: cart.payment_due.amount,
            currency: cart.payment_due.currency,
          });
        }
      } else {
        /* Will Run In GTM
        if (window.fbq) {
          window.fbq("track", "Purchase", {
            value: cart.payment_due.amount,
            currency: cart.payment_due.currency,
          });
        }
        */

        if (window.ttq) {
          console.log("TTQ");

          window.ttq.track("PlaceAnOrder", {
            content_type: "product",
            content_id: String(
              _.get(checkoutObject, "order.id") ||
                _.get(checkoutObject, "order.order_number") ||
                "order"
            ),
            value: cart.payment_due.amount,
            currency: cart.payment_due.currency,
          });
        }
      }

      generateImpactTrackingObject(cart, checkoutObject, irclickid);
      trackFosphaTransaction(cart, checkoutObject);
      trackTVSquaredTransaction(checkoutObject);
    } catch (error) {
      console.error("Conversion Event Error: ", error);
    }
  };

export const initialiseEnquireLabs = (completedCheckout, user) => () => {
  const API_KEY =
    "5tjRRqDjFFZ8usV6wf3cQB7hBsGmiYYAc3WB2gI0jc83Ez-VWxJz1D4LAgNV2YCy";
  const TARGET_ELEMENT = document.getElementById("enquire_labs_target");

  const customer = {
    id: user.email,
    email: user.email,
    order_count: 1,
  };

  const order = completedCheckout.order || completedCheckout.subscription;

  const total = parseFloat(
    order.total?.amount || _.get(order, "last_dispatch.order.total.amount")
  );

  const transaction = {
    // Some orders don't have shipping addresses
    country_code: order.country_code || order.shipping_address?.country_code,
    // Both have created_at
    created_at: order.created_at,
    // Both have currency
    currency_code: order.currency,
    id: String(_.get(order, "last_dispatch.order.id") || order.id),

    // Both have city, but not all will have shipping addresses
    locality: order.shipping_address?.city,

    number: String(
      order.order_number ||
        _.get(order, "last_dispatch.order.order_number") ||
        order.id
    ),
    platform: "shopify",
    postcode: order.shipping_address?.zip,
    source: "website",
    total: total,
    total_usd: total,
  };

  const EL = window.EnquireLabs(API_KEY, TARGET_ELEMENT, {
    transaction: transaction,
    customer: customer,
    config: {
      integrations: {
        ga: false,
        gtm: true,
        rockerbox: false,
      },
    },
  });
  EL.nextQuestion();
};

const removeLineItem = (lineItemId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(removeCartProduct(lineItemId));
    const { token } = getState().checkout.cart;

    api
      .delete(`/shop/cart/${encodeURIComponent(token)}/products/${lineItemId}`)
      .then(
        ({ data }) => {
          dispatch(removeCartProductSuccess(data));
          resolve(data);
        },
        (err) => {
          dispatch(removeCartProductFailure(err));
          reject(err);
        }
      );
  });

const restoreLineItem = (lineItemId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(restoreCartProduct(lineItemId));
    const { token } = getState().checkout.cart;

    api
      .post(
        `/shop/cart/${encodeURIComponent(token)}/products/${lineItemId}/restore`
      )
      .then(
        ({ data }) => {
          dispatch(restoreCartProductSuccess(data));
          resolve(data);
        },
        (err) => {
          dispatch(restoreCartProductFailure(err));
          reject(err);
        }
      );
  });

const updateLineItem =
  (lineItemId, productId, variantId) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(updateCartProduct({ lineItemId, productId, variantId }));

      const { token } = getState().checkout.cart;

      api
        .put(`/shop/cart/${encodeURIComponent(token)}/products/${lineItemId}`, {
          product_id: productId,
          variant_id: variantId,
        })
        .then(
          ({ data }) => {
            dispatch(updateCartProductSuccess(data));
            resolve(data);
          },
          (err) => {
            dispatch(updateCartProductFailure(err));
            reject(err);
          }
        );
    });

const applyTinPreference = (cart, tinPreference) => (dispatch) => {
  // Get the parent line item that contains a tin
  const currentParent = cart.products.find(
    (product) =>
      product.children &&
      Boolean(product.children.find(({ type }) => type === "rule"))
  );

  if (!currentParent) {
    return;
  }

  // Grab the tin child from the parent's children
  const tinProduct = currentParent.children.find(({ type }) => type === "rule");

  if (!tinProduct) {
    return;
  }

  // If preference is no tin, remove it
  if (tinPreference === "none" && !tinProduct.removed) {
    dispatch(removeLineItem(tinProduct.id));
    return;
  }

  // Get some more info on what tin we want
  const targetTin = multiTinLookup[tinPreference];
  if (
    !targetTin ||
    targetTin.variantTitles.indexOf(tinProduct.variant?.title) !== -1
  ) {
    return;
  }

  // Find the alternative that matches the spec of the tin we want
  const targetLineItem = tinProduct.alternatives?.find(
    (alt) => targetTin.variantTitles.indexOf(alt.variant_title) !== -1
  );

  if (!targetLineItem) {
    return;
  }

  dispatch(
    updateLineItem(
      tinProduct.id,
      targetLineItem.product_id,
      targetLineItem.variant_id
    )
  );
};

// GRI-689
// Turned successful checkout into a THUNK so we can run post-checkout effects
// off the back of a successful checkout regardless of how we got here.
const successfulCheckout = (confirmedCheckout) => (dispatch, getState) => {
  // GRI-689 if we've got upgrade=1 in the url and have compatible products,
  // upgrade the subscription!
  // Just make sure we don't crash the checkout
  try {
    const checkoutToken = confirmedCheckout.token;
    const upgradeRequested = window.location.search.includes("upgrade=1");
    const hasEligibleProducts = confirmedCheckout.subscription.products.find(
      (product) => product.variant.sku.startsWith("PODLB")
    );

    if (checkoutToken && upgradeRequested && hasEligibleProducts) {
      // Fire & forget
      api.post(`/shop/checkout/${checkoutToken}/upgrade-pods`).then(
        () => {},
        (err) => {
          Bugsnag.notify(err);
        }
      );
    }
  } catch (_) {}

  // Do this after because it changes the url
  dispatch(checkoutSuccess(confirmedCheckout));
};

export {
  loadCart,
  claimShoppingCart,
  loadShippingMethods,
  setContactDetails,
  setShippingAddress,
  setShippingMethod,
  setPaymentMethod,
  setHasCardDetails,
  setAcceptsMarketing,
  loadPaymentMethods,
  addPaymentMethod,
  loadShippingAddresses,
  updateCartShippingAddress,
  updateCartShippingMethod,
  updateAnonymousCartShippingDetails,
  addDiscountCode,
  clearDiscountCode,
  anonymousCheckout,
  authenticatedCheckout,
  loadConfirmedCheckout,
  clearCheckoutError,
  checkout,
  checkoutFailure,
  setIrclickid,
  restoreLineItem,
  removeLineItem,
  storeDiscountCode,
  updateLineItem,
  applyTinPreference,
  successfulCheckout,
};
