// Primary Libs
import React from "react";

// Local Components
import AppRouter from "./AppRouter";
import PageContainer from "./PageContainer";

const Layout = () => (
  <PageContainer>
    <AppRouter />
  </PageContainer>
);

export default Layout;
