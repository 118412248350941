const constants = {
  baseURL: "https://web-api.grind.co.uk/v1",
  stripeKey: "pk_live_YmCXA1JkndU5HIF29bVl3WDd",
  googleKey: "AIzaSyCYKLLvtfIK4-5WEyhdrJdt0QPhmBN-btg",
  shopifyURL: "https://grind.co.uk",
  testPageEnabled: false,
  tagManagerId: "GTM-MGRCJHV",
  bugsnagReleaseStage: "live",
};

export default constants;
