import React from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import CartLineItem from "./CartLineItem";
import DashedSeparator from "shared/components/DashedSeparator";
import DiscountCodeField from "./DiscountCodeField";
import PriceChangingLineItems from "./PriceChangingLineItems";
import SummaryLineItem from "./SummaryLineItem";
import RecurringCost from "./RecurringCost";

import styles from "./SubscriptionSummary.module.scss";

import constants from "api/constants";
import TinSelectBlock from "./TinSelectBlock";

const SubscriptionSummary = ({ className }) => {
  const { cart } = useSelector((s) => s.checkout);

  const subscriptionProducts = cart.products.filter(product => product.type === "subscription")

  const containsNonMatchaSub = Boolean(subscriptionProducts.find(({ product }) => !product.title.toUpperCase().includes('MATCHA')))

  // This will fetch the child product representing the starter pack.
  // const starterPackProduct = cart.products.find(
  //   (product) => product.children?.[0]?.type === "rule"
  // )?.children[0];

  const returnUrl = constants.shopifyURL;

  return (
    <div
      className={cx(
        "d-flex flex-column subscriptionSummaryContainer",
        styles.container,
        className
      )}
    >
      {/* Starter Block */}
      {containsNonMatchaSub && (
        <TinSelectBlock className="mb-4" cart={cart} />
        //<StarterPackBlock className="mb-4" product={starterPackProduct} />
      )}
      {/* List Line Items */}
      {cart.products.map((lineItem, idx) => {
        // The free matcha tin that comes with the bundle should be hidden so as not to confuse the customer.
        if (lineItem.type === "onetime" && lineItem.variant.sku === "EMPTYTIN-MATCHA") return null

        return (
          <CartLineItem
            className={cx(idx > 0 && "mt-4")}
            key={String(lineItem.id)}
            lineItem={lineItem}
          />
        )
      })}

      <DashedSeparator className="my-2" />
      {/* Edit Cart Button */}
      <a
        className="btn gkit-btn gkit-secondary my-2"
        href={`${returnUrl}#wizard?review=1`}
      >
        <p className="m-0 gkit-p-17 gkit-bold">Edit Cart</p>
      </a>
      <DashedSeparator className="my-2" />
      {/* Discount Code */}
      <DiscountCodeField />
      {/* Cost Breakdown */}
      <PriceChangingLineItems />
      <DashedSeparator className="my-2" />
      {/*
        This may have an issue for anonymous carts with paid shipping methods..
      */}
      <SummaryLineItem
        className="mt-2"
        isLarge={true}
        label="Total"
        value={cart.payment_due.formatted}
      />
      {subscriptionProducts.length && (
        <>
          <DashedSeparator className="my-2" />

          <RecurringCost
            className="mt-2"
            label="Recurring subtotal"
            cart={cart}
            interval={cart.subscription.interval}
            terms="Excludes gift subscriptions and any discounts - skip, pause, or cancel anytime."
          />
        </>
      )}
    </div>
  );
};

export default SubscriptionSummary;
