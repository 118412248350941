import Layout from "./Layout";

/**
 * This is the layout of the page. As this is simple there is only content,
 * however in a more complex application you'd have a Header, Footer, Sidebar
 * etc on this level, as only the actual page content would need to change
 * based on routing.
 */
// const Layout = () => {
//   const location = useLocation();
//   const handleViewPort = () => {
//     const vh = window.innerHeight * 0.01;
//     document.documentElement.style.setProperty("--vh", `${vh}px`);
//   };
//   useEffect(() => {
//     handleViewPort();
//     window.addEventListener("resize", handleViewPort);
//     return () => {
//       window.removeEventListener("resize", handleViewPort);
//     };
//   });

//   return (
//     <>
//       <Header />
//       <div
//         className={cx(
//           "main-body",
//           location.pathname === "/confirm" ? "remove-extra" : null
//         )}
//       >
//         <AppRouter />
//       </div>
//     </>
//   );
// };

export default Layout;
