import { useEffect } from "react";

import { paypalCheckout, client as braintreeClient } from "braintree-web";
import { useSelector } from "react-redux";

const PayPalLoader = ({ onCheckoutInstance }) => {
  const { paymentProviders } = useSelector((s) => s.config);
  const paypalToken =
    paymentProviders && paymentProviders.paypal
      ? paymentProviders.paypal.token
      : null;

  // Generate PayPal Instance
  useEffect(() => {
    if (!paypalToken) {
      return;
    }

    braintreeClient
      .create({
        authorization: paypalToken,
      })
      .then((client) =>
        paypalCheckout.create({
          client,
        })
      )
      .then((paypalCheckoutInstance) => {
        paypalCheckoutInstance.loadPayPalSDK({ vault: true }).then(() => {
          onCheckoutInstance(paypalCheckoutInstance);
        });
      });
  }, [onCheckoutInstance, paypalToken]);

  return null;
};

export default PayPalLoader;
