import api from "./index";

const apiSubscription = (store) => {
  let hasAccessToken = false;

  const updateAPI = ({ accessToken }) => {
    let needsUpdate = false;
    const storeHasAccessToken = Boolean(accessToken);
    if (hasAccessToken !== storeHasAccessToken) {
      hasAccessToken = storeHasAccessToken;
      needsUpdate = true;
    }

    if (!needsUpdate) return;

    if (hasAccessToken) {
      api.defaults.headers = {
        Authorization: `Bearer ${accessToken}`
      };
    } else {
      api.defaults.headers = {};
    }
  };

  store.subscribe(() => {
    const { auth } = store.getState();
    updateAPI(auth);
  });
};

export default apiSubscription;
