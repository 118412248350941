import api from "api";
import { createActions } from "redux-actions";
import {
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_SUCCESS,
  SET_PAYMENT_PROVIDERS,
} from "redux/constants";

export const {
  setPaymentProviders,
  fetchCountries,
  fetchCountriesSuccess,
  fetchCountriesFailure,
} = createActions(
  SET_PAYMENT_PROVIDERS,
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE
);

export const loadCountries = () => dispatch => {
  dispatch(fetchCountries());
  api.get("/shop/countries").then(
    ({ data }) => {
      dispatch(fetchCountriesSuccess(data));
    },
    err => {
      dispatch(fetchCountriesFailure(err));
    }
  );
};
