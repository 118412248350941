import React from "react";

import SubscriptionSummary from "./SubscriptionSummary";

import styles from "./DesktopSubscriptionSummary.module.scss";

const DesktopSubscriptionSummary = ({ isSubscription }) => (
  <div className={styles.container}>
    <p className="gkit-p-20 gkit-bold d-none d-md-block m-0">Your Order</p>
    <SubscriptionSummary />
  </div>
);

export default DesktopSubscriptionSummary;
