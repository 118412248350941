import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import logger from "redux-logger";
import thunk from "redux-thunk";

import reducer from "./reducer";
import subscription from "../api/subscription";

const { NODE_ENV } = process.env;
const isDev = NODE_ENV === "development";

const middleware = [thunk];
let enhancer;

if (isDev) {
  middleware.push(logger);
  const composeEnhancers = composeWithDevTools({
    name: `Grind Checkout`,
  });
  enhancer = composeEnhancers(applyMiddleware(...middleware));
} else {
  enhancer = applyMiddleware(...middleware);
}

const store = createStore(
  reducer,
  // applyMiddleware(thunk, isDev ? logger : (s) => (n) => (a) => n(a))
  enhancer
);

subscription(store);

export default store;
