import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { initialiseEnquireLabs } from "redux/actions/checkout";
import EnquireModal from "./testPage/EnquireModal";

const completedCheckout = require("api/mock/20220108-completedCheckout.json");
const contactDetails = require("api/mock/20220108-contactDetails.json");

const TestPage = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onShowModalClick = useCallback(() => {
    setIsModalVisible(true);

    setTimeout(() => {
      dispatch(initialiseEnquireLabs(completedCheckout, contactDetails));
    }, 500);
  }, [dispatch]);

  return (
    <div>
      <button onClick={onShowModalClick}>Show Modal</button>
      <EnquireModal
        isVisible={isModalVisible}
        onDismissed={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default TestPage;

// http://localhost:3000/?token=f4ed57bcdfbdf2970c12275b667d5a39
