import React, { useMemo } from "react";

// Shorten option names like "House Blend" to "House".
const deblendedTitle = (title) => {
  const titleParts = title.split(" ");

  if (titleParts.length === 2 && titleParts[1] === "Blend") {
    return titleParts[0];
  }

  return title;
};

const iconSources = {
  pods: {
    House: require("assets/images/iconBlendHousePod.svg").default,
    Dark: require("assets/images/iconBlendDarkPod.svg").default,
    Light: require("assets/images/iconBlendLightPod.svg").default,
    Decaf: require("assets/images/iconBlendDecafPod.svg").default,
  },
  beans: {
    House: require("assets/images/iconBlendHouseBeans.svg").default,
    Dark: require("assets/images/iconBlendDarkBeans.svg").default,
    Light: require("assets/images/iconBlendLightBeans.svg").default,
    Decaf: require("assets/images/iconBlendDecafBeans.svg").default,
  },
};

const LineItemVariant = ({ variant }) => {
  // Remove "Blend" suffixes from all the options
  const initialOptions = useMemo(
    () => (variant.options || []).map(deblendedTitle),
    [variant]
  );
  const uniqueOptions = useMemo(
    () => new Set(initialOptions),
    [initialOptions]
  );

  const variantTitle = useMemo(() => {
    // Majority of cases; We've got duplicate variants due to a package with
    // multiple of the same flavour. (Pods Letterbox = 3x 10x Pods.)
    // In these cases, instead of showing "House, House, House" we show just
    // "House"
    if (initialOptions.length > 1 && uniqueOptions.size === 1) {
      return deblendedTitle(initialOptions[0]);
    }

    // Comma-separate options.
    return initialOptions.join(", ");
  }, [initialOptions, uniqueOptions]);

  if (variant.title === "Default Title") {
    return null;
  }

  const icons = [];

  // Use the pods icon for pod products, beans for everything else
  // We don't have to worry about non-coffee products as it still uses the
  // blend name to get the icons.
  const iconSource = variant.sku.startsWith("POD")
    ? iconSources.pods
    : iconSources.beans;

  // There's only one and we have an icon for it
  if (uniqueOptions.size === 1 && iconSource[variantTitle]) {
    icons.push({
      alt: variantTitle,
      src: iconSource[variantTitle],
    });
  } else {
    // Create a stack / array of icons for each option value.
    for (const optionTitle of initialOptions) {
      if (iconSource[optionTitle]) {
        icons.push({
          alt: optionTitle,
          src: iconSource[optionTitle],
        });
      }
    }
  }

  return (
    <div className="mt-1 d-flex flex-row align-items-center justify-content-start">
      {icons.length > 0 && (
        <div
          className="me-2"
          style={{
            position: "relative",
            height: "20px",
            width: `${10 + 10 * icons.length}px`,
          }}
        >
          {icons.map((icon, idx) => (
            <img
              alt={icon.alt}
              key={idx}
              src={icon.src}
              style={{
                width: "20px",
                height: "20px",
                position: "absolute",
                left: `${10 * idx}px`,
                top: "0px",
                zIndex: idx + 1,
              }}
            />
          ))}
        </div>
      )}
      <p className="mb-0 gkit-p-15 gkit-mobile-13">{variantTitle}</p>
    </div>
  );
};

export default LineItemVariant;
