import React, { useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";

import SubscriptionSummary from "./SubscriptionSummary";
import { shortestPrice } from "shared/utility";

import styles from "./MobileSubscriptionSummary.module.scss";

const MobileSubscriptionSummary = () => {
  const { cart } = useSelector(s => s.checkout);
  const [isExpanded, setIsExpanded] = useState(false);

  const hasDiscount =
    parseFloat(cart.credit_applied.amount) +
      parseFloat(cart.discount_amount.amount) >
    0;

  const orderLabel = cart.subscription ? "Subscription" : "Order";

  return (
    <div className={cx(styles.container, "d-md-none")}>
      <div
        className={styles.headerContainer}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <p className="gkit-p-15 gkit-bold m-0">{`${orderLabel} summary`}</p>
        <div>
          <p className={cx("gkit-p-15 m-0", styles.price)}>
            {hasDiscount && (
              <span className={styles.strike}>
                {shortestPrice(cart.subtotal_price.formatted)}
              </span>
            )}
            {shortestPrice(cart.payment_due.formatted)}
            <img
              alt={isExpanded ? "Contract" : "Expand"}
              src={require("../../../assets/img/chevron-down.svg").default}
              className={cx(styles.chevron, isExpanded && styles.expanded)}
            />
          </p>
        </div>
      </div>
      {isExpanded && (
        <SubscriptionSummary className={styles.contentContainer} />
      )}
    </div>
  );
};

export default MobileSubscriptionSummary;
