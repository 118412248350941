import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { SWRConfig } from "swr";

import store from "./redux/store";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ScrollToTop from "./shared/ScrollToTop";
import smoothscroll from "smoothscroll-polyfill";
import TagManager from "react-gtm-module";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import "./assets/styles/index.scss";
import constants from "./api/constants";
import api from "api";
import { setPaymentProviders } from "redux/actions/config";

import { nonStandardErrorRequestUrls, standardErrorRequestURLs } from "errors";

TagManager.initialize({ gtmId: constants.tagManagerId });

const stripePromise = new Promise((resolve, reject) => {
  api.get("payment-providers").then(({ data }) => {
    const paymentProviders = {};
    for (const provider of data) {
      paymentProviders[provider.type] = {
        name: provider.name,
        token: provider.token,
      };
      // If it's stripe, initialise stripe
      if (provider.type === "stripe") {
        loadStripe(constants.stripeKey).then(resolve, reject);
      }
    }

    store.dispatch(setPaymentProviders(paymentProviders));
  }, reject);
});

smoothscroll.polyfill();

Bugsnag.start({
  apiKey: "ee4a3b171828bdc4abab7a0b59fd2cbc",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["local", "development", "production"],
  releaseStage: constants.bugsnagReleaseStage || process.env.NODE_ENV,
  onError: (event) => {
    const err = event.originalError;

    // Check if it's a HTTP Request
    const apiMethod = err?.config?.method;
    const apiUrl = err?.config.url;

    if (apiUrl) {
      const responseStatus = err?.response?.status;
      const responseErrorCode = err?.response?.data?.error?.code;

      const pathObject =
        standardErrorRequestURLs[apiUrl] ||
        nonStandardErrorRequestUrls.find(({ url }) => url.test(apiUrl))?.name;

      if (pathObject) {
        if (
          pathObject.ignore === true ||
          pathObject.ignore?.indexOf?.(responseStatus) !== -1 ||
          pathObject.ignoreCodes?.indexOf?.(responseErrorCode) !== -1
        ) {
          return false;
        }

        const apiCallSignature = [
          apiMethod?.toUpperCase?.(),
          pathObject.name || apiUrl,
          responseStatus,
        ]
          .filter(Boolean)
          .join(" ");

        event.context = apiCallSignature;
        event.groupingHash = apiCallSignature;
      }

      if (responseStatus) {
        event.errors[0].errorClass = "HTTP Error";
      } else {
        event.errors[0].errorClass = "Network Error";
      }

      let requestData = null;
      try {
        requestData = JSON.parse(err?.config?.data);
      } catch (e) {}

      event.addMetadata("requestInfo", {
        status: err?.response?.status,
        requestData: requestData,
        headers: err?.config?.headers,
        method: err?.config?.method,
        url: err?.config?.url,
        responseData: err?.response?.data,
        responseErrorCode: responseErrorCode,
        responseErrorMessage: err?.response?.data?.error?.message,
      });
    }
  },
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <Provider store={store}>
    <SWRConfig
      value={{
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        dedupingInterval: 60000,
      }}
    >
      <Router>
        <Helmet>
          <title>Grind | Checkout</title>
          <meta property="og:title" content="GRIND | Checkout" />
          <meta property="og:site_name" content="GRIND | Checkout" />
        </Helmet>
        <ScrollToTop>
          <ErrorBoundary>
            <Elements
              options={{
                fonts: [
                  {
                    cssSrc: "https://static.grind.co.uk/gkit/fonts.css",
                  },
                ],
              }}
              stripe={stripePromise}
            >
              <App />
            </Elements>
          </ErrorBoundary>
        </ScrollToTop>
      </Router>
    </SWRConfig>
  </Provider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
