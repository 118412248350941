import { paymentMethodProviders } from "app/checkout/checkoutConstants";
import React from "react";
import { CreditCardImages } from "../../../../shared/utility";

const PaymentRadioContentComponent = ({ entity: paymentMethod }) => {
  if (paymentMethod.type === paymentMethodProviders.STRIPE) {
    const { identifier, card } = paymentMethod;
    return (
      <div className="d-flex align-items-center">
        <img
          alt={identifier}
          className="me-2"
          src={CreditCardImages.Regular[card.brand]}
          style={{ width: "40px" }}
        />
        <div>
          <p className="gkit-p-15 gkit-bold my-0">{`•••• ${card.last4}`}</p>
          <p className="gkit-p-13 gkit-light my-0">{`Expires ${card.exp_month}/${card.exp_year}`}</p>
        </div>
      </div>
    );
  }

  if (paymentMethod.type === paymentMethodProviders.PAYPAL) {
    const { identifier } = paymentMethod;

    return (
      <div className="d-flex align-items-center">
        <img
          alt={identifier}
          className="me-2"
          src={CreditCardImages.Regular.paypal}
          style={{ width: "40px" }}
        />
        <p className="gkit-p-15 gkit-bold my-0">{identifier}</p>
      </div>
    );
  }

  return null;
};

export default PaymentRadioContentComponent;
