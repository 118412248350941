import { createActions } from "redux-actions";
import _ from "lodash";

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_LOGIN_AVAILABLE,
  SET_REGISTER_AVAILABLE,
  SET_USER,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
} from "../constants";
import api from "../../api";

const {
  login,
  loginSuccess,
  loginFailure,
  logout,
  fetchProfile,
  fetchProfileSuccess,
  fetchProfileFailure,
  setLoginAvailable,
  setRegisterAvailable,
} = createActions(
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  LOGOUT,
  SET_LOGIN_AVAILABLE,
  SET_REGISTER_AVAILABLE,
  SET_USER
);

export const submitLogout = () => (dispatch) => {
  localStorage.removeItem("access_token");
  api.post("/auth/logout");

  dispatch(logout());
};

export const loadProfile = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchProfile());
    api.get("/account/profile").then(
      ({ data }) => {
        dispatch(fetchProfileSuccess(data));
        resolve(data);
      },
      (err) => {
        dispatch(fetchProfileFailure(err));
        reject(err);

        // We are not authenticated!
        if (_.get(err, "response.status") === 401) {
          dispatch(submitLogout());
        }
      }
    );
  });

export const checkUser = () => (dispatch) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    dispatch(authSuccess(token));
  } else {
    dispatch(submitLogout());
  }
};

export const checkEmail = (email) => (dispatch, getState) => {
  api.get("/auth/email", { params: { email } }).then(
    // TODO: Use available login methods to determine how to auth
    () => {
      dispatch(setLoginAvailable(email));
    },
    (err) => {
      if (err && err.response && err.response.status === 404) {
        dispatch(setRegisterAvailable(true));
      }
    }
  );

  const cart = getState().checkout.cart;

  if (email && cart && cart.products) {
    window._learnq = window._learnq || [];

    window._learnq.push([
      "identify",
      {
        $email: email,
      },
    ]);

    window._learnq.push([
      "track",
      "Grind Checkout Started",
      {
        cart_token: cart.token,
        checkout_url: window.location.href,
        currency: cart.currency,
        country: cart.country_code,
        items: cart.products,
        purchase_type: 'subscription',
      },
    ]);
  }
};

export const authSuccess = (token) => (dispatch) => {
  dispatch(loginSuccess(token));
  localStorage.setItem("access_token", token);

  return dispatch(loadProfile());
};

export const silentRegister = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const payload = getState().checkout.contactDetails;

    if (!payload) {
      return reject();
    }

    api.post("/auth/register", payload).then(({ data }) => {
      dispatch(authSuccess(data.token));
      resolve(data.token);
    });
  });

export const passwordLogin =
  ({ email, password }) =>
    (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(login({ email, type: "password" }));

        api.post("/auth/login", { email, password }).then(
          ({ data }) => {
            dispatch(authSuccess(data.token));
            resolve(data);
          },
          (err) => {
            dispatch(loginFailure(err));
            reject(err);
          }
        );
      });

export const codeLogin =
  ({ email, code }) =>
    (dispatch) =>
      new Promise((resolve, reject) => {
        dispatch(login({ email, type: "code" }));

        api.post("/auth/magic-login", { email, code }).then(
          ({ data }) => {
            dispatch(authSuccess(data.token));
            resolve(data);
          },
          (err) => {
            dispatch(loginFailure(err));
            reject(err);
          }
        );
      });

export { loginSuccess };
