// order
export const STORE_BASKET = "STORE_BASKET";
export const SET_DISCOUNT_CODE = "SET_DISCOUNT_CODE";
export const CONFIRM_ORDER = "CONFIRM_ORDER";

// auth
export const SET_LOGIN_AVAILABLE = "SET_LOGIN_AVAILABLE";
export const SET_REGISTER_AVAILABLE = "SET_REGISTER_AVAILABLE";
export const LOGOUT = "LOGOUT";
export const CHECK_USER = "CHECK_USER";
export const SET_USER = "SET_USER";
export const SET_LOGGED_OUT = "SET_LOGGED_OUT";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

// config
export const SET_PAYMENT_PROVIDERS = "SET_PAYMENT_PROVIDERS";

export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_FAILURE = "FETCH_COUNTRIES_FAILURE";

// tracking
export const SET_IRCLICKID = "SET_IRCLICKID";

// form
export const IMPORT_CART = "IMPORT_CART";
export const IMPORT_CART_SUCCESS = "IMPORT_CART_SUCCESS";
export const IMPORT_CART_FAILURE = "IMPORT_CART_FAILURE";

export const CLAIM_CART = "CLAIM_CART";
export const CLAIM_CART_SUCCESS = "CLAIM_CART_SUCCESS";
export const CLAIM_CART_FAILURE = "CLAIM_CART_FAILURE";

export const SET_CONTACT_DETAILS = "SET_CONTACT_DETAILS";
export const SET_SHIPPING_ADDRESS = "SET_SHIPPING_ADDRESS";
export const SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_HAS_CARD_DETAILS = "SET_HAS_CARD_DETAILS";
export const SET_APPLE_PAY_PAYMENT_REQUEST = "SET_APPLE_PAY_PAYMENT_REQUEST";
export const SET_ACCEPTS_MARKETING = "SET_ACCEPTS_MARKETING";

export const FETCH_SHIPPING_ADDRESSES = "FETCH_SHIPPING_ADDRESSES";
export const FETCH_SHIPPING_ADDRESSES_SUCCESS =
  "FETCH_SHIPPING_ADDRESSES_SUCCESS";
export const FETCH_SHIPPING_ADDRESSES_FAILURE =
  "FETCH_SHIPPING_ADDRESSES_FAILURE";

export const FETCH_SHIPPING_METHODS = "FETCH_SHIPPING_METHODS";
export const FETCH_SHIPPING_METHODS_SUCCESS = "FETCH_SHIPPING_METHODS_SUCCESS";
export const FETCH_SHIPPING_METHODS_FAILURE = "FETCH_SHIPPING_METHODS_FAILURE";

export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAILURE = "FETCH_PAYMENT_METHODS_FAILURE";

export const APPLY_SHIPPING_ADDRESS = "APPLY_SHIPPING_ADDRESS";
export const APPLY_SHIPPING_ADDRESS_SUCCESS = "APPLY_SHIPPING_ADDRESS_SUCCESS";
export const APPLY_SHIPPING_ADDRESS_FAILURE = "APPLY_SHIPPING_ADDRESS_FAILURE";

export const APPLY_SHIPPING_METHOD = "APPLY_SHIPPING_METHOD";
export const APPLY_SHIPPING_METHOD_SUCCESS = "APPLY_SHIPPING_METHOD_SUCCESS";
export const APPLY_SHIPPING_METHOD_FAILURE = "APPLY_SHIPPING_METHOD_FAILURE";

export const STORE_DISCOUNT_CODE = "STORE_DISCOUNT_CODE";
export const APPLY_DISCOUNT_CODE = "APPLY_DISCOUNT_CODE";
export const APPLY_DISCOUNT_CODE_SUCCESS = "APPLY_DISCOUNT_CODE_SUCCESS";
export const APPLY_DISCOUNT_CODE_FAILURE = "APPLY_DISCOUNT_CODE_FAILURE";

export const REMOVE_DISCOUNT_CODE = "REMOVE_DISCOUNT_CODE";
export const REMOVE_DISCOUNT_CODE_SUCCESS = "REMOVE_DISCOUNT_CODE_SUCCESS";
export const REMOVE_DISCOUNT_CODE_FAILURE = "REMOVE_DISCOUNT_CODE_FAILURE";

export const REMOVE_CART_PRODUCT = "REMOVE_CART_PRODUCT";
export const REMOVE_CART_PRODUCT_SUCCESS = "REMOVE_CART_PRODUCT_SUCCESS";
export const REMOVE_CART_PRODUCT_FAILURE = "REMOVE_CART_PRODUCT_FAILURE";

export const RESTORE_CART_PRODUCT = "RESTORE_CART_PRODUCT";
export const RESTORE_CART_PRODUCT_SUCCESS = "RESTORE_CART_PRODUCT_SUCCESS";
export const RESTORE_CART_PRODUCT_FAILURE = "RESTORE_CART_PRODUCT_FAILURE";

export const UPDATE_CART_PRODUCT = "UPDATE_CART_PRODUCT";
export const UPDATE_CART_PRODUCT_SUCCESS = "UPDATE_CART_PRODUCT_SUCCESS";
export const UPDATE_CART_PRODUCT_FAILURE = "UPDATE_CART_PRODUCT_FAILURE";

export const CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD";
export const CREATE_PAYMENT_METHOD_SUCCESS = "CREATE_PAYMENT_METHOD_SUCCESS";
export const CREATE_PAYMENT_METHOD_FAILURE = "CREATE_PAYMENT_METHOD_FAILURE";

export const CHECKOUT = "CHECKOUT";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

export const CLEAR_CHECKOUT_ERROR = "CLEAR_CHECKOUT_ERROR";

export const FETCH_CONFIRMED_CHECKOUT = "FETCH_CONFIRMED_CHECKOUT";
export const FETCH_CONFIRMED_CHECKOUT_SUCCESS =
  "FETCH_CONFIRMED_CHECKOUT_SUCCESS";
export const FETCH_CONFIRMED_CHECKOUT_FAILURE =
  "FETCH_CONFIRMED_CHECKOUT_FAILURE";
