import React from "react";
import cx from "classnames";
import { useField } from "formik";

import InputField from "./InputField";

import "./InputBasic.scss";

const InputBasic = ({ className, rootClassName, append, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <InputField className={rootClassName} {...{ ...props, meta }}>
      <input
        className={cx(
          "form-control gkit-input-md",
          className,
          append ? "form-control-appended" : null,
          meta && meta.touched && meta.error && "is-invalid"
        )}
        type="text"
        {...field}
        {...props}
      />
      {append}
    </InputField>
  );
};

export default InputBasic;
