import React from "react";
import cx from "classnames";

// Styles
import styles from "./CheckoutPage.module.scss";
import { checkoutBlocks } from "./checkoutConstants";
import QuickPaymentBlock from "./QuickPaymentBlock";
import ContactInformationBlock from "./ContactInformationBlock";
import PaymentMethodBlock from "./PaymentMethodBlock";
import ShippingAddressBlock from "./ShippingAddressBlock";
import ContinueBlock from "./ContinueBlock";
import ShippingSummaryBlock from "./ShippingSummaryBlock";
import ShippingMethodBlock from "./ShippingMethodBlock";
import CheckoutBlock from "./CheckoutBlock";

const CheckoutPage = ({
  blocks,
  onQuickCheckoutPaymentRequestGenerated,
  onStandardPaymentRequestGenerated,
  onNextPage,
  onPreviousPage,
  onPaymentMethodCheckout,
  onPaypalCheckout,
  onCardCheckout,
  pageIndex,
  paypalCheckoutInstance,
  onQuickPaypalCheckoutRequestGenerated
}) => {
  return (
    <div className={cx(styles.container)}>
      {blocks.map((blockType) => {
        switch (blockType) {
          case checkoutBlocks.QUICK_PAYMENT:
            return (
              <QuickPaymentBlock
                key={blockType}
                paypalCheckoutInstance={paypalCheckoutInstance}
                onPaypalRequestGenerated={onQuickPaypalCheckoutRequestGenerated}
                onPaymentRequestGenerated={
                  onQuickCheckoutPaymentRequestGenerated
                }
              />
            );

          case checkoutBlocks.CONTACT_INFORMATION:
            return <ContactInformationBlock key={blockType} />;

          case checkoutBlocks.SHIPPING_ADDRESS:
            return <ShippingAddressBlock key={blockType} />;

          case checkoutBlocks.PAYMENT_METHOD:
            return <PaymentMethodBlock key={blockType} />;

          case checkoutBlocks.CONTINUE:
            return <ContinueBlock key={blockType} onClick={onNextPage} />;

          case checkoutBlocks.SHIPPING_SUMMARY:
            return (
              <ShippingSummaryBlock key={blockType} onClick={onPreviousPage} />
            );
          case checkoutBlocks.SHIPPING_METHOD:
            return <ShippingMethodBlock key={blockType} />;

          case checkoutBlocks.CHECKOUT:
            return (
              <CheckoutBlock
                key={blockType}
                onBackClick={onPreviousPage}
                onCardCheckout={onCardCheckout}
                onPaymentMethodCheckout={onPaymentMethodCheckout}
                onPaypalCheckout={onPaypalCheckout}
                onPaymentRequestGenerated={onStandardPaymentRequestGenerated}
                paypalCheckoutInstance={paypalCheckoutInstance}
                showBackButton={pageIndex > 0}
              />
            );

          default:
            return null;
        }
      })}
    </div>
  );
};

export default CheckoutPage;
