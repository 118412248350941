import { FETCH_COUNTRIES_SUCCESS, SET_PAYMENT_PROVIDERS } from "../constants";
import { handleActions } from "redux-actions";
import countries from "assets/json/countries.json";

const lookupFromCountries = countryList =>
  countryList.reduce((acc, country) => {
    acc[country.code] = country;

    return acc;
  }, {});

const initialState = {
  availableCountries: countries,
  availableCountryLookup: lookupFromCountries(countries),
  paymentProviders: null,
};

export default handleActions(
  {
    [SET_PAYMENT_PROVIDERS]: (state, { payload }) => ({
      ...state,
      paymentProviders: payload,
    }),
    [FETCH_COUNTRIES_SUCCESS]: (state, { payload }) => ({
      ...state,
      availableCountries: payload,
      availableCountryLookup: lookupFromCountries(payload),
    }),
  },
  initialState
);
