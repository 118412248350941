import React from "react";
import cx from "classnames";
import { filter } from "lodash";
import countries from "../assets/json/countries.json";

// This is a component as there will be a need to fetch countries data from an endpoint
// to populate the country and province information

const AddressComponent = ({
  address: {
    first_name,
    last_name,
    address1,
    address2,
    city,
    zip,
    country_code,
    province_code,
  },
  className,
}) => {
  const countryObject = filter(countries, o => o.code === country_code)[0];
  let provinceObject;
  if (province_code) {
    provinceObject = filter(
      countryObject.provinces,
      o => o.code === province_code
    )[0];
  }
  return (
    <div>
      <p className="gkit-p-17 mt-0 mb-1 gkit-bold">Saved address</p>
      <p className={cx("gkit-p-15 gkit-light m-0", className)}>
        {first_name && last_name && (
          <>
            {`${first_name} ${last_name}`}
            <br />
          </>
        )}
        {address1}
        {address2 && (
          <>
            <br />
            {address2}
          </>
        )}
        <br />
        {`${city}, ${zip}`}
        {provinceObject && (
          <>
            <br />
            {`${provinceObject.name}, ${countryObject.name}`}
          </>
        )}
        {!provinceObject && (
          <>
            <br />
            {countryObject?.name}
          </>
        )}
      </p>
    </div>
  );
};

export default AddressComponent;
