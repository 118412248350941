export const checkoutBlocks = {
  QUICK_PAYMENT: "quick-payment",
  CONTACT_INFORMATION: "contact-information",
  SHIPPING_ADDRESS: "shipping-address",
  CONTINUE: "continue",
  SHIPPING_SUMMARY: "shipping-summary",
  SHIPPING_METHOD: "shipping-method",
  PAYMENT_METHOD: "payment-method",
  MARKETING: "accepts-marketing",
  CHECKOUT: "checkout",
};

export const paymentTypes = {
  PAYPAL: "paypal",
  CARD: "card",
  APPLE_PAY: "apple-pay",
};

export const paymentMethodProviders = {
  PAYPAL: "paypal",
  STRIPE: "stripe",
};

export const multiTinLookup = {
  pink: {
    title: "Pink Grind Tin",
    variantTitles: ["Pods", "Coffee"],
    availableLocations: ["UK", "EU", "US"],
  },
  silver: {
    title: "Silver Grind Tin",
    variantTitles: ["Silver Pods", "Silver Coffee"],
    availableLocations: ["UK", "EU"],
  },
  black: {
    title: "Black Grind Tin",
    variantTitles: ["Black Pods", "Black Coffee"],
    // variantTitles: ["Black Pods"],
    availableLocations: ["UK", "EU"],
  },
  shantell: {
    title: "Shantell Martin",
    variantTitles: ["Shantell Martin"],
    availableLocations: ["UK", "EU"],
  },
  kitty: {
    title: "Hello Kitty Tin",
    variantTitles: ["Hello Kitty"],
    availableLocations: ["UK", "EU"],
  },
  lazyoaf: {
    title: "Lazy Oaf Tin",
    variantTitles: ["Lazy Oaf"],
    availableLocations: ["UK", "EU"],
  },
  greenday: {
    title: "Green Day Tin",
    variantTitles: ["Green Day"],
    availableLocations: ["UK", "EU"],
  },
  christmas: {
    title: "Christmas Tin",
    variantTitles: ["Christmas"],
    availableLocations: ["UK", "EU"],
  },
  sophy: {
    title: "Sophy Hollington",
    variantTitles: ["Sophy Hollington"],
    availableLocations: ["UK", "EU"],
  },
};
