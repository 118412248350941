import React, { useState } from "react";
import cx from "classnames";

import constants from "api/constants";
import { Button } from "shared/Button";
import BootstrapModal from "shared/components/BootstrapModal";

import styles from "./PageHeader.module.scss";

const ReturnModal = ({ isVisible, onDismissed }) => {
  return (
    <BootstrapModal isCentered isVisible={isVisible} onDismissed={onDismissed}>
      <div className="modal-body">
        <p className="gkit-p-15">
          Are you sure you want to leave the checkout and return to the home
          page?
        </p>
        <div className="d-flex gap-2 flex-column flex-md-row">
          <a
            className="ms-md-auto d-block d-md-inline-block"
            href={constants.shopifyURL}
          >
            <Button className="w-100">Yes, take me back.</Button>
          </a>
          <Button btnType="secondary" onClick={onDismissed}>
            No, I'm not done yet
          </Button>
        </div>
      </div>
    </BootstrapModal>
  );
};

const PageHeader = () => {
  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <ReturnModal
        isVisible={isVisible}
        onDismissed={() => setVisible(false)}
      />
      <div
        className={cx(
          "w-100 d-flex align-items-center justify-content-start justify-content-md-center",
          styles.container
        )}
      >
        {/* <img
      alt="Grind"
      className={cx(styles.img, "d-inline-block")}
      src={require("../../assets/img/grind-logo.svg").default}
    /> */}
        <a
          // TODO: not sure if ?sub-wizard=true is required?
          // href={`${constants.shopifyURL}?sub-wizard=true`}
          href={constants.shopifyURL}
          className="d-inline-block float-left align-center position-relative"
          onClick={e => {
            e.preventDefault();
            setVisible(true);
          }}
        >
          <img
            alt="Grind"
            className={cx(styles.img, "d-inline-block")}
            src={require("../../assets/img/grind-logo.svg").default}
          />
          {/* <img
          alt="Back"
          className="position-absolute"
          src={require("../../assets/img/backButton.png")}
          style={{ height: 40, width: 40 }}
        /> */}
        </a>
      </div>
    </>
  );
};

export default PageHeader;
