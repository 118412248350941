import GenericRadioSelect from "app/container/Checkout/Generic/GenericRadioSelect";
import PaymentRadioContentComponent from "app/container/Checkout/Payment/PaymentRadioContentComponent";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentMethod } from "redux/actions/checkout";
import NewPaymentMethod from "./paymentMethodBlock/NewPaymentMethod";

const PaymentMethodBlock = ({ onPaymentRequestGenerated }) => {
  const dispatch = useDispatch();
  const { availablePaymentMethods, paymentMethod, cart } = useSelector(s => ({
    ...s.checkout,
    ...s.auth,
  }));

  const onPaymentMethodSelected = useCallback(
    paymentMethodId => {
      dispatch(setPaymentMethod(paymentMethodId));
    },
    [dispatch]
  );

  return (
    <div className="pt-5">
      <p className="gkit-p-20 gkit-bold mb-0">Payment method</p>
      {parseInt(cart.payment_due.amount) === 0 && (
        <p className="gkit-p-13 gkit-bold text-danger">
          Please select a payment method for future transactions. Your account
          won't be charged for the current transaction.
        </p>
      )}
      {availablePaymentMethods && availablePaymentMethods.length > 0 && (
        <>
          <p className="gkit-p-17 gkit-light mt-2">Saved payment methods</p>
          <GenericRadioSelect
            entities={availablePaymentMethods}
            onEntitySelected={onPaymentMethodSelected}
            RadioContentComponent={PaymentRadioContentComponent}
            selectedEntityID={
              typeof paymentMethod === "number" ? paymentMethod : null
            }
          />
        </>
      )}
      <p className="gkit-p-17 gkit-light mt-2">Available payment methods</p>
      <NewPaymentMethod />
    </div>
  );
};

export default PaymentMethodBlock;
