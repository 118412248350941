import React from "react";
import cx from "classnames";

import "./Button.scss";

const ButtonIcon = ({
  alt,
  icon,
  className,
  size = "sm",
  children,
  dirn = "left",
  ...props
}) => (
  <button className={cx(`gkit-btn-${size}`, "btn-icon", className)} {...props}>
    {dirn !== "left" ? children : null}
    <img
      className={children ? (dirn === "left" ? "me-2" : "ms-2") : null}
      src={icon}
      alt={alt}
    />
    {dirn === "left" ? children : null}
  </button>
);

const Button = ({
  size = "sm",
  children,
  className,
  btnType = "primary",
  disabled,
  isLoading,
  ...props
}) => (
  <button
    className={cx(
      `gkit-btn-${size}`,
      !disabled ? `gkit-${btnType}` : null,
      disabled ? "disabled" : null,
      isLoading ? "gkit-loading" : null,
      className
    )}
    disabled={disabled}
    {...props}
  >
    {isLoading ? "loading.." : children}
  </button>
);

export { ButtonIcon, Button };
