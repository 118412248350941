import React, { useCallback } from "react";
import cx from "classnames";

import styles from "./TrustpilotBlock.module.scss";

const TrustpilotBlock = ({ className }) => {
  const onRender = useCallback(node => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(node, true);
    }
  }, []);

  return (
    <div className={cx(styles.container, className)}>
      <img
        alt="Grind Starter Pack"
        className="w-100"
        src={require("assets/images/assetStarterPack.png").default}
      />
      <div
        className="trustpilot-widget mt-4"
        data-locale="en-GB"
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="5919e89d0000ff0005a28068"
        data-style-height="48px"
        data-style-width="100%"
        data-theme="light"
        data-text-color="#959291"
        ref={onRender}
      >
        <a
          href="https://uk.trustpilot.com/review/www.grind.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </div>
  );
};

export default TrustpilotBlock;
