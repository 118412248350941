import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import api, { extractAPIErrorString } from "api";

import InputBasic from "shared/components/InputBasic";
import InputCheckbox from "shared/components/InputCheckbox";
import { Button } from "shared/Button";
import ErrorHandling from "shared/ErrorHandling";
import { authSuccess } from "redux/actions/auth";

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  accepts_marketing: Yup.boolean().required(),
});

const Register = ({ emailState, handleModal, setLoginState }) => {
  const [hasError, setHasError] = useState(null);
  const dispatch = useDispatch();
  const handleSubmit = ({ accepts_marketing, ...values }) => {
    setHasError(null);
    return api
      .post("/auth/register", values)
      .then(async ({ data }) => {
        if (data.token) {
          try {
            dispatch(authSuccess(data.token))
              .then(() =>
                api.put("/account/preferences", {
                  accepts_marketing,
                })
              )
              .then(() => {
                handleModal(false);
              });
          } catch (error) {
            setHasError(extractAPIErrorString(error));
          }
        } else {
          // upon registration, dont close modal and allow input of code
          // this situation shouldn't happen anymore!
          setLoginState("codeLogin");
        }
      })
      .catch((error) => {
        setHasError(extractAPIErrorString(error));
      });
  };

  return (
    <Formik
      initialValues={{
        email: emailState || "",
        first_name: "",
        last_name: "",
        accepts_marketing: false,
      }}
      onSubmit={handleSubmit}
      validationSchema={RegisterSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="mt-4 mt-md-0 p-md-4">
          <h2 className="gkit-h2 text-md-center">Create an account</h2>
          <p className="gkit-p-17 gkit-light text-md-center">
            Looks like you're new here, we need a little more info:
          </p>
          <div className="container p-0">
            <div className="row">
              <div className="col">
                <InputBasic
                  className="mt-3"
                  name="email"
                  label="Email address"
                  placeholder="jane@email.com"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <InputBasic
                  className="mt-3"
                  name="first_name"
                  label="First name"
                  autoComplete="given-name"
                />
              </div>
              <div className="col-12 col-md-6">
                <InputBasic
                  className="mt-3"
                  name="last_name"
                  label="Last name"
                  autoComplete="family-name"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <InputCheckbox name="accepts_marketing">
                  Send me news of what Grind is up to including new products,
                  exclusive offers and dogs that we like.
                </InputCheckbox>
              </div>
            </div>
          </div>
          <ErrorHandling error={hasError} />
          <Button
            size="md"
            className="mt-3"
            type="submit"
            id="submit-button"
            isLoading={isSubmitting}
          >
            Continue
          </Button>
          <p className="gkit-p-15 mt-3 text-md-center">
            Already created a password?{" "}
            <strong
              className="cursor-pointer"
              onClick={() => setLoginState("login")}
            >
              Try another email
            </strong>
          </p>
        </Form>
      )}
    </Formik>
  );
};

export default Register;
