import React, { useCallback, useState } from "react";
import LoginModal from "app/container/Checkout/LoginModal";

const SignInBlock = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const onClick = useCallback(() => {
    setModalVisible(true);
  }, []);
  return (
    <>
      <p className="gkit-p-20 gkit-light gkit-clickable my-0" onClick={onClick}>
        Returning customer?{" "}
        <span className="gkit-bold gkit-charcoal">Sign in</span>
      </p>
      <LoginModal isVisible={modalVisible} handleModal={setModalVisible} />
    </>
  );
};

export default SignInBlock;
