import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import Dropdown from "./Dropdown";

const ProvinceField = ({ countryLookup, ...props }) => {
  const {
    values: { country_code },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    setFieldValue("province_code", "");
  }, [setFieldValue, country_code]);

  const provinces = useMemo(() => {
    if (
      !country_code ||
      !countryLookup[country_code] ||
      !countryLookup[country_code].provinces
    ) {
      return null;
    }

    return countryLookup[country_code].provinces.map(province => ({
      label: province.name,
      value: province.code,
    }));
  }, [countryLookup, country_code]);

  if (!provinces) {
    return null;
  }

  return <Dropdown options={provinces} {...props} />;
};

export default ProvinceField;
