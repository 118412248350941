import React, { useCallback, useMemo, useState } from "react";
import cx from "classnames";
import styles from "./TinSelectBlock.module.scss";

import IconTin from "assets/img/iconGrindTin.svg";
import IconCaret from "assets/img/iconCaretDown.svg";
import TinBlock from "./TinBlock";
import { useDispatch } from "react-redux";
import {
  removeLineItem,
  restoreLineItem,
  updateLineItem,
} from "redux/actions/checkout";

const TinSelectBlock = ({ cart, className }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const relevantLineItem = cart.products.find((item) =>
    Boolean(item.children?.find(({ type }) => type === "rule") && !item.product.title.toUpperCase().includes("MATCHA"))
  );

  const relevantChild = relevantLineItem?.children.find(
    ({ type }) => type === "rule"
  );

  const tinOptions = useMemo(() => {
    if (!relevantChild) {
      return null;
    }

    return [
      {
        line_item_id: relevantChild.id,
        product_id: relevantChild.product.id,
        variant_id: relevantChild.variant.id,
        image: relevantChild.variant.image || relevantChild.product.image,
        product_title: relevantChild.product.title,
        variant_title: relevantChild.variant.title,
        selected: relevantChild.removed === false,
      },
      ...relevantChild.alternatives.map((alt) => ({
        line_item_id: relevantChild.id,
        product_id: alt.product_id,
        variant_id: alt.variant_id,
        image: alt.image || relevantChild.product.image,
        product_title: alt.title,
        variant_title: alt.variant_title,
        selected: false,
      })),
    ];
  }, [relevantChild]);

  const selectedVariantId = useMemo(
    () => tinOptions?.find((option) => option.selected) || null,
    [tinOptions]
  );

  const onRemove = useCallback(() => {
    if (isLoading || !relevantChild) {
      return;
    }

    setIsLoading(-1);

    dispatch(removeLineItem(relevantChild.id)).finally(() =>
      setIsLoading(false)
    );
  }, [dispatch, isLoading, relevantChild]);

  const onSelect = useCallback(
    (tinOption) => {
      setIsLoading(tinOption.variant_id);

      const updateFn = () =>
        dispatch(
          updateLineItem(
            relevantChild.id,
            tinOption.product_id,
            tinOption.variant_id
          )
        );

      if (relevantChild.removed) {
        dispatch(restoreLineItem(relevantChild.id))
          .then(() =>
            tinOption.variant_id !== relevantChild.variant.id
              ? updateFn()
              : Promise.resolve()
          )
          .finally(() => setIsLoading(false));
      } else {
        updateFn().finally(() => setIsLoading(false));
      }
    },
    [dispatch, relevantChild]
  );

  if (!tinOptions || tinOptions.length === 0) {
    return null;
  }

  return (
    <div className={cx(styles.container, className)}>
      <div
        className={cx(styles.header, "p-3 d-flex align-items-center")}
        onClick={() => setIsExpanded(!isExpanded)}
        role="button"
      >
        <img alt="Choose your Grind Tin" className="me-2" src={IconTin} />
        <p className="my-0 gkit-p-13 flex-grow-1">Choose your free Grind Coffee Tin</p>
        <img
          alt={`Click to ${isExpanded ? "collapse" : "expand"}`}
          className={cx(isExpanded && styles.rotatedCaret)}
          src={IconCaret}
        />
      </div>
      {isExpanded && (
        <div className={cx(styles.rowContainer, "p-3 pt-0 d-flex flex-wrap")}>
          {tinOptions.map((tinOption) => (
            <TinBlock
              isLoading={isLoading === tinOption.variant_id}
              isSelected={tinOption.selected}
              key={tinOption.variant_id}
              onSelect={() => onSelect(tinOption)}
              tinOption={tinOption}
            />
          ))}

          <TinBlock
            isLoading={isLoading === -1}
            isSelected={selectedVariantId === null}
            onSelect={onRemove}
            tinOption={null}
          />
        </div>
      )}
    </div>
  );
};

export default TinSelectBlock;
