import React from "react";
import cx from "classnames";

const GenericRadioSelect = ({
  entities,
  newEntityLabel = null,
  onEntitySelected,
  selectedEntityID,
  RadioContentComponent,
  topAlignedCheckboxes = false,
}) => {
  if (!entities) {
    return null;
  }

  const onChange = e => {
    const val = e.target.value;

    onEntitySelected(val === "NEW" ? null : parseInt(val));
  };

  return (
    <div>
      {entities.map(entity => (
        <label
          className={cx(
            "d-flex flex-row mb-3",
            topAlignedCheckboxes ? "align-items-start" : "align-items-center"
          )}
          key={entity.id}
        >
          <input
            className="me-3 mt-1"
            type="radio"
            value={entity.id}
            checked={selectedEntityID === entity.id}
            onChange={onChange}
          />
          {<RadioContentComponent entity={entity} />}
        </label>
      ))}
      {newEntityLabel && (
        <label className="d-flex flex-row align-items-center">
          <input
            className="me-3"
            type="radio"
            onChange={onChange}
            value="NEW"
            checked={selectedEntityID === null}
          />
          <p className="gkit-p-17 gkit-bold my-0">{newEntityLabel}</p>
        </label>
      )}
    </div>
  );
};

export default GenericRadioSelect;
