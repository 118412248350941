import _ from "lodash";
import sha1 from "sha1";

import VisaBlank from "../assets/img/payment-blank-visa.svg";
import MastercardBlank from "../assets/img/payment-blank-mastercard.svg";
import AmexBlank from "../assets/img/payment-blank-amex.svg";

import BlankCard from "../assets/img/payment-blank.svg";
import api, { extractAPIErrorString } from "api";

const insertItem = (array, index, item) => {
  return [...array.slice(0, index), item, ...array.slice(index)];
};

const removeItem = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

const generateSrcSet = (srcs) => {
  if (!srcs.length) return null;
  return srcs.map((each) => `${each.url} ${each.width}w`).join(",");
};

const logoVisa = require("assets/images/logoVisa.svg").default;
const logoMasterCard = require("assets/images/logoMasterCard.svg").default;
const logoAmex = require("assets/images/logoAmericanExpress.svg").default;
const logoPayPal = require("assets/images/logoPayPal.svg").default;

const CreditCardImages = {
  Blank: {
    "American Express": AmexBlank,
    amex: AmexBlank,
    Visa: VisaBlank,
    visa: VisaBlank,
    MasterCard: MastercardBlank,
    mastercard: MastercardBlank,
    Generic: BlankCard,
  },
  Regular: {
    "American Express": logoAmex,
    amex: logoAmex,
    Visa: logoVisa,
    visa: logoVisa,
    MasterCard: logoMasterCard,
    mastercard: logoMasterCard,
    paypal: logoPayPal,
  },
};

export const formStrings = {
  GB: {
    placeholders: {
      company: "",
      address1: "",
      phone: "+447712345678",
      city: "Grindtown",
      zip: "GR1 1ND",
    },
  },
  FR: {
    placeholders: {
      company: "",
      address1: "23 rue de Grind",
      city: "Lyon",
      zip: "17000",
      phone: "+33 571 885 959",
    },
  },
  DE: {
    placeholders: {
      company: "",
      address1: "Grindbergstraße 17",
      city: "Erlangen",
      zip: "91006",
      phone: "+49 282 177 3926",
    },
  },
  AT: {
    placeholders: {
      company: "",
      address1: "Grindring 31",
      city: "Steyersberg",
      zip: "2880",
      phone: "+43 688 739 8761",
    },
  },
  NL: {
    placeholders: {
      company: "",
      address1: "Grinderstraat 3",
      city: "Deventer",
      zip: "7415 JZ",
      phone: "+31 624 464 853",
    },
  },
  US: {
    placeholders: {
      company: "",
      address1: "54 W Grind St",
      city: "New York",
      zip: "10024",
      phone: "+1 111 123 4321",
    },
    labels: {
      zip: "Zip Code",
    },
  },
};

export const cartToApplePayProps = (cart) => {
  const shippingOptions = cart.shipping_rates
    ? cart.shipping_rates.map((rate) => ({
      id: String(rate.id),
      label: rate.name,
      amount: Math.round(parseFloat(rate.price.amount) * 100),
    }))
    : [];

  const displayItems = [
    {
      label: "Subtotal",
      amount: Math.round(parseFloat(cart.subtotal_price.amount) * 100),
    },
  ];

  if (cart.discount) {
    displayItems.push({
      label: `Discount: ${cart.discount.code}`,
      amount: -Math.round(parseFloat(cart.discount_amount.amount) * 100),
    });
  }

  if (cart.shipping_rate) {
    displayItems.push({
      label: cart.shipping_rate.name,
      amount: Math.round(parseFloat(cart.shipping_rate.price.amount) * 100),
    });
  }

  if (cart.tax_lines && cart.tax_lines.length > 0) {
    const taxLines = cart.tax_lines.filter((taxLine) =>
      Boolean(parseFloat(taxLine.amount.amount))
    );
    // Show the name of the tax if there's only one
    if (taxLines.length === 1) {
      taxLines.forEach((taxLine) =>
        displayItems.push({
          label: taxLine.name,
          amount: Math.round(parseFloat(taxLine.amount.amount) * 100),
        })
      );
      // If there are multiple taxes, just combine them and show the total
    } else {
      displayItems.push({
        label: "Taxes",
        amount: Math.round(parseFloat(cart.total_tax.amount) * 100),
      });
    }
  }

  const total = {
    label: "Grind",
    amount: Math.round(parseFloat(cart.payment_due.amount) * 100),
    pending: false,
  };

  return {
    shippingOptions,
    displayItems,
    total,
  };
};

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const setGroupAddress = (setFieldValue, value, name) => {
  const {
    street_number,
    route,
    country,
    postal_code,
    postal_town,
    locality,
    administrative_area_level_1,
  } = value;
  if (administrative_area_level_1) {
    setFieldValue(`${name}.province_code`, administrative_area_level_1);
  }
  setFieldValue(`${name}.zip`, postal_code || "");

  setFieldValue(`${name}.country_code`, country || "");
  setFieldValue(`${name}.city`, postal_town || locality || "");
  const address1 = [];
  if (street_number) address1.push(street_number);
  if (route) address1.push(route);
  setFieldValue(`${name}.address1`, address1.length ? address1.join(" ") : "");
};

const authenticateSetupIntent = ({ id, secret }, stripe) =>
  new Promise((resolve, reject) => {
    stripe.confirmCardSetup(secret).then(({ error }) => {
      if (error) {
        reject(error.message);
      } else {
        api.post(`/account/payment-cards/${id}/confirm`).then(
          ({ data }) => {
            resolve(data);
          },
          (err) => {
            reject(extractAPIErrorString(err));
          }
        );
      }
    });
  });

const createPaymentCard = (paymentMethod) =>
  new Promise((resolve, reject) => {
    api
      .post("/account/payment-cards", {
        token: paymentMethod.id,
      })
      .then(
        ({ data }) => {
          resolve(data);
        },
        (err) => {
          // There's not always a response! ????
          if (
            _.get(err, "response.data.error.code") === "card.requires_action"
          ) {
            reject(_.get(err, "response.data.error.card"));
          } else {
            reject(extractAPIErrorString(err));
          }
        }
      );
  });

const shouldUseProvinceCode = (availableCountryLookup, countryCode) =>
  Boolean(availableCountryLookup[countryCode]?.provinces);

const shortestPrice = (price) =>
  price &&
  (price.endsWith(".00") ? price.substring(0, price.length - 3) : price);

const performCheckout = (cart, cardId, stripe) =>
  new Promise((resolve, reject) => {
    const hasSubscription = Boolean(
      cart.products.find((product) => {
        return Boolean(product.subscription || product.type === "subscription");
      })
    );

    api
      .post(`/shop/cart/${hasSubscription ? "subscribe" : "checkout"}`, {
        card_id: cardId,
      })
      .then(
        ({ data }) => {
          resolve(data);
        },
        (err) => {
          if (
            _.get(err, "response.data.error.code") === "charge.requires_action"
          ) {
            // Handle SCA
            // Get our intent ID.
            const intentSecret = _.get(
              err,
              "response.data.error.charge.secret"
            );

            stripe.handleCardAction(intentSecret).then(({ error }) => {
              if (error) {
                reject(error);
              } else {
                return performCheckout(cart, cardId, stripe);
              }
            });
          } else {
            reject(err);
          }
        }
      );
  });
// {first_name, last_name} from name (basic af)
const namePartsFromFull = (name) => {
  const nameComponents = name.trim().split(" ");
  const lastName = nameComponents.pop();
  const firstName = nameComponents.join(" ");

  return {
    first_name: firstName,
    last_name: lastName,
  };
};

const addressObjFromPaypalRequest = (address, availableCountryLookup) => {
  const addr = {
    ...namePartsFromFull(address.recipientName),
    company: address.organisation || undefined,
    address1: address.line1,
    address2: undefined,
    city: address.city,
    zip: address.postalCode,
    phone: undefined,
    country_code: address.countryCode,
  };

  if (shouldUseProvinceCode(availableCountryLookup, address.countryCode)) {
    addr.province_code = address.state;
  }

  return addr;
};

const addressObjFromPaymentRequest = (address, availableCountryLookup) => {
  const addr = {
    ...namePartsFromFull(address.recipient),
    company: address.organisation,
    address1: address.addressLine[0],
    address2:
      address.addressLine.length > 1 ? address.addressLine[1] : undefined,
    city: address.city,
    zip: address.postalCode,
    phone: address.phone,
    country_code: address.country,
  };

  if (shouldUseProvinceCode(availableCountryLookup, address.country)) {
    addr.province_code = address.region;
  }

  return addr;
};

const getSelectedShippingAddress = ({
  shippingAddress,
  availableShippingAddresses,
  completedCheckout,
}) => {
  // If we've got a completed checkout, even if we for some reason have a
  // shipping address selected in redux we want to make the checkout's
  // address the only one we care about.
  // We have to also consider whether this is a subscription orde ror a
  // one time purchase.
  if (completedCheckout) {
    return (
      (completedCheckout.subscription &&
        completedCheckout.subscription.shipping_address) ||
      (completedCheckout.order && completedCheckout.order.shipping_address) ||
      null
    );
  }

  // If we've got available shipping addresses and one is selected,
  // we should look it up and return that.
  // TODO: We may have to come back and get shippingAddress from the cart.
  if (availableShippingAddresses && typeof shippingAddress === "number") {
    return (
      availableShippingAddresses.find(
        (shippingAddr) => shippingAddr.id === shippingAddress
      ) || null
    );
  }

  // Otherwise, act as if it's an unauthenticated shipping address and return
  // that or null.
  return shippingAddress || null;
};

const addressLinesFromAddress = (shippingAddress) => {
  const name = [shippingAddress.first_name, shippingAddress.last_name]
    .filter(Boolean)
    .join(" ");
  const company = shippingAddress.company || null;
  const address1 = shippingAddress.address1 || null;
  const address2 = shippingAddress.address2 || null;
  const zip = shippingAddress.zip || null;
  const city = shippingAddress.city || null;
  const country = shippingAddress.country || null;

  return [name, company, address1, address2, zip, city, country].filter(
    Boolean
  );
};

const generateImpactTrackingObject = (cart, checkout, irclickid) => {
  if (checkout.subscription) {
    const subId = checkout.subscription.id;
    const clickId = irclickid;
    const currencyCode = cart.currency;

    const user = checkout.customer || {};

    const customerId = user.id || "";
    const customerEmail = user.email ? sha1(user.email) : "";
    const discountCode =
      _.get(checkout.subscription, "last_dispatch.order.discount_code") || "";
    const discountAmount =
      _.get(checkout.subscription, "last_dispatch.order.discount.amount") || 0;

    const items = _.get(checkout.subscription, "last_dispatch.order")
      ? _.get(checkout.subscription, "last_dispatch.order.products").map(
        (product) => ({
          category: "coffee",
          subTotal: product.subtotal.amount,
          name: product.product.title,
          quantity: product.quantity,
          sku: product.variant.sku,
        })
      )
      : cart.products.map((product) => ({
        category: "coffee",
        subTotal: product.price.amount,
        name: product.product.title,
        quantity: product.quantity,
        sku:
          _.get(product, "variant.sku") ||
          _.get(product, "variant.id") ||
          product.product.title,
      }));

    const trackingPayload = {
      orderId: subId,
      clickId: clickId,
      customerId: customerId,
      customerEmail: customerEmail,
      currencyCode: currencyCode,
      orderPromoCode: discountCode,
      orderDiscount: discountAmount,
      items: items,
    };

    if (window.ire) {
      window.ire("trackConversion", 24816, trackingPayload, {
        verifySiteDefinitionMatch: true,
      });
    }
  } else {
    // OTP Orders...
  }
};

export const trackFosphaTransaction = (cart, checkout) => {
  if (checkout.subscription) {
    const currencyCode = cart.currency;

    const products = _.get(checkout.subscription, "last_dispatch.order")
      ? _.get(checkout.subscription, "last_dispatch.order.products").map(
        (product) => ({
          price: product.subtotal.amount,
          title: product.product.title,
          variant_title: product.variant?.title,
          quantity: product.quantity,
          sku: product.variant.sku,
        })
      )
      : cart.products.map((product) => ({
        price: product.price.amount,
        title: product.product.title,
        quantity: product.quantity,
        sku:
          _.get(product, "variant.sku") ||
          _.get(product, "variant.id") ||
          product.product.title,
      }));

    const salesGoalString = JSON.stringify({
      transactionId:
        _.get(checkout, "subscription.last_dispatch.order.order_number") ||
        String(_.get(checkout, "subscription.id")),
      revenue: _.get(
        checkout,
        "subscription.last_dispatch.order.total.amount",
        0
      ),
      discount: _.get(
        checkout,
        "subscription.last_dispatch.order.discount.amount",
        0
      ),
      customerId: _.get(checkout, "customer.id"),
      currency: currencyCode,
      token: checkout.token,
      products,
    });

    if (window.fospha) {
      window.fospha("send", "event", {
        eventCategory: "goal",
        eventCatLabel: "goal",
        eventAction: "achieved",
        eventItemId: "sale",
        eventLabel: "sale",
        eventText: salesGoalString,
      });
    }
  }
};

export const trackTVSquaredTransaction = (checkout) => {
  const {
    customer,
    subscription: {
      last_dispatch: { order },
    },
  } = checkout;

  var _tvq = (window._tvq = window._tvq || []);

  (function () {
    var session = { user: customer.id };
    var actionname = "purchase_subscription";
    var action = {
      rev: order.total.amount,
      id: order.order_number,
    };
    var u =
      "https:" === document.location.protocol
        ? "https://collector-31248.tvsquared.com/"
        : "http://collector-31248.tvsquared.com/";
    _tvq.push(["setSiteId", "TV-6381725418-1"]);
    _tvq.push(["setTrackerUrl", u + "tv2track.php"]);
    _tvq.push([
      function () {
        this.setCustomVariable(5, "session", JSON.stringify(session), "visit");
      },
    ]);
    _tvq.push([
      function () {
        this.setCustomVariable(5, actionname, JSON.stringify(action), "page");
      },
    ]);
    _tvq.push(["trackPageView"]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.type = "text/javascript";
    g.defer = true;
    g.async = true;
    g.src = u + "tv2track.js";
    s.parentNode.insertBefore(g, s);
  })();
};

const stripEmptyStrings = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === "") {
      newObj[key] = undefined;
    }
  });

  return newObj;
};

// Detect which store the user is coming from based on a URL param.
const getStore = (search) => {
  const urlParams = new URLSearchParams(search);
  const storeParam = urlParams.get("store");

  return storeParam ? storeParam : "UK";
};

// Wrap ® symbols in <sup> tags.
const strWithSuperR = (str) => {
  return str.split('®').reduce((acc, str, idx) => {
    if (idx > 0) {
      acc.push(<sup>®</sup>)
    }

    acc.push(str)
    return acc
  }, []);
};

export {
  insertItem,
  removeItem,
  generateSrcSet,
  CreditCardImages,
  loadScript,
  setGroupAddress,
  createPaymentCard,
  authenticateSetupIntent,
  performCheckout,
  shortestPrice,
  namePartsFromFull,
  addressObjFromPaymentRequest,
  addressObjFromPaypalRequest,
  getSelectedShippingAddress,
  addressLinesFromAddress,
  generateImpactTrackingObject,
  stripEmptyStrings,
  shouldUseProvinceCode,
  getStore,
  strWithSuperR,
};
