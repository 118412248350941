import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkUser } from "../../redux/actions/auth";
import Checkout from "app/Checkout";
import CheckoutConfirmation from "app/CheckoutConfirmation";
import constants from "api/constants";
import TestPage from "app/TestPage";

const AppRouter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkUser());
  }, [dispatch]);

  return (
    <Switch>
      <Route
        component={CheckoutConfirmation}
        path="/:checkoutToken/confirmation"
      />
      {constants.testPageEnabled && (
        <Route component={TestPage} path="/test-page" />
      )}
      <Route component={Checkout} />
    </Switch>
  );
};

export default AppRouter;
