import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import ContactInformationForm from "./contactInformationBlock/ContactInformationForm";
import { useSelector } from "react-redux";

const ContactInformationBlock = () => {
  const { contactDetails, isLoggedIn } = useSelector(s => ({
    ...s.checkout,
    ...s.auth,
  }));

  return (
    <div className="pt-5">
      <p className="gkit-p-20 gkit-bold">Contact information</p>
      <Formik
        enableReinitialize={true}
        initialValues={
          contactDetails || { email: "", first_name: "", last_name: "" }
        }
        validationSchema={Yup.object().shape({
          email: Yup.string().email("Please enter a valid email.").required("Please enter an email address."),
          first_name: Yup.string().required("Please enter a valid first name."),
          last_name: Yup.string().required("Please enter a valid last name."),
        })}
      >
        {formikProps => (
          <ContactInformationForm isLoggedIn={isLoggedIn} {...formikProps} />
        )}
      </Formik>
    </div>
  );
};

export default ContactInformationBlock;
