import {
  FETCH_PROFILE_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_LOGGED_OUT,
  SET_LOGIN_AVAILABLE,
  SET_REGISTER_AVAILABLE,
} from "../constants";
import { handleActions } from "redux-actions";

const initialState = {
  loginAvailableWithEmail: null,
  registerAvailable: null,
  isLoggedIn: null,
  accessToken: null,
  user: null,
};

export default handleActions(
  {
    [LOGOUT]: () => ({
      ...initialState,
      isLoggedIn: false,
    }),
    [LOGIN_SUCCESS]: (state, { payload }) => ({
      ...state,
      accessToken: payload,
    }),
    [FETCH_PROFILE_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoggedIn: true,
      user: payload,
    }),
    [SET_LOGGED_OUT]: state => ({
      ...state,
      isLoggedIn: false,
    }),
    [SET_LOGIN_AVAILABLE]: (state, { payload }) => ({
      ...state,
      loginAvailableWithEmail: payload,
      registerAvailable: false,
    }),
    [SET_REGISTER_AVAILABLE]: (state, { payload }) => ({
      ...state,
      registerAvailable: payload,
      loginAvailableWithEmail: false,
    }),
  },
  initialState
);
