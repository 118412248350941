import React from "react";

const ShippingMethodRadioContentComponent = ({ entity }) => (
  <p className="gkit-p-17 gkit-bold m-0">
    {entity.name} -{" "}
    {entity.price.amount === "0.00" ? "FREE" : entity.price.formatted}
  </p>
);

export default ShippingMethodRadioContentComponent;
