import ShippingMethodRadioContentComponent from "app/container/Checkout/Delivery/ShippingMethodRadioContentComponent";
import GenericRadioSelect from "app/container/Checkout/Generic/GenericRadioSelect";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  loadShippingMethods,
  setShippingMethod,
  updateAnonymousCartShippingDetails,
  updateCartShippingAddress,
  updateCartShippingMethod,
} from "redux/actions/checkout";

const ShippingMethodBlock = () => {
  const dispatch = useDispatch();
  const {
    availableShippingMethods,
    isLoggedIn,
    shippingAddress,
    shippingMethod,
  } = useSelector(s => ({ ...s.checkout, ...s.auth }));

  const onShippingMethodSelected = useCallback(
    shippingMethodId => {
      dispatch(setShippingMethod(shippingMethodId));
      if (isLoggedIn) {
        dispatch(updateCartShippingMethod(shippingMethodId));
      } else {
        dispatch(
          updateAnonymousCartShippingDetails(shippingAddress, shippingMethodId)
        );
      }
    },
    [dispatch, isLoggedIn, shippingAddress]
  );

  // TODO: Update to support 2nd-page-logins.
  useEffect(() => {
    if (!availableShippingMethods && !isLoggedIn) {
      dispatch(loadShippingMethods()).then(shippingMethods => {
        if (shippingMethods.length > 0) {
          onShippingMethodSelected(shippingMethods[0].id);
        }
      });
    } else if (isLoggedIn && !availableShippingMethods) {
      dispatch(updateCartShippingAddress(shippingAddress));
    } else if (isLoggedIn && availableShippingMethods && !shippingMethod) {
      onShippingMethodSelected(availableShippingMethods[0].id);
    }
  }, [
    availableShippingMethods,
    dispatch,
    isLoggedIn,
    onShippingMethodSelected,
    shippingAddress,
    shippingMethod,
  ]);

  if (!availableShippingMethods) {
    return null;
  }

  return (
    <div className="mt-5">
      <p className="gkit-p-20 gkit-bold">Choose a delivery option</p>
      <p className="gkit-p-17 gkit-light">
        Free delivery for all subscriptions
      </p>
      <GenericRadioSelect
        entities={availableShippingMethods}
        onEntitySelected={onShippingMethodSelected}
        selectedEntityID={shippingMethod}
        RadioContentComponent={ShippingMethodRadioContentComponent}
      />
    </div>
  );
};

export default ShippingMethodBlock;
