import React, { useState } from 'react'
import api from "api"
import cx from "classnames"

import { sendEvent } from "shared/DataLayer"

import PhoneRewardsScreen from "../../assets/images/iphoneRewardsScreen.webp"

import styles from "./RewardsSignupBlock.module.scss"

const RewardsSignupBlock = ({ setCustomerEnrolled, completedCheckout }) => {
  const [enrolError, setEnrolError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const rewardsPoints = completedCheckout.loyalty.points_value

  const enrolAndOpenRewards = event => {
    const { href } = event.target

    event.preventDefault()

    setIsLoading(true)

    api
      .post(`/shop/checkout/${completedCheckout.token}/loyalty/enrol`)
      .then(() => {
        sendEvent("postPurchaseRewardsEnrol", rewardsPoints)

        window.open(
          href,
          '_blank'
        )
      })
      .catch(err => {
        console.error(err.message)

        setEnrolError(err.message)
      })
      .finally(() => {
        setIsLoading(false)
        setCustomerEnrolled(true)
      })
  }

  return (
    <div className={cx(styles.container, "mt-4")}>
      <div className={styles.content}>
        {
          (rewardsPoints >= 35)
            ?
            <>
              <h3 className={cx(styles.title, "gkit-h3 m-0")}>You've just earned a free coffee.</h3>

              <p className={cx(styles.copy, "gkit-p-15 mb-0 mt-2")}>Join Grind Rewards now to add these points to your account.<br />You've earned {rewardsPoints} points on this order and 35 points can be redeemed for a <span className={styles.standout}>free coffee</span> online or in-store. Earn 1 point for every £1 you spend with us.</p>
            </>
            :
            <>
              <h3 className={cx(styles.title, "gkit-h3 m-0")}>You've just earned {rewardsPoints} points.</h3>

              <p className={cx(styles.copy, "gkit-p-15 mb-0 mt-2")}>Join Grind Rewards now to add these points to your account. Once you reach 35 points you'll get a <span className={styles.standout}>free coffee</span> online or in-store. Earn 1 point for every £1 you spend with us.</p>
            </>
        }

        {!enrolError &&
          <a onClick={enrolAndOpenRewards} className={cx("mt-3 gkit-btn-md gkit-quaternary", isLoading && "gkit-loading")} href="https://my.grind.co.uk/rewards">
            Get points now
          </a>
        }

        {enrolError && <p className="mt-3 gkit-p-15 text-danger">{enrolError}</p>}

        <p className={cx(styles.terms, "mb-0 mt-3 gkit-p-11")}>Opt in via the link to get your points and join Grind Rewards. T&Cs apply</p>
      </div>

      <img className={styles.img} alt="" src={PhoneRewardsScreen} />
    </div>
  )
}

export default RewardsSignupBlock