import React, { useMemo } from "react";
import SubscriptionIcon from "../../../assets/images/subscription.svg";
import IconGreenTick from "../../../assets/images/iconGreenTick.svg";
import IconMatchaTin from "../../../assets/images/iconMatchaTin.svg";
import styles from "./LineItemBadge.module.scss";

const badgeSettings = {
  onetime: {
    text: "One Time Purchase",
    backgroundColour: "dark",
    textColour: "white",
  },
  subscription: {
    text: "Subscription",
    backgroundColour: "pink",
    textColour: "dark",
  },
  discount: {
    text: "Free with code",
    backgroundColour: "palegreen",
    textColour: "dark",
  },
  gwp: {
    text: "Free Gift",
    backgroundColour: "palegreen",
    textColour: "dark",
  },
  matchatin: {
    text: "Free matcha tin",
    backgroundColour: "palegreen",
    textColour: "dark",
  },
};

const LineItemBadge = ({ type }) => {
  const settings = useMemo(() => badgeSettings[type], [type]);

  return (
    <div
      className={`d-inline-block gkit-bg-${settings.backgroundColour} gkit-p-13 gkit-bold text-${settings.textColour} mb-0 ${styles.lineItemBadge}`}
    >
      <span className={styles.centreAlign}>
        {type === "onetime" && (
          <span className={styles.otpOuter}>
            <span className={styles.otpInner}>1</span>
          </span>
        )}
        {type === "subscription" && (
          <img src={SubscriptionIcon} height="20" alt="" />
        )}

        {(type === "discount" || type === "gwp") && (
          <img src={IconGreenTick} height="20" alt="" />
        )}

        {(type === "matchatin") && (
          <img src={IconMatchaTin} height="20" alt="" />
        )}

        <span className="ms-2">{settings.text}</span>
      </span>
    </div>
  );
};

export default LineItemBadge;
