import Bugsnag from "@bugsnag/js";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";

import { Button } from "shared/Button";
import { paymentTypes } from "./checkoutConstants";

const paypalButtonArguments = (
  paypalCheckoutInstance,
  onSuccessfulCheckout
) => ({
  fundingSource: window.paypal.FUNDING.PAYPAL,

  createBillingAgreement: () =>
    paypalCheckoutInstance.createPayment({
      flow: "vault",
      enableShippingAddress: false,
    }),

  onApprove: (data, actions) =>
    paypalCheckoutInstance.tokenizePayment(data).then(onSuccessfulCheckout),

  onCancel: (data) => {
    console.warn("Paypal Cancelled: ", data);
  },

  onError: (err) => {
    console.error("Paypal Error: ", err);
  },

  style: {
    color: "black",
    height: 51,
    label: "pay",
  },
});

const btn = {};

const CheckoutBlock = ({
  onBackClick,
  onPaypalCheckout,
  onCardCheckout,
  onPaymentMethodCheckout,
  onPaymentRequestGenerated,
  paypalCheckoutInstance,
  showBackButton,
}) => {
  const {
    cart,
    shippingAddress,
    shippingMethod,
    paymentMethod,
    hasCardDetails,
  } = useSelector((s) => s.checkout);

  const {
    requires_shipping: requiresShipping,
    currency,
    country_code: countryCode,
  } = cart;

  const needsAddress = Boolean(requiresShipping && !shippingAddress);
  const needsShippingMethod = Boolean(requiresShipping && !shippingMethod);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const stripe = useStripe();

  const canCheckout =
    (needsAddress || needsShippingMethod) === false &&
    paymentMethod &&
    !(paymentMethod === paymentTypes.CARD && !hasCardDetails) &&
    !(paymentMethod === paymentTypes.APPLE_PAY && !paymentRequest);

  // Generate our PayPal button component
  useEffect(() => {
    if (!paypalCheckoutInstance) {
      return;
    }

    btn.PayPal = window.paypal.Buttons.driver("react", {
      React,
      ReactDOM,
    });
  }, [paypalCheckoutInstance]);

  useEffect(() => {
    if (paymentRequest || !(stripe && window.ApplePaySession)) {
      return;
    }

    try {
      const pr = stripe.paymentRequest({
        country: countryCode,
        currency: currency.toLowerCase(),
        requestPayerEmail: false,
        requestPayerPhone: false,
        requestPayerName: false,
        requestShipping: false,
        total: {
          label: "Grind",
          amount: Math.round(parseFloat(cart.payment_due.amount) * 100),
        },
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);

          if (onPaymentRequestGenerated) {
            onPaymentRequestGenerated(pr);
          }
        }
      });
    } catch (e) {
      Bugsnag.notify(new Error(`${e.name} - ${e.message}`));
    }
  }, [
    countryCode,
    currency,
    cart.payment_due.amount,
    onPaymentRequestGenerated,
    paymentRequest,
    stripe,
  ]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.update({
        total: {
          label: "Grind",
          amount: Math.round(parseFloat(cart.payment_due.amount) * 100),
        },
      });
    }
  }, [cart.payment_due.amount, paymentRequest]);

  let progressContents;

  if (canCheckout) {
    switch (paymentMethod) {
      case paymentTypes.APPLE_PAY:
        progressContents = (
          <PaymentRequestButtonElement
            options={{
              paymentRequest: paymentRequest,
              style: {
                paymentRequestButton: {
                  height: "50px",
                },
              },
            }}
          />
        );
        break;

      case paymentTypes.PAYPAL:
        if (btn.PayPal) {
          progressContents = (
            <btn.PayPal
              {...paypalButtonArguments(
                paypalCheckoutInstance,
                onPaypalCheckout
              )}
            />
          );
        }
        break;

      case paymentTypes.CARD:
      default:
        progressContents = (
          <Button
            className="w-100"
            onClick={
              typeof paymentMethod === "number"
                ? onPaymentMethodCheckout
                : onCardCheckout
            }
            size="lg"
          >
            Pay Now
          </Button>
        );
        break;
    }
  } else {
    progressContents = (
      <Button className="w-100" disabled={true} size="lg">
        Pay Now
      </Button>
    );
  }

  return (
    <div className="mt-5">
      <div className="row">
        <div className="col-12 col-lg-6 order-lg-2">{progressContents}</div>
        {showBackButton && (
          <div className="col-12 col-lg-6 mt-3 mt-lg-0 order-lg-1">
            <Button
              className="w-100"
              btnType="secondary"
              onClick={onBackClick}
              size="lg"
            >
              Return to shipping
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutBlock;
