// This is a separate set of shared functions to populate the DataLayer
// from state and push events & pageviews to GTM
import store from "../redux/store";

// Main Send GTM Event Function
const sendEvent = (eventName, data = false) => {
  try {
    const state = store.getState();
    const eventData = getStandardEventData(eventName, state);

    switch (eventName) {
      case "checkoutInit":
        sendPageview("/checkout/contact", "Checkout: Contact / Address");
        break;

      case "checkoutPayment":
        sendPageview("/checkout/payment", "Checkout: Shipping / Payment");
        eventData.user = getUserData(state.checkout.contactDetails);
        eventData.user.acceptsMarketing = state.checkout.acceptsMarketing;
        eventData.shippingAddress = state.checkout.shippingAddress;
        break;

      case "checkoutConversion":
        sendPageview("/checkout/complete", "Checkout: Complete");
        optimizelyTotalRevenue(eventData);
        eventData.user = getUserData(state.checkout.contactDetails);
        eventData.user.acceptsMarketing = state.checkout.acceptsMarketing;
        eventData.shippingAddress = state.checkout.shippingAddress;
        eventData.order = getOrderData(state);

        // Set Legacy & Current Order Discount
        const discountData = getDiscountData(state);
        eventData.discountData = discountData;
        if (parseFloat(discountData?.amount) > 0) {
          eventData.discount = discountData.amount;
        }

        break;

      case "emailComplete":
        eventData.user = { email: data };
        break;

      case "contactComplete":
        eventData.user = getUserData(data);
        break;

      case "marketingChange":
        eventData.user = { acceptsMarketing: data ? true : false };
        break;

      case "addDiscount":
        eventData.discountData = { code: data };
        break;

      case "removeDiscount":
        eventData.discountData = { code: null };
        break;

      case "setPaymentMethod":
        eventData.paymentMethod = data;
        break;

      case "postPurchaseRewardsEnrol":
        eventData.points = data;
        break;

      default:
      // No Default Action
    }

    window.dataLayer = getDataLayer();
    window.dataLayer.push(eventData);
  } catch (e) {
    console.error(eventName + " Tracking Event Failed To Process: " + e);
  }
};

// Faux Pageview Call (Handy For Funnel Setup In GA)
const sendPageview = (pageURL, pageTitle) => {
  window.dataLayer = getDataLayer();
  window.dataLayer.push({
    event: "checkoutPageview",
    page: {
      url: pageURL,
      title: pageTitle,
    },
  });
};

// Optimizely needs a custom event to track the total revenue per customer.
const optimizelyTotalRevenue = data => {
  if (!data?.value) return;

  const revenueInPence = Number(data.value * 100);

  if (revenueInPence) {
    window.optimizely = window.optimizely || [];
    window.optimizely.push({
      type: "event",
      eventName: "trackRevenue",
      tags: {
        revenue: revenueInPence,
      },
    });
  }
};

// General DL Call
const getDataLayer = () => {
  return window.dataLayer || [];
};

// Unique Event ID (If We Decide To Go CAPI Route)
const generateEventId = () => {
  return "GRIND" + Math.floor(Math.random() * Date.now()).toString();
};

// Standard Data For All Requests
const getStandardEventData = (eventName, state) => {
  const eventId = generateEventId();

  if (!state.checkout.cart) {
    return { event: eventName, eventId };
  }

  let cart_item_count = 0;
  for (let product of state.checkout.cart.products)
    cart_item_count += product.quantity;

  return {
    event: eventName,
    eventId: eventId,
    value: Number(state.checkout.cart.total_price.amount),
    cartToken: state.checkout.cart.token,
    cartItemCount: cart_item_count,
    ecommerce: getEcommLayer(eventName, state),
    ga4items: getGA4Products(state.checkout.cart),
    fbItems: getFBProducts(state.checkout.cart),
    subscription: getSubscriptionItems(state.checkout.cart),
  };
};

// GA Ecomm Layer (UA)
const getEcommLayer = (eventName, state) => {
  const checkout = { products: getGAProducts(state.checkout.cart) };
  if (eventName === "checkoutInit")
    checkout.actionField = { step: 1, option: "Contact" };
  if (eventName === "checkoutPayment")
    checkout.actionField = { step: 2, option: "Payment" };
  if (eventName === "checkoutConversion") {
    const order = getConversionOrderObject(state);
    checkout.actionField = {
      step: 3,
      option: "Conversion",
      id: getLastDispatch(order, "id") || order.id,
      name:
        getLastDispatch(order, "order_number") ||
        order.order_number ||
        order.id,
      affiliation: "Website",
      revenue: parseFloat(
        getLastDispatch(order, "total", "amount") || order.total?.amount || 0
      ),
      tax: 0,
      shipping: 0,
    };

    // Set Coupon
    const coupon =
      getLastDispatch(order, "discount_code") || order.discount_code || false;
    if (coupon && coupon !== null && coupon.trim() !== "") {
      checkout.actionField.coupon = coupon;
    }

    // If Conversion, Early Exit With Additional GA4 Ecomm DL Variables
    const conversion = {
      currencyCode: state.checkout.cart.currency,
      checkout: checkout,
      items: getGA4Products(state.checkout.cart),
      transaction_id: getLastDispatch(order, "id") || order.id,
      affiliation: "Website",
      value: parseFloat(
        getLastDispatch(order, "total", "amount") || order.total?.amount || 0
      ),
      shipping: 0,
      tax: 0,
      currency: state.checkout.cart.currency,
    };

    // Set Coupon
    if (coupon && coupon !== null && coupon.trim() !== "") {
      conversion.coupon = coupon;
    }

    return conversion;
  }

  // Standard Event Return
  return {
    currencyCode: state.checkout.cart.currency,
    checkout: checkout,
    items: getGA4Products(state.checkout.cart),
    currency: state.checkout.cart.currency,
  };
};

// GA (UA) Product Data
const getGAProducts = (cart) => {
  const products = [];
  for (const product of cart.products) {
    products.push({
      name: product.product.title,
      id: product.product.id,
      price: Number(product.price.amount),
      quantity: product.quantity,
      brand: "Grind",
      category: "Coffee",
      variant: product.variant.title,
    });
  }
  return products;
};

// GA4 Product Data
const getGA4Products = (cart) => {
  const products = [];
  for (const product of cart.products) {
    products.push({
      item_name: product.product.title,
      item_id: product.product.id,
      price: Number(product.price.amount),
      quantity: product.quantity,
      item_brand: "Grind",
      item_category: "Coffee",
      item_category2: "G-Charge Checkout",
      item_category3: cart.subscription ? "Subscription" : "OTP",
      item_variant: product.variant.title,
    });
  }
  return products;
};

// Facebook Product Data
const getFBProducts = (cart) => {
  const content_ids = [];
  const contents = [];
  const category = [];
  const item_names = [];
  let cart_item_count = 0;
  for (const product of cart.products) {
    content_ids.push(String(product.product.id));
    category.push("Coffee");
    item_names.push(product.product.title);
    cart_item_count += product.quantity;
    contents.push({
      id: String(product.product.id),
      quantity: product.quantity,
      item_price: Number(product.price.amount),
    });
  }
  return {
    content_ids: content_ids,
    category: category,
    item_names: item_names,
    contents: contents,
    cartItemCount: cart_item_count,
    facebookCartTotal: Number(cart.total_price.amount),
  };
};

// Subscription Info
const getSubscriptionItems = (cart) => {
  if (!cart.subscription) return false;
  const output = cart.subscription.start_date
    ? {
      subscriptionInterval: cart.subscription.interval,
      subscriptionPeriod: cart.subscription.period,
      subscriptionStartDate: cart.subscription.start_date,
    }
    : false;
  return output;
};

// User Info
const getUserData = (user) => {
  return {
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
  };
};

// Get Value From Last Dispatched Order Object If Available
const getLastDispatch = (orderData, value, subvalue) => {
  if (!orderData.last_dispatch) return false;
  if (!orderData.last_dispatch.order) return false;
  if (subvalue) return orderData.last_dispatch.order[value][subvalue] || false;
  return orderData.last_dispatch.order[value] || false;
};

// Get Conversion Order Object
const getConversionOrderObject = (state) => {
  if (!state.checkout.completedCheckout) return false;
  return (
    state.checkout.completedCheckout.order ||
    state.checkout.completedCheckout.subscription
  );
};

// Order Data (Possibly Add More To This?)
const getOrderData = (state) => {
  const order = getConversionOrderObject(state);
  return {
    createdAt: order.created_at,
    subscriptionId: state.checkout.completedCheckout.subscription
      ? state.checkout.completedCheckout.subscription.id
      : false,
  };
};

// Discount Data
const getDiscountData = (state) => {
  return {
    code: state.checkout.cart.discount,
    amount: state.checkout.cart.discount_amount.amount,
    currency: state.checkout.cart.discount_amount.currency,
  };
};

export { sendEvent, sendPageview };
