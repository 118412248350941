import React, { useCallback, useMemo } from "react";
import { Form } from "formik";
import { useDispatch } from "react-redux";

import { setShippingAddress } from "redux/actions/checkout";
import Dropdown from "shared/components/Dropdown";
import ProvinceField from "shared/components/ProvinceField";
import Textbox from "shared/components/Textbox";
import { formStrings } from "shared/utility";

const ShippingAddressForm = ({
  availableCountries,
  availableCountryLookup,
  handleChange,
  handleBlur,
  validateForm,
  values,
}) => {
  const dispatch = useDispatch();
  const onValidateForm = useCallback(() => {
    validateForm().then((errors) => {
      // Whole form is valid
      if (Object.keys(errors).length === 0) {
        const shippingAddr = { ...values };

        if (shippingAddr.phone) {
          shippingAddr.phone = shippingAddr.phone.replace(" ", "");
        }

        // Here we undefine empty vals for our diva
        for (const addrKey of Object.keys(shippingAddr)) {
          if (!shippingAddr[addrKey]) {
            shippingAddr[addrKey] = undefined;
          }
        }

        dispatch(setShippingAddress(shippingAddr));
      }
    });
  }, [dispatch, validateForm, values]);

  const onFieldChange = useCallback(
    (e) => {
      handleChange(e);
      onValidateForm();
    },
    [handleChange, onValidateForm]
  );

  const onFieldBlur = useCallback(
    (e) => {
      handleBlur(e);
      onValidateForm();
    },
    [handleBlur, onValidateForm]
  );

  const countryOptions = useMemo(
    () =>
      (availableCountries || []).map((country) => ({
        label: country.name,
        value: country.code,
      })),
    [availableCountries]
  );

  const countryCode = values ? values.country_code : null;

  const placeholders = formStrings[countryCode]?.placeholders || {};
  const labels = formStrings[countryCode]?.labels || {};

  return (
    <Form className="mt-4">
      <div className="row g-3">
        <Textbox
          autoComplete="shipping given-name"
          containerClass="col-12 col-md-6"
          label="First name"
          name="first_name"
          onChange={onFieldChange}
          onBlur={onFieldBlur}
          placeholder="John"
        />
        <Textbox
          autoComplete="shipping family-name"
          containerClass="col-12 col-md-6"
          label="Last name"
          name="last_name"
          onChange={onFieldChange}
          onBlur={onFieldBlur}
          placeholder="Doe"
        />
        <Textbox
          autoComplete="shipping tel"
          containerClass="col-12"
          label={
            <>
              Phone number{" "}
              <span className="gkit-p-13 gkit-light">
                Text me about my order, exclusive offers and product launches.
              </span>
            </>
          }
          name="phone"
          onChange={onFieldChange}
          onBlur={onFieldBlur}
          placeholder={placeholders.phone || ""}
        />
      </div>
      <hr className="my-3" />
      <div className="row g-3">
        <Textbox
          autoComplete="shipping company"
          containerClass="col-12"
          name="company"
          label="Company"
          onChange={onFieldChange}
          onBlur={onFieldBlur}
          placeholder={placeholders.company || ""}
        />
        <Textbox
          autoComplete="shipping address-line1"
          containerClass="col-12"
          name="address1"
          label="Address line 1"
          onChange={onFieldChange}
          onBlur={onFieldBlur}
          placeholder={placeholders.address1 || ""}
        />
        <Textbox
          autoComplete="shipping address-line2"
          containerClass="col-12"
          name="address2"
          label="Address line 2"
          onChange={onFieldChange}
          onBlur={onFieldBlur}
          placeholder={placeholders.address2 || ""}
        />
        <Textbox
          autoComplete="shipping address-level2"
          containerClass="col-12 col-md-6"
          label="City"
          name="city"
          onChange={onFieldChange}
          onBlur={onFieldBlur}
          placeholder={placeholders.city || ""}
        />
        <Textbox
          autoComplete="shipping postal-code"
          containerClass="col-12 col-md-6"
          name="zip"
          label={labels.zip || "Postcode"}
          onChange={onFieldChange}
          onBlur={onFieldBlur}
          placeholder={placeholders.zip || ""}
        />
        {availableCountries && (
          <>
            <Dropdown
              autoComplete="shipping country"
              containerClass="col-12 col-md-6"
              label="Country"
              name="country_code"
              onChange={onFieldChange}
              onBlur={onFieldBlur}
              options={countryOptions}
            />
            <ProvinceField
              autoComplete="shipping province"
              containerClass="col-12 col-md-6"
              countryLookup={availableCountryLookup}
              label="Province"
              name="province_code"
              onChange={onFieldChange}
              onBlur={onFieldBlur}
            />
          </>
        )}
      </div>
    </Form>
  );
};

export default ShippingAddressForm;
