import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import ReactDOM from "react-dom";

import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";

// Styles
import styles from "./QuickPaymentBlock.module.scss";
import { useSelector } from "react-redux";
import Bugsnag from "@bugsnag/js";

const paypalButtonArguments = (
  paypalCheckoutInstance,
  onSuccessfulCheckout
) => ({
  fundingSource: window.paypal.FUNDING.PAYPAL,

  // this is different from choosing paypal as a payment method
  createBillingAgreement: () =>
    paypalCheckoutInstance.createPayment({
      flow: "vault",
      enableShippingAddress: true,
    }),

  onApprove: (data) => {
    paypalCheckoutInstance.tokenizePayment(data).then(onSuccessfulCheckout);
  },

  onCancel: (data) => {
    console.warn("Paypal Cancelled: ", data);
  },

  onError: (err) => {
    console.error("Paypal Error: ", err);
  },

  style: {
    color: "gold",
    height: 50,
  },
});

const QuickPaymentBlock = ({
  onPaymentRequestGenerated,
  paypalCheckoutInstance,
  onPaypalRequestGenerated,
}) => {
  const { cart } = useSelector((s) => s.checkout);

  const { country_code: countryCode, currency } = cart;

  // The Stripe Payment Request that will allow us to accept
  // Google or Apple Pay
  const [paymentRequest, setPaymentRequest] = useState(null);
  // Grab stripe so we can generate our payment request and (obviously)
  // Do stripe-y stuff.
  const stripe = useStripe();

  // Well, I'm still not sure if these useMemos are the correct thing tbh, just
  // Didn't want to add cart as a dependency and have to regenerate this shit
  // unnecessarily
  const requiresShipping = useMemo(
    () => cart.requires_shipping,
    [cart.requires_shipping]
  );
  const paymentDue = useMemo(
    () => cart.payment_due.amount,
    [cart.payment_due.amount]
  );

  // This section prepares paypal
  const allowPaypal = !!paypalCheckoutInstance;

  const PayPalButton = useMemo(() => {
    if (!paypalCheckoutInstance) {
      return;
    }

    return window.paypal.Buttons.driver("react", {
      React,
      ReactDOM,
    });
  }, [paypalCheckoutInstance]);

  // This section prepares apply pay
  const allowApplePay = !!window.ApplePaySession && paymentRequest;
  useEffect(() => {
    if (paymentRequest || !stripe) {
      return;
    }

    try {
      // Create the payment request that will define the apple pay modal
      const pr = stripe.paymentRequest({
        country: countryCode,
        currency: currency.toLowerCase(),
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestPayerName: true,
        requestShipping: requiresShipping,
        total: {
          label: "Grind",
          pending: true,
          amount: Math.round(parseFloat(paymentDue) * 100),
        },
      });

      // This checks we're in an environment where we can execute this payment
      // request.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          // TODO: Thinking maybe we should do a "here's your darn payment request"
          // handler so a greater component than I can handle the PR events shit

          if (onPaymentRequestGenerated) {
            onPaymentRequestGenerated(pr);
          }
        }
      });
    } catch (e) {
      Bugsnag.notify(new Error(`${e.name} - ${e.message}`));
    }
  }, [
    countryCode,
    currency,
    onPaymentRequestGenerated,
    paymentDue,
    paymentRequest,
    requiresShipping,
    stripe,
  ]);

  useEffect(() => {
    if (!paymentRequest) {
      return;
    }

    paymentRequest.update({
      total: {
        label: "Grind",
        amount: Math.round(parseFloat(paymentDue) * 100),
      },
    });
  }, [paymentDue, paymentRequest]);

  if (!allowApplePay && !allowPaypal) {
    return null;
  }

  return (
    <>
      {/* Apple Pay */}
      {allowApplePay && (
        <div
          className={cx(
            styles.container,
            "d-flex align-items-md-center justify-content-md-between flex-column flex-md-row"
          )}
        >
          <p className="gkit-p-15 text-center text-md-left m-md-0">
            Fast checkout with Apple Pay
          </p>
          <PaymentRequestButtonElement
            className={styles.paymentButtons}
            options={{
              paymentRequest: paymentRequest,
              style: {
                paymentRequestButton: {
                  height: "50px",
                },
              },
            }}
          />
        </div>
      )}
      {/* PayPal */}
      {allowPaypal && PayPalButton && (
        <div
          className={cx(
            styles.container,
            "d-flex align-items-md-center justify-content-md-between flex-column flex-md-row"
          )}
        >
          <p className="gkit-p-15 text-center text-md-left m-md-0">
            Fast checkout with PayPal
          </p>
          <div className={styles.paymentButtons}>
            <PayPalButton
              {...paypalButtonArguments(
                paypalCheckoutInstance,
                onPaypalRequestGenerated
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default QuickPaymentBlock;
