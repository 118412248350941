import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, Redirect } from "react-router";
import qs from "qs";
import _ from "lodash";

import InitialisedCheckout from "./checkout/InitialisedCheckout";
import {
  addressObjFromPaymentRequest,
  addressObjFromPaypalRequest,
  namePartsFromFull,
} from "shared/utility";
import { useDispatch, useSelector } from "react-redux";
import {
  storeDiscountCode,
  addPaymentMethod,
  anonymousCheckout,
  authenticatedCheckout,
  checkout,
  checkoutFailure,
  claimShoppingCart,
  loadCart,
  loadPaymentMethods,
  loadShippingAddresses,
  setIrclickid,
  applyTinPreference,
} from "redux/actions/checkout";
import { paymentMethodProviders } from "./checkout/checkoutConstants";
import Spinner from "shared/components/Spinner";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

// Styles
import styles from "./Checkout.module.scss";
import InvalidCheckout from "./InvalidCheckout";

// This is a big boy. Doesn't do much except process finalised checkouts.
const Checkout = () => {
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const {
    availableCountryLookup,
    cart,
    cartError,
    completedCheckout,
    isLoggedIn,
    paymentMethod,
  } = useSelector((s) => ({ ...s.auth, ...s.checkout, ...s.config }));

  const queryString = useLocation().search;

  const qsObj = useMemo(
    () => qs.parse(queryString, { ignoreQueryPrefix: true }),
    [queryString]
  );

  const cartToken = qsObj.token || null;
  const tinPreference = qsObj.tin || "pink";
  const requiresShipping = cart ? cart.requires_shipping : null;
  const urlDiscountCode = qsObj.discount_code || null;
  const irclickid = qsObj.irclickid || null;
  const hasCart = Boolean(cart);

  useEffect(() => {
    if (urlDiscountCode && hasCart) {
      // This has been restructured such that the discount code is applied within the discountCodeField component.
      // This was done because errors weren't captured when triggered from here and the discount would be invalidated when a user is logged in, as the cart claiming event happens much later in the lifecycle.
      dispatch(storeDiscountCode(urlDiscountCode));
    }
  }, [dispatch, hasCart, urlDiscountCode]);

  useEffect(() => {
    if (irclickid) {
      dispatch(setIrclickid(irclickid));
    }
  }, [dispatch, irclickid]);

  const completeCardCheckout = useCallback(() => {
    dispatch(checkout());
    const cardElement = elements.getElement(CardElement);

    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then(({ error: stripeErr, paymentMethod: stripePaymentMethod }) => {
        if (stripeErr) {
          dispatch(checkoutFailure(stripeErr));
          // Whatevs bro
        } else {
          if (!isLoggedIn) {
            dispatch(
              anonymousCheckout(
                {
                  paymentMethod: {
                    token: stripePaymentMethod.id,
                    type: paymentMethodProviders.STRIPE,
                  },
                },
                stripe
              )
            );
          } else {
            dispatch(
              addPaymentMethod(
                paymentMethodProviders.STRIPE,
                stripePaymentMethod.id,
                stripe
              )
            ).then((grindPaymentMethod) =>
              dispatch(authenticatedCheckout(grindPaymentMethod.id, stripe))
            );
          }
        }
      });
  }, [dispatch, elements, isLoggedIn, stripe]);

  // This handles when users submit a full checkout via PayPal, meaning
  // we're getting contact details, shipping information and payment
  // information all from paypal.
  const completeQuickPayPalCheckout = useCallback(
    (payload) => {
      const { details: userDetail, nonce: paypalToken } =
        payload.paypalResponse;
      dispatch(
        anonymousCheckout(
          {
            paymentMethod: {
              token: paypalToken,
              type: paymentMethodProviders.PAYPAL,
            },
            stateOverride: {
              cart: {
                token: cartToken,
              },
              contactDetails: {
                ...namePartsFromFull(userDetail.shippingAddress.recipientName),
                email: userDetail.email,
              },
              ...(requiresShipping
                ? {
                  shippingAddress: addressObjFromPaypalRequest(
                    userDetail.shippingAddress,
                    availableCountryLookup
                  ),
                  shippingMethod: payload.shippingOptions[0].id, // pick the first option from the shipping rates
                }
                : {}),
            },
          },
          stripe
        )
      );
    },
    [availableCountryLookup, cartToken, dispatch, requiresShipping, stripe]
  );

  const completeQuickCheckout = useCallback(
    (event) => {
      dispatch(
        anonymousCheckout(
          {
            paymentMethod: {
              token: event.paymentMethod.id,
              type: paymentMethodProviders.STRIPE,
            },
            stateOverride: {
              cart: {
                token: cartToken,
              },
              contactDetails: {
                ...namePartsFromFull(event.payerName),
                email: event.payerEmail,
              },
              ...(requiresShipping
                ? {
                  shippingAddress: addressObjFromPaymentRequest(
                    event.shippingAddress,
                    availableCountryLookup
                  ),
                  shippingMethod: event.shippingOption.id,
                }
                : {}),
            },
          },
          stripe
        )
      ).then(
        (data) => {
          console.log("Checkout Complete:", data);
          event.complete("success");
        },
        (err) => {
          console.error("Checkout Error: ", err.code, err.response);
          event.complete("fail");
        }
      );
    },
    [availableCountryLookup, cartToken, dispatch, requiresShipping, stripe]
  );

  // This handles when a user submits Apple Pay as only their payment method.
  // This means we'll only get the payment information from Apple Pay, whilst
  // all other information is coming from their input or account.
  const completeStandardApplePayCheckout = useCallback(
    (event) => {
      if (isLoggedIn) {
        dispatch(
          addPaymentMethod(
            paymentMethodProviders.STRIPE,
            event.paymentMethod.id
          )
        )
          .then((grindPaymentMethod) =>
            dispatch(authenticatedCheckout(grindPaymentMethod.id, stripe))
          )
          .then(
            (data) => {
              event.complete("success");
            },
            (err) => {
              console.error("Authenticated Checkout Error: ", err.response);
              event.complete("fail");
            }
          );
      } else {
        dispatch(
          anonymousCheckout(
            {
              paymentMethod: {
                type: paymentMethodProviders.STRIPE,
                token: event.paymentMethod.id,
              },
            },
            stripe
          )
        ).then(
          (data) => {
            event.complete("success");
          },
          (err) => {
            console.error("Anonymous Checkout Error: ", err.response);
            event.complete("fail");
          }
        );
      }
    },
    [dispatch, isLoggedIn, stripe]
  );

  // This handles when a user submits a PayPal checkout.
  const completePayPalCheckout = useCallback(
    (payload) => {
      const paypalToken = payload.nonce;

      // If we're not logged in, let's do a silent register, claim the cart,
      // Apply our shipping address and shipping method, add our paypal payment
      // method and then checkout.
      if (!isLoggedIn) {
        dispatch(
          anonymousCheckout(
            {
              paymentMethod: {
                token: paypalToken,
                type: paymentMethodProviders.PAYPAL,
              },
            },
            stripe
          )
        );
      } else {
        dispatch(
          addPaymentMethod(paymentMethodProviders.PAYPAL, paypalToken)
        ).then((grindPaymentMethod) =>
          dispatch(authenticatedCheckout(grindPaymentMethod.id, stripe))
        );
      }
    },
    [dispatch, isLoggedIn, stripe]
  );

  const completePaymentMethodCheckout = useCallback(
    () => dispatch(authenticatedCheckout(paymentMethod, stripe)),
    [dispatch, paymentMethod, stripe]
  );

  // Import the cart. Maybe we add another method to claim it...
  useEffect(() => {
    if (!cartToken || isLoggedIn === null) {
      return;
    }
    if (isLoggedIn) {
      // A pristine cart token must always be imported into the database first before claiming. i.e. loadCart then claimShoppingCart
      dispatch(loadCart(cartToken)).then(() => {
        dispatch(claimShoppingCart(cartToken)).then((cart) =>
          dispatch(applyTinPreference(cart, tinPreference))
        );
      });
      dispatch(loadShippingAddresses());
      dispatch(loadPaymentMethods());
    } else {
      dispatch(loadCart(cartToken)).then((cart) =>
        dispatch(applyTinPreference(cart, tinPreference))
      );
    }
  }, [cartToken, dispatch, isLoggedIn, tinPreference]);

  if (completedCheckout) {
    return <Redirect to={`/${completedCheckout.token}/confirmation`} />;
  }

  if (
    cartError &&
    _.get(cartError, "response.data.error.code") === "shop.cart.checked_out"
  ) {
    const checkoutToken = _.get(
      cartError,
      "response.data.error.checkout.token"
    );
    return <Redirect to={`/${checkoutToken}/confirmation`} />;
  }

  // The user has come to the checkout without... *gasp* a cart token!
  if (cartError || !cartToken) {
    return <InvalidCheckout />;
  }

  if (!cart) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <InitialisedCheckout
      onCardCheckout={completeCardCheckout}
      onPaymentMethodCheckout={completePaymentMethodCheckout}
      onQuickCheckoutPaymentMethodReceived={completeQuickCheckout}
      onStandardCheckoutPaymentMethodReceived={completeStandardApplePayCheckout}
      onPaypalCheckout={completePayPalCheckout}
      onQuickPaypalCheckoutReceived={completeQuickPayPalCheckout}
    />
  );
};

export default Checkout;
